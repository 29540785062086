import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";
import BG from "../../assets/monitoring/bg02.png";

const StockChart = ({ series, lineColor, tooltip, style }) => {
  const chartRef = useRef(null);

  const [options, setOptions] = useState({
    chart: {
      type: "line",
      backgroundColor: "transparent",
      height: 300,
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%Y-%m-%d}",
        style: { color: "#FFFFFF" },
      },
    },
    tooltip: {
      formatter: function () {
        return `${Highcharts.dateFormat("%Y-%m-%d", this.x)}
      <br/>이용시간: <strong>${this.y}</strong>`;
      },
      valueDecimals: 0,
    },
    series: [
      {
        name: "Series 1",
        data: [],
        color: "#FF5733", // 라인 색상
        lineWidth: 3, // 라인 두께 설정
      },
    ],
    credits: {
      enabled: false,
    },
    rangeSelector: {
      inputDateFormat: "%Y-%m-%d",
      inputEditDateFormat: "%Y-%m-%d",
      inputStyle: {
        color: "#FFFFFF",
        backgroundColor: "#333333",
        border: "1px solid #777777",
        borderRadius: "4px",
      },
      labelStyle: {
        color: "#FFFFFF",
        fontWeight: "bold",
      },
    },
  });

  useEffect(() => {
    if (series && series.length > 0) {
      const minDate = series[0][0];
      let maxDate = series[series.length - 1][0];

      if (minDate >= maxDate) {
        maxDate = minDate + 24 * 60 * 60 * 1000;
      }

      // series가 배열 안에 객체로 구성된 경우
      const isComplexSeries =
        Array.isArray(series) &&
        typeof series[0] === "object" &&
        series[0].data;

      const optionsSeries = isComplexSeries
        ? series // 그대로 사용
        : [
            // 단일 데이터 배열일 경우 감싸서 사용
            {
              name: "Series 1",
              data: series,
              color: lineColor,
              lineWidth: 4,
            },
          ];

      setOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: tooltip,
        // series: [
        //   {
        //     name: "Series 1",
        //     data: series,
        //     color: lineColor,
        //     lineWidth: 4, // 라인 두께 업데이트
        //   },
        // ],
        series: optionsSeries,
        xAxis: {
          ...prevOptions.xAxis,
          min: minDate,
          max: maxDate,
        },
      }));

      if (chartRef.current) {
        chartRef.current.chart.xAxis[0].setExtremes(minDate, maxDate);
      }
    }
  }, [series]);

  Highcharts.setOptions({
    lang: {
      months: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      weekdays: [
        "일요일",
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
      ],
      shortMonths: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      rangeSelectorZoom: "기간 선택",
    },
  });

  return (
    <Col
      className="p-3 border rounded"
      style={
        style || {
          backgroundColor: "#1D222A",
          backgroundImage: `url(${BG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }
      }
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
        ref={chartRef}
      />
    </Col>
  );
};

export default StockChart;
