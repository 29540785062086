import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import {
  getAccountsCenter,
  getCenters,
  getDistrict,
  getNoticeCategory,
  getNoticeTemplate,
  getSeniorCenter,
  getTemplate,
  postNotice,
} from "../../api/apiClient";
import CustomModal from "../common/CustomModal";

const RegisterNotice = ({ permissionLevel }) => {
  const [noticeCategory, setNoticeCategory] = useState([]); // 템플릿 카테고리 api
  const [noticeTemplateList, setNoticeTemplateList] = useState([]); // 템플릿 리스트 api
  const [showCenterSearchModal, setShowCenterSearchModal] = useState(false);
  const [formData, setFormData] = useState({
    receiver: [], // 수신처 설정
    selectedCategory: "", // 카테고리 선택
    selectedTemplate: "", // 템플릿 선택
    category: "", // 카테고리 default (1: 공지사항)
    noticeTitle: "", // 공지사항 제목
    noticeContent: "", // 공지사항 내용
    repeatCount: 1, // 반복 횟수
    deliveryType: "reserve", // 예약 발송 or 바로 발송
    year: "2024",
    month: "1",
    day: "1",
    hour: "00",
    minute: "00",
  });

  const [districtList, setDistrictList] = useState(null);

  const [centerList, setCenterList] = useState(null);
  const [allCenters, setAllCenters] = useState([]);

  const [searchParam, setSearchParam] = useState({
    district: "",
    // center: "",
    search: "",
  });

  const [checkedList, setCheckedLists] = useState([]);

  // 행정구 리스트
  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 전체 경로당 가져오기
  const getAllCenterList = () => {
    getCenters("", "")
      .then((res) => {
        // console.log("전체 데이터1", res.data);
        setAllCenters(res.data || []); // 초기에는 전체 데이터 표시
      })
      .catch((err) => console.error(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    // getAccountsCenter("", "", "", "", "") // 전체 데이터 요청
    getCenters(searchParam.district, searchParam.search)
      .then((res) => {
        // console.log("전체 데이터", res.data.results);
        // console.log("전체 데이터", res.data);
        setCenterList(res.data || []); // 초기에는 전체 데이터 표시
      })
      .catch((err) => console.error(err));
  };

  // 공지사항 카테고리
  const getNoticeCategoryList = () => {
    getNoticeCategory()
      .then((res) => setNoticeCategory(res.data))
      .catch((err) => console.error(err));
  };

  // 공지사항 템플릿 리스트 api
  const getNoticeTemplateList = () => {
    getTemplate()
      .then((res) => {
        setNoticeTemplateList(res.data.results);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getDistrictList();
    getAllCenterList();
    getAccountsCenterList();
    setSelectedReceiverNames([]);
    getNoticeCategoryList();
    getNoticeTemplateList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.district]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // 전체 선택 버튼
  // const handleCheckedAll = () => {
  //   if (checkedList.length === centerList?.length) {
  //     setCheckedLists([]);
  //   } else {
  //     setCheckedLists(centerList || []);
  //   }
  // };
  const handleCheckedAll = () => {
    if (centerList.length === 0) return; // 데이터가 없으면 아무 작업도 하지 않음

    const isAllSelected = centerList.every((center) =>
      checkedList.some((checked) => checked.id === center.id)
    );

    if (isAllSelected) {
      // 전체 해제
      setCheckedLists((prev) =>
        prev.filter(
          (checked) => !centerList.some((center) => center.id === checked.id)
        )
      );
    } else {
      // setCheckedLists((prev) => [...prev, ...centerList]);
      // 중복 제거 후 추가
      setCheckedLists((prev) => {
        const newCenters = centerList.filter(
          (center) => !prev.some((checked) => checked.id === center.id)
        );
        return [...prev, ...newCenters];
      });
    }
  };

  // 경로당 개별 체크
  const handleCheckedChange = (checked, center) => {
    if (checked) {
      // setCheckedLists((prev) => [...prev, center]);
      setCheckedLists((prev) =>
        prev.some((item) => item.id === center.id) ? prev : [...prev, center]
      );
    } else {
      setCheckedLists((prev) => prev.filter((item) => item.id !== center.id));
    }
  };

  // 선택된 항목 삭제
  const handleDeleteChecked = (id, isDistrict = false) => {
    if (isDistrict) {
      // 해당 구에 속한 모든 센터를 찾아 checkedList에서 제거
      const districtCenters = centerList?.filter(
        (center) => center.district.id === id
      );

      setCheckedLists((prev) =>
        prev.filter(
          (item) => !districtCenters.some((center) => center.id === item.id)
        )
      );
    } else {
      // 개별 항목 삭제
      setCheckedLists((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const [selectedReceiverNames, setSelectedReceiverNames] = useState([]);

  // 선택 경로당 적용 버튼
  const handleSelectedCenters = () => {
    const selectedIds = checkedList.map((center) => center.id); // ID 리스트 추출
    const selectedNames = checkedList.map((center) => center.name); // 이름 리스트 추출

    if (selectedIds.length === 0) {
      alert("경로당을 선택해주세요.");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      receiver: selectedIds, // 선택된 ID 리스트 저장
    }));

    setSelectedReceiverNames(selectedNames); // 선택된 이름 리스트 저장
    setSearchParam({ district: "", center: "", search: "" });
    getAccountsCenterList();
    setShowCenterSearchModal(false); // 모달 닫기
  };

  const [daysInMonth, setDaysInMonth] = useState([]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  useEffect(() => {
    const days = getDaysInMonth(formData.year, formData.month);
    setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
  }, [formData.year, formData.month]);

  // 업데이트 핸들러
  const updateField = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // 폼 유효성 검사
  const validateForm = () => {
    const requiredFields = [
      "receiver",
      "category",
      "noticeTitle",
      "noticeContent",
      "repeatCount",
      "deliveryType",
      "year",
      "month",
      "day",
      "hour",
      "minute",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  // 발송 버튼 핸들러
  const handleSend = () => {
    if (!validateForm()) {
      alert("모든 필드를 채워주세요.");
      return;
    }

    const userConfirmed = window.confirm("발송 하시겠습니까?");
    if (!userConfirmed) {
      return;
    }

    let postData = {};
    if (formData.deliveryType === "reserve") {
      postData = {
        category: formData.category, // 카테고리 ID
        title: formData.noticeTitle, // 공지사항 제목
        content: formData.noticeContent, // 공지사항 내용
        repeat_time: formData.repeatCount, // 반복 횟수
        is_reserved: formData.deliveryType === "reserve", // 예약 여부
        // reserved_at: `${formData.year}-${formData.month}-${formData.day}T${formData.hour}:${formData.minute}:00.000Z`,  // 예약 시간
        reserved_at: new Date(
          Date.UTC(
            formData.year,
            formData.month - 1,
            formData.day,
            formData.hour,
            formData.minute
          )
        ).toISOString(), // 예약 시간
        receiver: formData.receiver, // 수신처
      };

      // console.log("예약 발송 Post Data:", postData);
    } else if (formData.deliveryType === "immediate") {
      postData = {
        category: formData.category, // 카테고리 ID
        title: formData.noticeTitle, // 공지사항 제목
        content: formData.noticeContent, // 공지사항 내용
        repeat_time: formData.repeatCount, // 반복 횟수
        is_reserved: formData.deliveryType === "immediate", // 예약 여부
        receiver: formData.receiver, // 수신처
      };

      // console.log("즉시 발송 Post Data:", postData);
    }

    console.log("Post Data:", postData);
    postNotice(postData)
      .then((res) => {
        console.log(res);
        alert("발송 완료");
      })
      .catch((err) => {
        console.error(err);
        alert("발송 실패");
      });
  };

  // 템플릿 리스트 선택
  const handleTemplateSelect = (templateId) => {
    const selectedTemplate = noticeTemplateList.find(
      (template) => template.id === parseInt(templateId)
    );

    if (selectedTemplate) {
      setFormData((prevState) => ({
        ...prevState,
        category: selectedTemplate.category.id,
        noticeTitle: selectedTemplate.title,
        noticeContent: selectedTemplate.content,
      }));
    }
  };

  // 초기화 버튼
  const handleReset = () => {
    setFormData({
      receiver: [], // 수신처 설정
      selectedCategory: "", // 카테고리 선택
      selectedTemplate: "", // 템플릿 선택
      category: { name: "" }, // 카테고리 default (1: 공지사항)
      noticeTitle: "", // 공지사항 제목
      noticeContent: "", // 공지사항 내용
      repeatCount: 1, // 반복 횟수
      deliveryType: "reserve", // 예약 발송 or 바로 발송
      year: "2024",
      month: "1",
      day: "1",
      hour: "00",
      minute: "00",
    });

    setSearchParam({ district: "", center: "", search: "" });
    setCheckedLists([]);
    setSelectedReceiverNames([]);
    getAccountsCenterList();
  };

  // submit
  const handleSumit = (e) => {
    e.preventDefault();
    getAccountsCenterList();
  };

  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3 justify-content-center"}>
          <Col xs={10}>
            <Form onSubmit={handleSumit}>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-4 fw-bold"
                        }
                      >
                        경로당 찾기
                      </span>
                    </Col>
                  </Row>

                  {/* 경로당 검색 */}
                  <Row className="mt-2">
                    <Col xs={2} className={"my-auto align-middle pe-0"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구선택
                      </span>
                    </Col>
                    <Col xs={3} className="pe-0">
                      <Form.Select
                        className="h-100 fs-5"
                        value={searchParam.district || ""}
                        onChange={(e) => {
                          setSearchParam({
                            ...searchParam,
                            district: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>구</option>
                        {districtList?.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={5} className="pe-0">
                      <Form.Control
                        type="text"
                        placeholder="검색어 입력"
                        className="h-100 fs-5 pe-0"
                        value={searchParam.search || ""}
                        onChange={(e) => {
                          setSearchParam({
                            ...searchParam,
                            search: e.target.value,
                          });
                        }}
                      />
                    </Col>

                    <Col xs={2}>
                      <Button
                        type="submit"
                        className="w-100 h-100 fs-5 fw-bold"
                        onClick={handleSumit}
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>

                  {/* 경로당 체크박스 */}
                  <Row className="m-0 mt-2">
                    <Col
                      xs={12}
                      className="border rounded px-5 d-flex flex-column justify-content-center align-items-center"
                    >
                      {/* 전체선택 버튼 */}
                      <Col xs={12} className="py-2 text-start mb-2">
                        <Button
                          type="button"
                          className="btn btn-primary fs-5 fw-bold"
                          onClick={handleCheckedAll}
                        >
                          전체선택
                        </Button>
                      </Col>

                      {/* 세부 체크박스 리스트 */}
                      <Col
                        xs={12}
                        style={{
                          height: "18rem",
                          overflowX: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        <Row>
                          {centerList?.length > 0 ? (
                            centerList?.map((center, idx) => (
                              <Col xs={12} sm={4} key={idx} className="mb-2">
                                <Form.Check className="ps-1 d-flex align-items-center">
                                  <Form.Check.Input
                                    type="checkbox"
                                    id={`detail-${idx + 1}`}
                                    style={{ width: "2rem", height: "2rem" }}
                                    className="ms-0 mb-auto"
                                    checked={checkedList.some(
                                      (item) => item.id === center.id
                                    )}
                                    onChange={(e) =>
                                      handleCheckedChange(
                                        e.target.checked,
                                        center
                                      )
                                    }
                                  />
                                  <Form.Check.Label
                                    htmlFor={`detail-${idx + 1}`}
                                    className="ms-2 border rounded px-2 py-1 text-start fs-5"
                                  >
                                    {center.name}
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            ))
                          ) : (
                            <Col className="w-100 text-center my-5">
                              <span>검색 결과가 없습니다.</span>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Col>
                  </Row>

                  {/* 선택한 경로당 */}
                  <Row className="m-0 mt-2 border rounded w-100 p-3">
                    <Col
                      xs={10}
                      className="d-flex flex-wrap align-items-start gap-2 mb-2 p-0"
                      style={{ height: "6rem", overflowY: "auto" }}
                    >
                      {districtList?.map((district) => {
                        // 현재 구에 해당하는 체크된 센터 리스트
                        const districtCenters = checkedList.filter(
                          (center) => center.district.id === district.id
                        );

                        // 현재 구에 해당하는 모든 센터 리스트
                        const allDistrictCenters = allCenters.filter(
                          (center) => center.district.id === district.id
                        );

                        // 구 전체 선택 여부 확인
                        const isDistrictSelected =
                          districtCenters.length > 0 &&
                          districtCenters.length === allDistrictCenters.length;

                        if (isDistrictSelected) {
                          // 구 전체가 선택된 경우
                          return (
                            <div
                              key={`district-${district.id}`}
                              className="me-2 border rounded px-2 py-1 text-center"
                            >
                              <span>{district.name} 전체</span>
                              <span
                                className="ms-2 border rounded px-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteChecked(district.id, true)
                                } // 구 전체 삭제
                                style={{ cursor: "pointer" }}
                              >
                                x
                              </span>
                            </div>
                          );
                        }

                        // 구 전체가 선택되지 않은 경우, 개별 항목 처리
                        return districtCenters.map((center) => (
                          <div
                            key={`center-${center.id}`}
                            className="me-2 border rounded px-2 py-1 text-center"
                          >
                            <span>{center.name}</span>
                            <span
                              className="ms-2 border rounded px-1 cursor-pointer"
                              onClick={() => handleDeleteChecked(center.id)} // 개별 항목 삭제
                              style={{ cursor: "pointer" }}
                            >
                              x
                            </span>
                          </div>
                        ));
                      })}

                      {/* {districtList?.map((district) => {
                        // 해당 구의 센터 리스트
                        const districtCenters = checkedList?.filter(
                          (center) => center.district.id === district.id
                        );

                        // 해당 구 전체 선택 여부 확인
                        // const isDistrictSelected =
                        //   districtCenters?.length > 0 &&
                        //   districtCenters.every((center) =>
                        //     checkedList.some(
                        //       (checked) => checked.id === center.id
                        //     )
                        //   );
                        const isDistrictSelected =
                          districtCenters.length > 0 &&
                          districtCenters.length ===
                            centerList?.filter(
                              (center) => center.district.id === district.id
                            )?.length;

                        if (isDistrictSelected) {
                          // 해당 구가 전체 선택된 경우
                          return (
                            <div
                              key={`district-${district.id}`}
                              className="me-2 border rounded px-2 py-1 text-center"
                            >
                              <span>{district.name} 전체</span>
                              <span
                                className="ms-2 border rounded px-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteChecked(district.id, true)
                                } // 구 전체 삭제
                                style={{cursor: "pointer"}}
                              >
                                x
                              </span>
                            </div>
                          );
                        }

                        // 개별 항목 처리
                        return districtCenters
                          ?.filter((center) =>
                            checkedList.some(
                              (checked) => checked.id === center.id
                            )
                          )
                          .map((center) => (
                            <div
                              key={`center-${center.id}`}
                              className="me-2 border rounded px-2 py-1 text-center"
                            >
                              <span>{center.name}</span>
                              <span
                                className="ms-2 border rounded px-1 cursor-pointer"
                                onClick={() => handleDeleteChecked(center.id)} // 개별 항목 삭제
                                style={{cursor: "pointer"}}
                              >
                                x
                              </span>
                            </div>
                          ));
                      })} */}
                      {/* {checkedList.length === centerList?.length ? (
                        <p>전체</p>
                      ) : (
                        checkedList.map((center) => (
                          <div
                            key={center.id}
                            className="me-2 border rounded px-2 py-1 text-center"
                          >
                            <span>{center.name}</span>
                            <span
                              className="ms-2 border rounded px-1"
                              onClick={() => handleDeleteChecked(center.id)}
                            >
                              x
                            </span>
                          </div>
                        ))
                      )} */}
                    </Col>
                    <Col xs={2} className="text-end mt-auto p-0">
                      <Button
                        variant="secondary"
                        className="rounded-5 fs-5 px-3 fw-bold"
                        onClick={() => setCheckedLists([])}
                      >
                        전체 초기화
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={12} className={"text-center"}>
            <Button
              className="btn-outline-primary"
              size="lg"
              onClick={handleSelectedCenters}
            >
              선택 경로당 적용
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Container
        className="p-4 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 13rem)",
          overflow: "auto",
        }}
      >
        {/* 상단 버튼 그룹 */}
        <Row className="mb-3 justify-content-start">
          <Col
            xs={4}
            xl={2}
            className={
              "d-flex justify-content-center align-items-center border rounded text-center fs-5 mb-auto py-2"
            }
          >
            <label>수신처 설정</label>
          </Col>
          <Col xs={8} xl={4} className={"my-auto align-middle"}>
            <Row>
              <Col>
                <Form.Control
                  className={
                    "d-block w-100 border p-2 rounded-3 text-start fs-5 placeholder-white"
                  }
                  style={{
                    backgroundColor: "#1D222A",
                    color: "#fff",
                  }}
                  as="textarea"
                  rows={formData.receiver.length > 1 ? 3 : 1}
                  placeholder="선택한 경로당 명"
                  size="lg"
                  // value={formData.receiver}
                  value={selectedReceiverNames.join(", ")} // 이름 리스트 표시
                  onChange={(e) => updateField("receiver", e.target.value)}
                  disabled
                />
              </Col>
              <Col className="p-0">
                <Button
                  size="lg"
                  className="fw-bold"
                  onClick={() => {
                    setCenterList([]); // 기존 검색 결과 초기화
                    setSearchParam({ district: "", center: "", search: "" });
                    // getAccountsCenterList();
                    // 상태 리셋 후 데이터를 가져오기
                    setTimeout(() => {
                      getAccountsCenterList(); // 새로운 데이터 가져오기
                    }, 0);
                    setShowCenterSearchModal(true);
                  }}
                  disabled={permissionLevel === 1}
                >
                  경로당 찾기
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* 카테고리 선택 */}
        <Row className="mb-3">
          <Col
            xs={4}
            xl={2}
            className={
              "d-flex justify-content-center align-items-center border rounded text-center fs-5"
            }
          >
            <label>카테고리 선택</label>
          </Col>
          <Col xs={8} xl={4} className={"my-auto align-middle"}>
            <Form.Select
              className="primary-select fw-bold"
              size="lg"
              value={formData.category}
              onChange={(e) => updateField("category", e.target.value)}
              disabled={permissionLevel === 1}
            >
              <option defaultChecked value={"category"}>
                카테고리
              </option>
              {noticeCategory?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {/* 템플릿 선택 */}
        <Row className="mb-3">
          <Col
            xs={4}
            xl={2}
            className={
              "d-flex justify-content-center align-items-center border rounded text-center fs-5"
            }
          >
            <label>템플릿 선택</label>
          </Col>
          <Col xs={8} xl={4} className={"my-auto align-middle"}>
            <Form.Select
              className="primary-select fw-bold"
              size="lg"
              value={formData.selectedTemplate}
              onChange={(e) => {
                const selectedId = e.target.value;
                updateField("selectedTemplate", selectedId);
                handleTemplateSelect(selectedId); // 선택된 템플릿의 내용 업데이트
              }}
              disabled={permissionLevel === 1}
            >
              <option value="">템플릿 리스트</option>
              {noticeTemplateList &&
                noticeTemplateList?.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.title}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>

        {/* 공지사항 제목 */}
        <Row className="mb-3">
          <Col
            xs={4}
            xl={2}
            className={
              "d-flex justify-content-center align-items-center border rounded text-center fs-5"
            }
          >
            <label>공지사항 제목</label>
          </Col>
          <Col xs={8} xl={6} className={"my-auto align-middle"}>
            <Form.Control
              type="text"
              placeholder="제목을 입력하세요"
              size="lg"
              value={formData.noticeTitle}
              onChange={(e) => updateField("noticeTitle", e.target.value)}
              disabled={permissionLevel === 1}
            />
          </Col>
        </Row>

        {/* 공지사항 내용 */}
        <Row className="mb-3">
          <Col
            xs={4}
            xl={2}
            className={"mb-auto py-2 border rounded text-center fs-5"}
          >
            <label>공지사항 내용</label>
          </Col>
          <Col xs={8} xl={6} className={"my-auto align-middle"}>
            <Form.Control
              as="textarea"
              rows={8}
              size="lg"
              placeholder="내용을 입력하세요"
              value={formData.noticeContent}
              onChange={(e) => updateField("noticeContent", e.target.value)}
              disabled={permissionLevel === 1}
            />
          </Col>
        </Row>

        {/* 반복 횟수 */}
        <Row className="mb-3">
          <Col
            xs={4}
            xl={2}
            className={
              "d-flex justify-content-center align-items-center border rounded text-center fs-5"
            }
          >
            <label>반복 횟수</label>
          </Col>
          <Col xs={8} xl={4} className={"my-auto align-middle"}>
            <Form.Control
              type="number"
              placeholder=""
              size="lg"
              value={formData.repeatCount}
              onChange={(e) => updateField("repeatCount", e.target.value)}
              disabled={permissionLevel === 1}
            />
          </Col>
        </Row>

        {/* 예약 발송 및 라디오 버튼 */}
        <Row className="mb-3">
          <Col xs={4} xl={2} className={"py-2 border rounded text-center fs-5"}>
            <label>예약 발송</label>
          </Col>
          <Col xs={8} xl={8} className={"my-auto align-middle"}>
            <Row>
              <Col xs={2} xl={2} className="pe-0">
                <Form.Check
                  type="radio"
                  id="check-api-reserve"
                  className="border rounded p-2 m-0 d-flex align-items-center"
                >
                  <Form.Check.Input
                    type="radio"
                    checked={formData.deliveryType === "reserve"}
                    onChange={(e) => updateField("deliveryType", "reserve")}
                    disabled={permissionLevel === 1}
                  />
                  <Form.Check.Label
                    className="fs-5"
                    style={{ verticalAlign: "-webkit-baseline-middle" }}
                  >
                    예약하기
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs={2} xl={2} className="pe-0">
                <Form.Check
                  type="radio"
                  id="check-api-immediate"
                  className="border rounded p-2 m-0 d-flex align-items-center"
                >
                  <Form.Check.Input
                    type="radio"
                    checked={formData.deliveryType === "immediate"}
                    onChange={(e) => updateField("deliveryType", "immediate")}
                    disabled={permissionLevel === 1}
                  />
                  <Form.Check.Label
                    className="fs-5"
                    style={{ verticalAlign: "-webkit-baseline-middle" }}
                  >
                    바로 발송
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              {formData.deliveryType === "reserve" && (
                <Col xs={8} xl={8} className={"my-auto align-middle"}>
                  <Row className={"row-gap-3"}>
                    <Col xs={2} className="pe-0">
                      <Form.Select
                        className={"w-100"}
                        size="lg"
                        value={formData.year}
                        onChange={(e) => updateField("year", e.target.value)}
                        disabled={permissionLevel === 1}
                      >
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="pe-0">
                      <Form.Select
                        className={"w-100"}
                        size="lg"
                        value={formData.month}
                        onChange={(e) => updateField("month", e.target.value)}
                        disabled={permissionLevel === 1}
                      >
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}월
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="pe-0">
                      <Form.Select
                        className={"w-100"}
                        size="lg"
                        value={formData.day}
                        onChange={(e) => updateField("day", e.target.value)}
                        disabled={permissionLevel === 1}
                      >
                        {daysInMonth.map((day) => (
                          <option key={day} value={day}>
                            {day}일
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs={2} className="pe-0">
                      <Form.Select
                        className={"w-100"}
                        size="lg"
                        value={formData.hour}
                        onChange={(e) => updateField("hour", e.target.value)}
                        disabled={permissionLevel === 1}
                      >
                        {Array.from({ length: 24 }, (_, i) => (
                          <option key={i} value={i}>
                            {i}시
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs={2} className="pe-0">
                      <Form.Select
                        className={"w-100"}
                        size="lg"
                        value={formData.minute}
                        onChange={(e) => updateField("minute", e.target.value)}
                        disabled={permissionLevel === 1}
                      >
                        {Array.from({ length: 60 }, (_, i) => (
                          <option key={i} value={i}>
                            {i}분
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {/* 예약 발송 날짜 정보 */}
        {/* {formData.deliveryType === "reserve" && (
          <Row className="mb-3">
            <Col xs={4} xl={2} className={"my-auto align-middle"}>
              예약 시간 설정
            </Col>
            <Col xs={8} xl={6} className={"my-auto align-middle"}>
              <Row className={"row-gap-3"}>
                <Col xs={12} lg={4}>
                  <Form.Select
                    className={"w-100"}
                    size="lg"
                    value={formData.year}
                    onChange={(e) => updateField("year", e.target.value)}
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                  </Form.Select>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Select
                    className={"w-100"}
                    size="lg"
                    value={formData.month}
                    onChange={(e) => updateField("month", e.target.value)}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Select
                    className={"w-100"}
                    size="lg"
                    value={formData.day}
                    onChange={(e) => updateField("day", e.target.value)}
                  >
                    {daysInMonth.map((day) => (
                      <option key={day} value={day}>
                        {day}일
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col xs={12} lg={4}>
                  <Form.Select
                    className={"w-100"}
                    size="lg"
                    value={formData.hour}
                    onChange={(e) => updateField("hour", e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}시
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col xs={12} lg={4}>
                  <Form.Select
                    className={"w-100"}
                    size="lg"
                    value={formData.minute}
                    onChange={(e) => updateField("minute", e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}분
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        )} */}

        {(permissionLevel === 3 || permissionLevel === 2) && (
          <>
            {/* 발송 버튼 */}
            <Row className="justify-content-center mt-5">
              <Col className="text-end">
                <Button
                  size="lg"
                  className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                  onClick={handleSend}
                >
                  발송
                </Button>
              </Col>
              <Col>
                <Button
                  size="lg"
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={handleReset}
                >
                  초기화
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <CustomModal
        show={showCenterSearchModal}
        onHide={() => setShowCenterSearchModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default RegisterNotice;
