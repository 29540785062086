import { useEffect } from 'react';

const useScript = (url, isLoaded) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => {
      console.log(`Script loaded: ${url}`);
      if (isLoaded)
        isLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;