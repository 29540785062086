import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";
import {
  getCentersWeight,
  getDetailWeight,
  getDistrict,
  getSeniorCenter,
  getWeight,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import CenterSearch from "../common/search/CenterSearch";
import CustomTable from "../table/CustomTable";
import CustomModal from "../common/CustomModal";

const BMI = () => {
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { key: "district_name", title: "구" },
    { key: "center_name", title: "경로당명" },
    { key: "total_measurements", title: "전체 측정 수" },
    { key: "male_measurements", title: "남자 수" },
    { key: "female_measurements", title: "여자 수" },
    { key: "actions", title: "상세보기" },
  ];

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        // console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const [totalStatistics, setTotalStatistics] = useState({});
  const [maleStatistics, setMaleStatistics] = useState({});
  const [femaleStatistics, setFemaleStatistics] = useState({});

  // 컬러 배열
  const colors = ["#2ECF8F", "#FB72F4", "#FF8C18", "#9E54F3"];
  const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
  const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

  // 헬스케어 시스템 운영관리 - 체중 통계
  const getWeightList = () => {
    getWeight(
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      startData,
      endData
    )
      .then((res) => {
        console.log("getWeight : ", res.data);

        setTotalStatistics({
          xAxisCategories: res.data.grade_statistics.total.map(
            (item) => item.grade_name
          ),
          xAxisTitle: "등급구분",
          series: [
            {
              type: "column",
              name: "등급구분",
              data: res.data.grade_statistics.total.map((item, index) => ({
                y: item.count || 0,
                color: colors[index],
              })),
            },
          ],
          height: "15%",
        });

        setMaleStatistics({
          xAxisCategories: res.data.grade_statistics.male.map(
            (item) => item.grade_name
          ),
          xAxisTitle: "등급구분",
          series: [
            {
              type: "column",
              name: "등급구분",
              data: res.data.grade_statistics.male.map((item, index) => ({
                y: item.count || 0,
                color: colors[index],
              })),
            },
          ],
          height: "31%",
        });

        setFemaleStatistics({
          xAxisCategories: res.data.grade_statistics.female.map(
            (item) => item.grade_name
          ),
          xAxisTitle: "등급구분",
          series: [
            {
              type: "column",
              name: "등급구분",
              data: res.data.grade_statistics.female.map((item, index) => ({
                y: item.count || 0,
                color: colors[index],
              })),
            },
          ],
          height: "31%",
        });
      })
      .catch((err) => console.error(err));
  };

  // 경로당별 체중 측정 현황 리스트
  const getCentersWeightList = () => {
    getCentersWeight(
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      startData,
      endData,
      currentPage
    )
      .then((res) => {
        console.log("getCentersWeight : ", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getWeightList();
    getCentersWeightList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getCentersWeightList();
  }, [currentPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    getWeightList();
    getCentersWeightList();
  };

  const [detailData, setDetailData] = useState({});

  const handleDetail = (data) => {
    setShowModal(true);

    getDetailWeight(data.center_id)
      .then((res) => {
        console.log("id : ", res.data);
        setDetailData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const renderDetail = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={12}>
                      <span className="fs-4 fw-bold">
                        | {detailData?.center_name}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} className={"my-auto align-end"}>
                      <BootstrapTable striped hover responsive>
                        <thead>
                          <tr className={"text-center"}>
                            {["등급", "남자 수", "여자 수", "전체 측정 수"].map(
                              (col, idx) => (
                                <th key={idx}>{col}</th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr className={"text-center"}>
                            <td>{detailData?.stats?.low.grade_name}</td>
                            <td>{detailData?.stats?.low.male_count}</td>
                            <td>{detailData?.stats?.low.female_count}</td>
                            <td>{detailData?.stats?.low.total_count}</td>
                          </tr>

                          <tr className={"text-center"}>
                            <td>{detailData?.stats?.normal.grade_name}</td>
                            <td>{detailData?.stats?.normal.male_count}</td>
                            <td>{detailData?.stats?.normal.female_count}</td>
                            <td>{detailData?.stats?.normal.total_count}</td>
                          </tr>

                          <tr className={"text-center"}>
                            <td>{detailData?.stats?.overweight.grade_name}</td>
                            <td>{detailData?.stats?.overweight.male_count}</td>
                            <td>
                              {detailData?.stats?.overweight.female_count}
                            </td>
                            <td>{detailData?.stats?.overweight.total_count}</td>
                          </tr>

                          <tr className={"text-center"}>
                            <td>
                              {detailData?.stats?.obesity_level_1.grade_name}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_1.male_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_1.female_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_1.total_count}
                            </td>
                          </tr>

                          <tr className={"text-center"}>
                            <td>
                              {detailData?.stats?.obesity_level_2.grade_name}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_2.male_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_2.female_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_2.total_count}
                            </td>
                          </tr>

                          <tr className={"text-center"}>
                            <td>
                              {detailData?.stats?.obesity_level_3.grade_name}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_3.male_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_3.female_count}
                            </td>
                            <td>
                              {detailData?.stats?.obesity_level_3.total_count}
                            </td>
                          </tr>

                          {/* 합계 */}
                          <tr className={"text-center"}>
                            <td className="border-0"></td>
                            <td
                              style={{
                                borderTop: "2px solid #fff",
                                borderBottom: "2px solid #fff",
                              }}
                            >
                              {detailData?.stats?.total.male_count}
                            </td>
                            <td
                              style={{
                                borderTop: "2px solid #fff",
                                borderBottom: "2px solid #fff",
                              }}
                            >
                              {detailData?.stats?.total.female_count}
                            </td>
                            <td
                              style={{
                                borderTop: "2px solid #fff",
                                borderBottom: "2px solid #fff",
                              }}
                            >
                              {detailData?.stats?.total.total_count}
                            </td>
                          </tr>
                        </tbody>
                      </BootstrapTable>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 경로당 검색 */}
        <Col
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="row-gap-3">
            <DateSearch
              title={"검색 일자"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />
            <CenterSearch
              title={"경로당 필터 검색"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              districtList={districtList}
              centerList={centerList}
              handleKeywordChange={handleKeywordChange}
              handleCenterChange={handleCenterChange}
              onSearch={handleSearch}
            />
          </Row>
        </Col>

        {/* 테이블 */}

        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">| 등급별 측정 현황</span>
            </Col>
            <Col xs={12} className="">
              <BasicColumnChart {...totalStatistics} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Col className="w-100 mt-3 mb-1">
                <span className="fs-4 fw-bold w-100">
                  | 등급별 측정 남자 수
                </span>
              </Col>
              <Col xs={12} className="pe-0">
                <BasicColumnChart {...maleStatistics} />
              </Col>
            </Col>
            <Col>
              <Col className="w-100 mt-3 mb-1">
                <span className="fs-4 fw-bold w-100">
                  | 등급별 측정 여자 수
                </span>
              </Col>
              <Col xs={12} className="pe-0">
                <BasicColumnChart {...femaleStatistics} />
              </Col>
            </Col>
          </Row>

          <Row>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 등급별 측정 현황</span>
            </Col>
            <Col xs={12}>
              {tableData ? (
                <CustomTable
                  columns={columns}
                  tableData={tableData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        {renderDetail()}
      </CustomModal>
    </>
  );
};

export default BMI;
