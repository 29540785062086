import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  getJWT,
  getLiveStreaming,
  getProgramSchedule,
} from "../../api/apiClient";
import Media from "./client-monitoring/media";
import Conf_screen from "./client-monitoring/conf_screen";
import Slide from "./client-monitoring/Slide";

const ClientMonitoring = ({ permissionLevel }) => {
  const [current, setCurrent] = useState({});
  const [slideList, setSlideList] = useState(null);

  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");
  useEffect(() => {
    // console.log(centerInfo);
    getJWT("사숲경로당")
      .then((res) => {
        console.log("====token", res.data.token);
        setToken(res?.data?.token?.slice(2, -1));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // api call
  const getCurrent = () => {
    // setLoading(true);
    // get current streaming info
    getLiveStreaming()
      .then((res) => {
        console.log("Current streaming info: ", res);
        setCurrent(res);
      })
      .catch((error) => {
        console.error("Current streaming info error: ", error);
      });
    // .finally(() => setLoading(false));
  };

  const getSlideList = () => {
    getProgramSchedule()
      .then((res) => {
        console.log("slideList res : ", res.data);
        setSlideList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCurrent();
    getSlideList();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getCurrent();
    }, 10000);
  }, []);

  useEffect(() => {
    console.log("current: ", current);
  }, [current]);

  // "HH:mm" 포맷을 분 단위 숫자로 변환하는 함수
  const timeToMinutes = (timeStr) => {
    const [hour, minute] = timeStr.split(":").map(Number);
    return hour * 60 + minute;
  };

  const [currentTime, setCurrentTime] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    const updateTimeAndEvent = () => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const timeStr = `${hours}:${minutes}`;
      setCurrentTime(timeStr);

      const todaySlide = slideList?.slides[0]; // 배열의 첫 번째 요소가 오늘 날짜
      if (todaySlide && todaySlide.events && todaySlide.events.length > 0) {
        const eventsSorted = [...todaySlide.events].sort(
          (a, b) => timeToMinutes(a.start_time) - timeToMinutes(b.start_time)
        );

        const nowMinutes = timeToMinutes(timeStr);
        let foundEvent = null;

        for (let i = 0; i < eventsSorted.length; i++) {
          const startTime = timeToMinutes(eventsSorted[i].start_time);
          const endTime = eventsSorted[i].end_time
            ? timeToMinutes(eventsSorted[i].end_time)
            : startTime + 60; // end_time이 없으면 기본 60분 유지

          if (nowMinutes >= startTime && nowMinutes < endTime) {
            foundEvent = eventsSorted[i];
            break;
          }
        }

        // 마지막 방송이 끝났으면 currentEvent를 null로 설정
        const lastEvent = eventsSorted[eventsSorted.length - 1];
        if (
          lastEvent.end_time &&
          nowMinutes >= timeToMinutes(lastEvent.end_time)
        ) {
          foundEvent = null;
        }

        setCurrentEvent(foundEvent);
      } else {
        setCurrentEvent(null);
      }
    };

    // 초기 업데이트 및 매 분마다 업데이트
    updateTimeAndEvent();
    const timerId = setInterval(updateTimeAndEvent, 60000);

    return () => clearInterval(timerId);
  }, [slideList]);

  console.log("현재 방송 중:", currentEvent);

  return (
    <div
      className={"border p-3 rounded-3"}
      style={{
        backgroundColor: "#1D222A",
        height: "calc(100vh - 12rem)",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <div xs={6} className={"my-auto align-middle mb-3"}>
        <span className="fs-4 fw-bold">| 실시간 방송 모니터링</span>
      </div>
      {/* 로딩스피너 */}
      {/* {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100vw", height: "80vh", zIndex: 100 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )} */}
      {!permissionLevel ? (
        <p>접근 권한이 없습니다.</p>
      ) : (
        <div
          className="border px-5 py-3 rounded-3 mx-auto"
          style={{
            width: "1350px",
            backgroundColor: "#171717",
          }}
        >
          <span className="fs-3">
            {currentEvent ? (
              <h3>
                {currentEvent?.start_time} : {currentEvent?.title}
              </h3>
            ) : (
              <p></p>
            )}
          </span>
          <div>
            {/* 방송이 있을 경우 (스트리밍) */}
            {current &&
              current.data &&
              current.data.content_type === "live" &&
              token && (
                <div className="mb-3">
                  {/* <p
                    className="text-center mb-2"
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    비디오 및 오디오 장치가 연결되어 있어야 현재 스트리밍 방송을
                    확인할 수 있습니다.
                  </p> */}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transformOrigin: "top center",
                      borderRadius: "24px",
                      overflow: "hidden",
                    }}
                  >
                    <Conf_screen token={token} />
                  </div>
                </div>
              )}

            {/* 방송이 있을 경우 (VOD) */}
            {current && current.data && current.data.content_type === "vod" && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  transformOrigin: "top center",
                  borderRadius: "24px",
                  overflow: "hidden",
                }}
              >
                <Media
                  videoUrl={current.data.vod.video}
                  startTime={current.data.start_time}
                  endTime={current.data.end_time}
                />
              </div>
            )}

            {/* 방송이 없을 경우 */}
            {((current && current.data && current.data.message) ||
              current.length === 0) && (
              <div
                className="border mb-3 d-flex justify-content-center align-items-center"
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "52vh",
                  borderRadius: "24px",
                  overflow: "hidden",
                }}
              >
                <h3 className={"fs-3 text-center"}>
                  현재 진행중인 방송이 없습니다.
                </h3>
              </div>
            )}

            {/* IPTV 일 경우 */}
            {/* {((current && current.data && current.data.content_type === "iptv") ||
        current.length === 0) && (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <h3 className={"fs-3 text-center"}>경로당에서 iptv 시청 중입니다.</h3>
        </div>
      )} */}
          </div>
          <div>
            <Slide slideList={slideList} />
          </div>
        </div>
      )}

      {/* iframe test */}
      {/* <div
        style={{
          width: "100%",
          height: "100%",
          transform: "scale(0.8)", // 스케일을 80%로 축소
          transformOrigin: "top center",
        }}
      >
        <iframe
          src="https://webclient.smartsilver.life/?deviceID=7f842a9321eafe2a5e983ac69a6318a59e6e159c0fb163bf1306c8db1ca9525a"
          width="100%"
          height="100%"
          style={{
            display: isLoaded ? "block" : "none",
          }}
          onLoad={handleLoad}
        />
      </div> */}
    </div>
  );
};

export default ClientMonitoring;
