import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LogoSvg from "../../assets/common/logo.svg";
import LoginSvg from "../../assets/common/login.svg";
import LogoutSvg from "../../assets/common/logout.svg";
import { useNavigate } from "react-router-dom";

const Header = ({ title = "스마트경로당 통합관리시스템" }) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setUsername(localStorage.getItem("name"));
      setUserType(localStorage.getItem("type"));
    }, 100);
  }, []);

  const logout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("name");
    navigate("/");
  };

  return (
    <Row
      className="header p-4 px-md-4 px-lg-5 w-100 mx-auto"
      style={{ maxWidth: "1920px" }}
    >
      <Col xs={12} md={6} className="w-100">
        <Row className="justify-content-between">
          <Col className="d-flex justify-content-start align-items-center gap-4">
            <img
              src={LogoSvg}
              alt="Logo"
              className={`border-end pe-4 custom-border-secondary ${
                userType === "external" ? "" : "cursor-hover"
              }`}
              onClick={() => {
                if (userType === "external") return;
                navigate("/landing");
              }}
            />
            <h3 className="fs-4 fw-bold m-0">{title}</h3>
          </Col>
          {username && (
            <Col className="d-flex justify-content-end align-items-center gap-4 h-100">
              <span className="border-end pe-4 custom-border-secondary fs-5">
                {username} 님
              </span>
              <div
                className="d-flex justify-content-center align-items-center gap-2 fs-5 cursor-hover"
                onClick={logout}
              >
                <img src={LogoutSvg} alt="logout" className="" />
                <p className="m-0">로그아웃</p>
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
