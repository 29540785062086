import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";
import { formatPhoneNumber } from "../../hooks/usePhoneNumberFormatter";
import {
  deleteSetTopBox,
  getDistrict,
  getSeniorCenter,
  getSetTopBox,
  getSetTopBoxId,
  putSetTopBox,
} from "../../api/apiClient";
import CenterSearch from "../common/search/CenterSearch";
import CustomTable from "../table/CustomTable";
import OnOffState from "../common/OnOffState";

const SetTopBoxStatus = ({ permissionLevel }) => {
  const [showSetTopBoxModal, setShowSetTopBoxModal] = useState(false); // 모달창
  const [isEditable, setIsEditable] = useState(false); // 필드 활성화 상태
  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
    keyword: "",
  });

  const [formData, setFormData] = useState({
    local: "",
    center: "",
    is_active: true, // 연결 여부: true(연결됨), false(연결 안됨)
    manager_phone_number: "",
    serialNumber: "",
  });

  const columns = [
    { key: "id", title: "번호" },
    { key: "district.name", title: "구" },
    { key: "center.name", title: "경로당" },
    {
      key: "is_active",
      title: "연결상태",
      render: (value) => (
        <img src={value ? On : Off} alt={value ? "on" : "off"} />
      ),
    },
    {
      key: "serial_number",
      title: "기기 시리얼넘버",
      render: (value) => <span>{value ? value : "미등록"}</span>,
    },

    // ...(permissionLevel >= 3 ?[{  key: "actions",
    //   title: "상세보기",}] : [])
    {
      key: "actions",
      title: "상세보기",
      /*
      render: (_, row) => (
        <Button
          className={"rounded-5 px-5 fw-bold fs-5 btn-outline-primary"}
          onClick={() => {
            setShowSetTopBoxModal(true);
            setIsEditable(false);
          }}
          style={{borderColor: "#fff", color: "#fff"}}
        >
          상세보기
        </Button>
      ),
      */
    },
  ];

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getTableData();
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  const getTableData = () => {
    getSetTopBox(
      currentPage,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getSetTopBox", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [setTopId, setSetTopId] = useState(null);

  const handleDetail = (row) => {
    setShowSetTopBoxModal(true);
    setIsEditable(false);
    setSetTopId(row.id);

    // get detail Info
    getSetTopBoxId(row.id)
      .then((res) => {
        console.log("getSetTopBoxId", res.data);
        setFormData({
          local: res.data.district.id,
          center: res.data.center.id,
          is_active: res.data.is_active,
          manager_phone_number: res.data.manager_phone_number,
          serialNumber: res.data.serial_number,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getTableData();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData({
      ...formData,
      manager_phone_number: formatted,
    });
  };

  // 업데이트 핸들러
  const updateField = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // 폼 유효성 검사
  const validateForm = () => {
    const requiredFields = [
      "local",
      "center",
      "is_active",
      "manager_phone_number",
      "serialNumber",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    return true;
  };

  // 저장 버튼
  const handleSend = () => {
    const userConfirmed = window.confirm("저장 하시겠습니까?");
    if (!userConfirmed) {
      return;
    } else {
      /*else if (!validateForm()) {
      alert("필수 입력값을 입력하세요.");
      return;
    }*/
      // save data
      console.log("formData", formData);
      putSetTopBox(setTopId, formData)
        .then((res) => {
          console.log("putSetTopBox", res.data);
          setIsEditable(false);
          setShowSetTopBoxModal(false);
          getTableData();
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }
  };

  // 삭제 버튼
  const handleDelete = () => {
    const userConfirmed = window.confirm("삭제 하시겠습니까?");

    if (!userConfirmed) {
      return;
    } else {
      deleteSetTopBox(setTopId)
        .then((res) => {
          console.log("deleteSetTopBox", res.data);
          setShowSetTopBoxModal(false);
          getTableData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");

    setSearchParam({
      local: "",
      center: "",
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    getTableData();
  };

  // 모달창 내용
  const renderEdit = () => {
    const id = tableData.id;

    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        value={formData.local}
                        onChange={(e) => updateField("local", e.target.value)}
                        disabled={!isEditable}
                      >
                        {districtList &&
                          districtList.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 명
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        value={formData.center}
                        onChange={(e) => updateField("center", e.target.value)}
                        disabled={!isEditable}
                      >
                        {centerList &&
                          centerList.map((center) => (
                            <option key={center.id} value={center.id}>
                              {center.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        연결상태
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-3">
                      <Form.Check
                        inline
                        label="연결"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-1`}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                        // checked={formData.is_active === true}
                        checked={formData.is_active === true}
                        onChange={() => updateField("is_active", true)}
                        disabled={!isEditable}
                      />
                      <Form.Check
                        inline
                        label="연결 안됨"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-2`}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                        // checked={formData.is_active === false}
                        checked={formData.is_active === false}
                        onChange={() => updateField("is_active", false)}
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관리자 연락처
                      </span>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Control
                        type={"text"}
                        placeholder="연락처를 입력하세요."
                        className={"w-100 h-100"}
                        value={formData.manager_phone_number || ""}
                        onChange={(e) => {
                          handleContact(e);
                        }}
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기 시리얼넘버
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="기기 시리얼넘버"
                        value={formData.serialNumber}
                        onChange={(e) =>
                          updateField("serialNumber", e.target.value)
                        }
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          {/* 버튼 */}
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center mt-5"}
          >
            {permissionLevel >= 3 && (
              <>
                {isEditable ? (
                  <Button
                    className={
                      "rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                    }
                    onClick={() => handleSend(id)}
                  >
                    저장
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    className={"rounded-5 px-5 fw-bold fs-5"}
                    onClick={() => setIsEditable(true)}
                  >
                    수정
                  </Button>
                )}
                <Button
                  variant="danger"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              </>
            )}

            {permissionLevel === 2 && (
              <>
                <Button
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => setShowSetTopBoxModal(false)}
                >
                  확인
                </Button>
              </>
            )}

            {permissionLevel === 1 && (
              <>
                <Button
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => setShowSetTopBoxModal(false)}
                >
                  확인
                </Button>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  console.log("tableData", tableData);

  return (
    <Form>
      <Row className="row-gap-3 m-0">
        {/* 경로당 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <OnOffState type={"settop"} />
          <CenterSearch
            title={"경로당 검색"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            districtList={districtList}
            centerList={centerList}
            handleKeywordChange={handleKeywordChange}
            handleCenterChange={handleCenterChange}
            // onSearch={() => {
            //   handleSearch();
            // }}
            onSearch={handleSearch}
          />
        </Col>

        {/* 테이블 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflow: "hidden",
          }}
        >
          {/*<PaginatedTable columns={columns} data={dummyData} pageSize={10}/>*/}
          <CustomTable
            columns={columns}
            tableData={tableData}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleDetail={handleDetail}
          />
        </Col>
      </Row>
      <CustomModal
        show={showSetTopBoxModal}
        onHide={() => setShowSetTopBoxModal(false)}
      >
        {renderEdit()}
      </CustomModal>
    </Form>
  );
};

export default SetTopBoxStatus;
