import React, { useEffect, useState } from "react";
import { Table as BootstrapTable, Button, Col, Spinner } from "react-bootstrap";
import Pagination from "./Pagination"; // Adjust the import path as necessary
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";
import Info from "../../assets/table/info.svg";
import Warning from "../../assets/table/warning.svg";
import Error from "../../assets/table/error.svg";

const CustomTable = ({
  columns,
  tableData,
  handleDetail = undefined,
  currentPage,
  handlePageChange,
}) => {
  const [finalColumns, setFinalColumns] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [loading, setLoading] = useState(true);

  const flattenArray = (arr, parentKey = "", res = {}) => {
    arr.forEach((item, index) => {
      const propName = parentKey ? `${parentKey}[${index}]` : `${index}`;
      if (Array.isArray(item)) {
        flattenArray(item, propName, res);
      } else if (typeof item === "object" && item !== null) {
        flattenObject(item, propName, res);
      } else {
        res[propName] = item;
      }
    });
    return res;
  };

  const flattenObject = (obj, parentKey = "", res = {}) => {
    for (let key in obj) {
      const propName = parentKey ? `${parentKey}.${key}` : key;
      if (Array.isArray(obj[key])) {
        flattenArray(obj[key], propName, res);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  useEffect(() => {
    setFinalColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (tableData) {
      setLoading(false);
    }
  }, [tableData]);

  const handleRowClick = (row) => {
    const isActive = activeRow === row.id || activeRow === row.center_id;

    setActiveRow(isActive ? null : row.id || row.center_id);
    if (!isActive) {
      handleDetail(row);
    }
  };

  const handlePageChangeWithLoading = (newPage) => {
    if (newPage === currentPage) return; // 같은 페이지 로딩 방지
    setLoading(true);
    handlePageChange(newPage);
  };

  return (
    <>
      {loading ? (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <BootstrapTable striped hover>
            <thead>
              <tr>
                {finalColumns.map((col) => (
                  <th
                    key={col.key}
                    className={`fs-5 ${
                      col.title === "경로당 명" ? "text-start" : "text-center"
                    }`}
                    style={{
                      width:
                        ["상세보기", "수정하기", "선택하기", "삭제"].includes(
                          col.title
                        ) && "8rem",
                      minWidth: col.title === "번호" ? "3.5rem" : "auto",
                    }}
                  >
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.results?.map((row, index) => {
                const flattenedRow = flattenObject(row);
                return (
                  <tr key={index} className="custom-table">
                    {finalColumns.map((col) => (
                      <td
                        key={col.key}
                        className={`fs-5 ${
                          col.title === "경로당 명"
                            ? "text-start"
                            : "text-center"
                        }`}
                        style={{
                          maxWidth: "10rem", // 최대 너비를 설정
                          whiteSpace: "nowrap", // 텍스트 줄바꿈 방지
                          overflow: "hidden", // 넘치는 텍스트 숨김
                          textOverflow: "ellipsis", // 말줄임표 처리
                        }}
                        // title={flattenedRow[col.key]}
                        title={
                          typeof flattenedRow[col.key] === "string" ||
                          typeof flattenedRow[col.key] === "number"
                            ? String(flattenedRow[col.key])
                            : undefined
                        }
                      >
                        {col.key === "participant_names" ? (
                          <span>
                            {Array.isArray(row[col.key])
                              ? row[col.key].join(", ")
                              : row[col.key]}
                          </span>
                        ) : ["actions", "edit", "select", "delete"].includes(
                            col.key
                          ) ? (
                          <Col className="text-end" style={{ width: "7rem" }}>
                            <Button
                              className={`rounded-5 px-4 fw-bold fs-5 btn-detail ${
                                activeRow === row.id ||
                                activeRow === row.center_id
                                  ? "hover"
                                  : ""
                              }`}
                              onClick={() => handleRowClick(row)}
                            >
                              {col.key === "actions" && "상세보기"}
                              {col.key === "edit" && "수정하기"}
                              {col.key === "select" && "선택하기"}
                              {col.key === "delete" && "삭제"}
                            </Button>
                          </Col>
                        ) : col.key === "is_active" ? (
                          <img
                            src={flattenedRow[col.key] ? On : Off}
                            alt={flattenedRow[col.key] ? "Active" : "Inactive"}
                          />
                        ) : col.key === "recipients" ? (
                          <>
                            {row.recipients.map((recipient, index) => (
                              <span key={index}>
                                {recipient}
                                {index < row.recipients.length - 1 && ", "}
                              </span>
                            ))}
                          </>
                        ) : col.key === "is_resolved" ? (
                          <>{row.title === true ? "처리완료" : "미처리"}</>
                        ) : col.key === "is_competition" ? (
                          <span>{flattenedRow[col.key] ? "대회" : "일반"}</span>
                        ) : typeof flattenedRow[col.key] === "boolean" ? (
                          <span
                            style={{
                              color: flattenedRow[col.key]
                                ? "#B2FF00"
                                : "#FF1500",
                            }}
                          >
                            {flattenedRow[col.key] ? "ON" : "OFF"}
                          </span>
                        ) : col.key === "grade" ? (
                          <div>
                            <img
                              src={
                                flattenedRow[col.key] === "info"
                                  ? Info
                                  : flattenedRow[col.key] === "warning"
                                  ? Warning
                                  : Error
                              }
                            />
                          </div>
                        ) : flattenedRow[col.key] !== undefined ? (
                          typeof flattenedRow[col.key] === "object" ? (
                            JSON.stringify(flattenedRow[col.key])
                          ) : (
                            flattenedRow[col.key]
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}

              {tableData?.results?.length === 0 && (
                <tr>
                  <td
                    colSpan={finalColumns.length}
                    className={"text-center fs-5 py-3"}
                  >
                    데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </BootstrapTable>
          <Pagination
            propCurPage={currentPage}
            propTotal={tableData?.count || 0}
            propSetNewPage={handlePageChangeWithLoading}
          />
        </>
      )}
    </>
  );
};

export default CustomTable;
