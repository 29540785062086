import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

const CenterSearch = ({
                        title,
                        searchParam,
                        setSearchParam,
                        districtList,
                        centerList,
                        handleCenterChange,
                        handleKeywordChange,
                        onSearch,
                      }) => {

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(e);
    }
  };

  return (
    <Col xs={8}>
      <Row className={""}>
        <Col xs={2} className={"my-auto align-middle pe-0"}>
          <span className="fs-4 fw-bold">| {title || "경로당 검색 필터"}</span>
        </Col>
        <Col xs={2} className="pe-0">
          <Form.Select
            className="h-100 fs-5"
            aria-label="Default select example"
            value={searchParam.local || ""}
            onChange={(e) =>
              setSearchParam({...searchParam, local: e.target.value})
            }
          >
            <option value={""}>구</option>
            {districtList &&
              districtList.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Form.Select
            className="h-100 fs-5"
            aria-label="Default select example"
            value={searchParam.center || ""}
            onChange={handleCenterChange}
          >
            <option value={""}>경로당</option>
            {centerList &&
              centerList.map((center) => (
                <option key={center.id} value={center.id}>
                  {center.name}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={1} className="border rounded text-center p-2 fs-5">
          경로당 명
        </Col>
        <Col xs={4}>
          <Form.Control
            type="text"
            placeholder="검색어를 입력하세요."
            className="h-100 fs-5"
            value={searchParam.keyword || ""}
            onChange={handleKeywordChange}
            disabled={searchParam.center !== ""}
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col xs={1} className={"text-end ps-0"}>
          <Button
            type={"submit"}
            className="w-100 h-100 fs-5 fw-bold"
            onClick={onSearch}
          >
            검색
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default CenterSearch;
