import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

// 시간 문자열을 초 단위로 변환하는 함수
function timeStringToSeconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function Media({videoUrl, startTime, endTime}) {
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [isHLS, setIsHLS] = useState(false); // HLS 여부 확인

  useEffect(() => {
    if (videoUrl) {
      setIsHLS(videoUrl.endsWith('.m3u8'));
    }
  }, [videoUrl]);

  useEffect(() => {
    console.log('HLS:', isHLS);
  }, [isHLS]);

  /*const handleDuration = (duration) => {
    console.log('Video Duration:', duration);
    setVideoDuration(Math.floor(duration)); // 비디오 길이를 저장 (반올림 제거)
  };*/

  useEffect(() => {
    if (!videoDuration || !isReady) return;

    // const now = new Date();

    // new Date를 현재시간 (한국시간)으로 불러오기
    const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/Seoul"}));

    const currentSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    console.log('Current Seconds:', currentSeconds);

    const startPlaybackTime = timeStringToSeconds(startTime);
    console.log('Start Playback Time:', startPlaybackTime);

    const endPlaybackTime = timeStringToSeconds(endTime);
    console.log('End Playback Time:', endPlaybackTime);

    if (currentSeconds >= startPlaybackTime && currentSeconds < endPlaybackTime) {
      const elapsedTime = currentSeconds - startPlaybackTime; // 시작부터 경과된 시간
      console.log('Elapsed Time:', elapsedTime);

      const repeatedVideoTime = elapsedTime % videoDuration; // 반복되는 비디오의 현재 재생 시간
      console.log('Repeated Video Time:', repeatedVideoTime);

      if (!isNaN(repeatedVideoTime) && repeatedVideoTime >= 0) {
        if (isHLS && videoRef.current) {
          videoRef.current.currentTime = repeatedVideoTime; // HLS 영상 seek
        } else {
          console.log('Seeking to:', repeatedVideoTime);
          playerRef.current.seekTo(repeatedVideoTime, 'seconds'); // 일반 영상 seek
        }
      } else {
        console.error('Repeated Video Time is invalid:', repeatedVideoTime);
      }
    } else {
      console.warn('Current time is outside the playback range.');
    }
  }, [videoDuration, isReady, isHLS]); // videoDuration과 isReady 변경 시 실행

  useEffect(() => {
    console.log('Video URL:', videoUrl);

    if (isHLS && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('HLS Loaded');
        setIsReady(true);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS Error:', data);
      });

      return () => hls.destroy();
    }
  }, [videoUrl, isHLS]);

  return (
    <>
      {isHLS ? (
        <video
          ref={videoRef}
          controls={false}
          autoPlay
          loop
          style={{width: '100%', height: '100%'}}
          onLoadedMetadata={() => {
            if (videoRef.current) {
              setVideoDuration(Math.floor(videoRef.current.duration)); // HLS 비디오 길이 저장
            }
          }}
        />
      ) : (
        <ReactPlayer
          ref={playerRef}
          url={videoUrl || ''}
          playing={isReady} // 준비가 완료되었을 때만 재생
          loop
          width="100%"
          height="100%"
          controls={false}
          // controls={true}
          onReady={() => setIsReady(true)} // 준비 완료 설정
          // onDuration={handleDuration} // 비디오 길이를 가져옴
          onDuration={(duration) => setVideoDuration(Math.floor(duration))}
        />
      )}
    </>
  );
}

export default Media;
