import React from "react";
import ReactPaginate from "react-js-pagination";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import "./Pagination.scss";

const Pagination = ({
                      propCurPage = 1,
                      propTotal = 0,
                      propSetNewPage = null,
                      propSetIsSearch,
                    }) => {

  const totalPages = Math.ceil(propTotal / 10); // 총 페이지 수 계산


  const handlePageChange = (page) => {
    if (propSetIsSearch) {
      propSetIsSearch(true);
    }
    propSetNewPage(page);
  };

  return (
    <Row className={"text-center "}>
      <Col md={12} className="d-flex justify-content-center">
        <ReactPaginate
          activePage={propCurPage}
          itemsCountPerPage={10}
          totalItemsCount={propTotal}
          pageRangeDisplayed={5}
          hideFirstLastPages={true}
          prevPageText={
            <div className={"previous fs-6"}>
              <FontAwesomeIcon icon={faAngleLeft} className={""}/>
            </div>
          }
          nextPageText={
            <div className={"next fs-6"}>
              <FontAwesomeIcon icon={faAngleRight} className={""}/>
            </div>
          }
          onChange={handlePageChange}
          activeClass={"active"}
          innerClass={"pagination justify-content-end"}
          itemClass={"page-item ms-2"}
          linkClass={"page-link"}
          itemClassPrev={"prev"}
          itemClassNext={"next"}
          itemClassFirst={"first"}
          itemClassLast={"last"}
        />
        {/*<div style={{display: 'inline-block'}}>
          {propCurPage <= totalPages - 5 && (
            <>
              {' '}
              ...{' '}
              <button
                onClick={() => handlePageChange(totalPages)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#007bff',
                  cursor: 'pointer',
                }}
              >
                {totalPages}
              </button>
            </>
          )}
        </div>*/}
      </Col>
    </Row>
  );
};

export default Pagination;
