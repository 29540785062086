import React, { useState, useEffect } from "react";
//import useScript from '../../hooks/useScript';
import useScript from "../../../hooks/useScript";
import "./conf_custom.css";
// import InitLogo from "../../../assets/common/logo-sm.svg";
import CenterWait from "../../../assets/video/center-wait.jpg";
import StudioWait from "../../../assets/video/studio-wait.jpg";
import { jwtDecode } from "jwt-decode";

const handleJaaSIFrameRef = (iframeRef) => {
  iframeRef.style.border = "0px solid #3d3d3d";
  iframeRef.style.background = "#3d3d3d";
  iframeRef.style.height = "1080px";
  //iframeRef.style.marginBottom = '20px';
};

// ISC App ID
const ICSC_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd";
//const TEST_APP_ID = "vpaas-magic-cookie-8ee4bda167804e1c95671411faac76ca"
const ROOM_NAME = "icsc-stream-prod";

// const TEST_APP_ID = "vpaas-magic-cookie-a88a3f26faf14fa6979d0d8ab1f80475"
// const TEST_TOKEN = "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtOGVlNGJkYTE2NzgwNGUxYzk1NjcxNDExZmFhYzc2Y2EvZDQxNDFkLVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE3Mzk0NDA4NTIsImV4cCI6MTczOTQ0ODA1MiwibmJmIjoxNzM5NDQwODQ3LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtOGVlNGJkYTE2NzgwNGUxYzk1NjcxNDExZmFhYzc2Y2EiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJoaWRkZW4tZnJvbS1yZWNvcmRlciI6ZmFsc2UsIm1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6ImpteWFuZyIsImlkIjoiZ29vZ2xlLW9hdXRoMnwxMDIwMDY0NDkyNzczMTM3MzA1MDIiLCJhdmF0YXIiOiIiLCJlbWFpbCI6ImpteWFuZ0BhaXhjb24uY28ua3IifX0sInJvb20iOiIqIn0.Rdxgkr5SJZWQbpnhY1ydU9Ub-hXzUnyN4u3uvdC0bKtsez6pynnGB7QkPxJ4_AhsuCBHFLl7Fo0QmaJ9NFztX-QU0u-RbscFksCvajXkHgeSg0fipaUp03IYxft0xOOsmDUXNmzRWmKNCUwbzFSH8C5IUXel9T4f9Uiq3lEy_2Xo9nNRdosqPf-evwJnH0blMiAim8g0aVnx1A3icz00CWdjdgiWASY8aWLe2xLZ3rHDuSNqIru8NDIS28fl1WY2b0ZM00rzR5Xd3sXAIKgmkRGAsJSJYF37EtTAvx4w4p-lOugjxN9bLxprSjAVbPi75t7TH7qfIIuir0DEB5Zabg"

// App global state.
//

const state = {
  appId: ICSC_APP_ID,
  room: ROOM_NAME,
  jwt: undefined,
  connection: undefined,
  conference: undefined,
  username: undefined,
};

function Conf_custom({ token }) {
  const [isLoaded, setIsLoaded] = useState(false);
  // const [userName, setUserName] = useState('');

  useScript("https://code.jquery.com/jquery-3.5.1.min.js");
  useScript("https://8x8.vc/libs/lib-jitsi-meet.min.js", setIsLoaded);

  useEffect(() => {
    console.log("token: ", token);
    state.jwt = token;
    // state.jwt = TEST_TOKEN;

    const decodedToken = jwtDecode(token);
    console.log("decodedToken: ", decodedToken);
    // console.log("decodedTokenName", decodedToken.context.user.name); // 사용자의 이름이 출력되어야 합니다.
    // setUserName(decodedToken.context.user.name);
    // state.username = decodedToken?.context?.user?.name;
  }, [token]);

  useEffect(() => {
    if (isLoaded) {
      console.log("JitsiMeetJS is loaded");
      console.log("Lib Jitsi Initialize......");

      // Initialize UI
      createInitialGrid();

      // Initialize library.
      window.JitsiMeetJS.init();
      window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.INFO);
      console.log(`using LJM version ${window.JitsiMeetJS.version}!`);

      // Connect to the room.
      connect();
    }

    return () => {
      leave();
    };
  }, [isLoaded]);

  // Cleanup on unmount. 코드가 없음.

  return (
    // <JaaSMeeting
    //     appId = { YOUR_APP_ID }
    //     roomName={ROOM_NAME}
    //     jwt = { token }
    //     configOverwrite = {{
    //         startWithAudioMuted: true,
    //         prejoinPageEnabled: false,
    //     }}

    //     getIFrameRef={handleJaaSIFrameRef}
    //     />
    <div class="container-fluid h-100">
      <div id="meeting-grid" class="row h-100"></div>
    </div>
  );
}

export default Conf_custom;

function buildOptions(appId, room) {
  return {
    hosts: {
      domain: "8x8.vc",
      muc: `conference.${appId}.8x8.vc`,
    },
    serviceUrl: `wss://8x8.vc/${appId}/xmpp-websocket?room=${room}`,
    websocketKeepAliveUrl: `https://8x8.vc/${appId}/_unlock?room=${room}`,
  };
}

const TrackOps = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

const GRID_SIZE = 44; // 45 participants
let GM = null;

class GridManager {
  MaxGrid = GRID_SIZE;
  cntUsedGrid = 0;
  isUsedGrid = null;
  infoGridId = null;

  constructor() {
    this.isUsedGrid = new Array(this.MaxGrid).fill(false);
    this.infoGridId = new Array(this.MaxGrid).fill(null);
  }

  // get a random node
  getGridNode(vidId) {
    if (this.cntUsedGrid >= this.MaxGrid) {
      return null;
    } else {
      // find id from the list
      for (let i = 0; i < this.MaxGrid; i++) {
        if (this.infoGridId[i] === vidId) {
          return document.getElementById(vidId);
        }
      }

      console.log("GridManager: getGridNode - id not found:", vidId);

      // assign random grid
      let randId = Math.floor(Math.random() * this.MaxGrid);
      let videoNode = null;

      while (this.isUsedGrid[randId]) {
        randId = (randId + 1) % this.MaxGrid;
      }
      this.isUsedGrid[randId] = true;
      this.infoGridId[randId] = vidId;
      this.cntUsedGrid++;

      videoNode = document.querySelector("#placeholder-" + randId);
      console.log(
        "GridManager: getGridNode - assigned grid:",
        randId,
        videoNode
      );
      return videoNode;
    }
  }

  // find a node with video id

  // clean up the grid (pos) or (video id)
  cleanGrid(vidId) {
    for (let i = 0; i < this.MaxGrid; i++) {
      if (this.infoGridId[i] === vidId) {
        let videoNode;
        console.log("GridManager: cleanGrid - found id:", vidId, "at pos:", i);
        videoNode = document.getElementById(vidId);
        setBlankGrid(videoNode, i);
        this.isUsedGrid[i] = null;
        return true; // found and cleaned
      }
    }
    return false; // not found
  }
}

// Create initial grid

function setBlankGrid(videoNode, pos) {
  console.log("setBlankGrid:", videoNode, pos);
  if (videoNode) {
    videoNode.id = `placeholder-${pos}`;
    videoNode.className = "jitsiTrack h-100 "; // Default class
    videoNode.autoplay = true;
    videoNode.style.backgroundImage = `url(${CenterWait})`;
    videoNode.style.backgroundSize = "cover";
    videoNode.style.backgroundRepeat = "no-repeat";
    videoNode.style.backgroundPosition = "center";
    videoNode.style.padding = "0 !important";
    // videoNode.style.backgroundOrigin = "content-box";
  }
}

function createInitialGrid() {
  const meetingGrid = document.getElementById("meeting-grid");

  for (let i = 0; i < GRID_SIZE; i++) {
    const videoNode = document.createElement("video");
    setBlankGrid(videoNode, i);
    meetingGrid.appendChild(videoNode);
  }

  // Create a large video node for "스튜디오"
  const studioNode = document.createElement("video");
  studioNode.id = "studio";
  studioNode.className = "jitsiTrack large h-100"; // Large class for studio
  studioNode.style.gridColumn = "2 / span 10"; // Centered in a 12x12 grid
  studioNode.style.gridRow = "1 / span 10"; // Starting from the first row
  studioNode.autoplay = true;
  studioNode.style.backgroundImage = `url(${StudioWait})`;
  studioNode.style.backgroundSize = "cover";
  studioNode.style.backgroundRepeat = "no-repeat";
  studioNode.style.backgroundPosition = "center";
  // studioNode.style.backgroundOrigin = "content-box";
  meetingGrid.appendChild(studioNode);

  GM = new GridManager();
}

// Handle track added
const handleTrackAdded = (track) => {
  console.log("Track object:", track);
  if (
    !track ||
    typeof track.getType !== "function" ||
    typeof track.attach !== "function"
  ) {
    console.error("Invalid track object:", track);
    return;
  }

  const trackType = track.getType();
  console.log("Track type:", trackType);

  if (trackType === "video") {
    console.log("Track object: video track");
    // Handle video track

    const participant = track.conference.getParticipantById(
      track.getParticipantId()
    );
    const displayName = participant ? participant.getDisplayName() : "";
    let studioTrackName = null;

    if (displayName === "스튜디오") {
      console.log("Track object: 스튜디오 트랙:", track);
      console.log("Track object: conference:", state.conference);
      console.log("Track object: constaraints: ", track.track.getConstraints());
      console.log("Track object: settings: ", track.track.getSettings());
      studioTrackName = track.getSourceName();
    }

    if (studioTrackName != null) {
      state.conference.setReceiverConstraints({
        lastN: 44, // Number of videos requested from the bridge.
        selectedSources: [studioTrackName], // The source names of the video tracks that are prioritized first.
        onStageSources: [studioTrackName], // The source names of the video tracks that are prioritized up to a higher resolution.
        defaultConstraints: { maxHeight: 720 }, // Default resolution requested for all endpoints.
        constraints: {
          // Source specific resolution.
          studioTrackName: { maxHeight: 720 },
        },
      });
    }
    state.conference.setReceiverVideoConstraint(720);
  } else if (trackType === "audio") {
    console.log("This is an audio track");
    // Handle audio track
  } else {
    console.log("Unknown track type");
  }

  const participant = track.conference.getParticipantById(
    track.getParticipantId()
  );
  const displayName = participant ? participant.getDisplayName() : "";
  const meetingGrid = document.getElementById("meeting-grid");

  console.log("Participant display name:", displayName);

  if (track.getType() === "video") {
    let videoNode = null;

    if (displayName === "스튜디오") {
      // Create a large video node for "스튜디오"
      videoNode = document.createElement("video");
      videoNode.id = "studio";
      videoNode.className = "jitsiTrack large"; // Large class for studio
      videoNode.style.gridColumn = "2 / span 10"; // Centered in a 12x12 grid
      videoNode.style.gridRow = "1 / span 10"; // Starting from the first row
      videoNode.autoplay = true;
      videoNode.style.backgroundSize = "cover";
      meetingGrid.appendChild(videoNode);
    } else {
      // Find any available placeholder for other participants
      //videoNode = document.querySelector('#placeholder-' + getRandomGridID());
      console.log("Track Owner ID:", track.getParticipantId());
      videoNode = GM.getGridNode(track.getParticipantId());
    }

    if (videoNode) {
      videoNode.id = track.getParticipantId();
      videoNode.classList.remove("placeholder");
      videoNode.style.backgroundImage = "none";
      videoNode.className =
        displayName === "스튜디오" ? "jitsiTrack large" : "jitsiTrack";
      videoNode.style.width = "100%";
      videoNode.style.height = "100%";
      videoNode.style.padding = "0 !important";
      track.attach(videoNode);
    }
  } else if (!track.isLocal()) {
    const audioNode = document.createElement("audio");
    audioNode.id = track.getId();
    audioNode.className = "jitsiTrack";
    audioNode.autoplay = true;
    document.body.appendChild(audioNode);
    track.attach(audioNode);
  }
};

const handleTrackRemoved = (track) => {
  // clean track
  track.dispose();
};

const onConferenceJoined = () => {
  console.log("conference joined!");
  state.conference.setDisplayName(state.username);
};

const onConferenceLeft = () => {
  console.log("conference left!");
};

const onUserJoined = (id) => {
  console.log("user joined!", id);
};

const onUserLeft = (id) => {
  // Find the video node and remove it
  GM.cleanGrid(id);

  console.log("user left!", id);
};

const onConnectionSuccess = async () => {
  // Initialize conference
  const c = (state.conference = state.connection.initJitsiConference(
    state.room,
    {
      userInfo: {
        displayName: state.username,
      },
    }
  ));

  // Setup event listeners
  c.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, handleTrackAdded);
  c.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, handleTrackRemoved);
  c.on(
    window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
    onConferenceJoined
  );
  c.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
  c.on(window.JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
  c.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);

  try {
    // Create local tracks
    const localTracks = await window.JitsiMeetJS.createLocalTracks({
      devices: ["audio", "video"],
      resolution: 180,
    });

    // Add local tracks before joining
    for (const track of localTracks) {
      if (track.getType() === "audio") {
        // Mute local audio track
        track.mute();
      }
      await c.addTrack(track);
    }
  } catch (e) {
    console.log(e);
  }

  // Join
  c.join();
  // c.setSenderVideoConstraint(720);  // Send at most 720p
  // c.setReceiverVideoConstraint(720);  // Receive at most 360p for each participant
};

const onConnectionFailed = () => {
  console.error("connection failed!");
};

const onConnectionDisconnected = () => {
  console.log("connection disconnected!");
};

async function connect() {
  const options = buildOptions(state.appId, state.room);
  console.log("connecting with options:", options);

  // Create connection.
  const connection = (state.connection = new window.JitsiMeetJS.JitsiConnection(
    null,
    state.jwt,
    options
  ));
  console.log("state.jwt:", state.jwt);

  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    onConnectionDisconnected
  );

  connection.connect();
}

// Leave the room and proceed to cleanup.
async function leave() {
  if (state.conference) {
    await state.conference.leave();
  }

  if (state.connection) {
    await state.connection.disconnect();
  }

  state.connection = undefined;
  state.conference = undefined;
}
