import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { getJWT } from "../../../api/apiClient";
import { jwtDecode } from "jwt-decode";
import Conf_custom from "./conf_custom";

const handleJaaSIFrameRef = (iframeRef) => {
  iframeRef.style.border = "0px solid #3d3d3d";
  iframeRef.style.background = "#3d3d3d";
  iframeRef.style.height = "1080px";
  //iframeRef.style.marginBottom = '20px';
};

// ISC App ID
var YOUR_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd";

//var YOUR_VALID_JWT = "eyJhbGciOiJSUzI1NiIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hODhhM2YyNmZhZjE0ZmE2OTc5ZDBkOGFiMWY4MDQ3NS9lMzQzMDUiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MzM4NTUyMzgsIm5iZlRpbWUiOjE3MzM4NDgwMjgsInJvb20iOiIqIiwic3ViIjoidnBhYXMtbWFnaWMtY29va2llLWE4OGEzZjI2ZmFmMTRmYTY5NzlkMGQ4YWIxZjgwNDc1IiwiY29udGV4dCI6eyJ1c2VyIjp7Im1vZGVyYXRvciI6InRydWUiLCJpZCI6ImI1ZjBiNjVjLTA0MWQtNDliOS1hNjgxLTgwMzRlNDVjNGEwNCIsIm5hbWUiOiLsg6TtjqDrk5zrr7jslZkifSwiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJyZWNvcmRpbmciOiJ0cnVlIiwib3V0Ym91bmQtY2FsbCI6InRydWUiLCJzaXAtb3V0Ym91bmQtY2FsbCI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSJ9fSwiaXNzIjoiY2hhdCIsImF1ZCI6ImppdHNpIn0.Hu_DYqghqyUIaoF74OKqvlOGND3tnpjmaWwJAdm216XeQLuV84oxGM7ytcwLIOTKgivYXmikgPAE1Wr6heFvHk4Eda9TnnBnhvy4vEre2uRfrHEOD1WNCgfY1R6xJgqtivoZR7X5Pa3bnWIC-yWa4IKrbU00NPTBw1g-tvpSDZsLlFwvTo9gd7Q4viXvb7qoryx-_IWJh6papcWohOpYIpjQ6WPG3fnXWA8vnr4EYLjxyHBsO0MElwrAH85yfdD_7QuSfShqdl4TcnZqGltBFI9ONHaLFR8ByrnNoDp94amGf8GVKQ3a2PLl80zOxs1mKcF7AiQHHye6axfeSA1rkg"

function Conf_screen({ token, setToken, centerInfo }) {
  //const { token } = props

  // const [jwt, setJwt] = useState('')

  // useEffect(() => {
  //     getJWT("샤펠드미앙").then((res) => {
  //         console.log("token res : ", res.data.token.slice(2, -1));
  //         setJwt(res.data.token.slice(2, -1));
  //     })
  //     .catch((error) => {
  //         console.error(error);
  //       });
  // }, [])
  useEffect(() => {
    console.log("token: ", token);

    if (token) {
      const decodedToken = jwtDecode(token);
      const expTime = decodedToken.exp * 1000; // 밀리초로 변환

      // 만료 시간 확인 후 갱신 요청 설정
      // if (Date.now() >= expTime) {
      //   requestNewToken(); // 이미 만료된 경우
      // }
      // else {
      //   const timeUntilExpiry = expTime - Date.now();
      //   const refreshBuffer = 2 * 60 * 1000; // 만료 2분 전 갱신

      //   setTimeout(requestNewToken, timeUntilExpiry - refreshBuffer); // 갱신 타이머 설정
      // }
    }
  }, [token]);

  // 새 토큰 요청 함수
  // const requestNewToken = async () => {
  //   if (Object.keys(centerInfo).length !== 0) {
  //     // console.log(centerInfo);
  //     getJWT(centerInfo.name)
  //       .then((res) => {
  //         setToken(res.data.token.slice(2, -1));
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }
  // };

  return (
    // <JaaSMeeting
    //   appId={YOUR_APP_ID}
    //   roomName={"icsc-stream-prod"}
    //   jwt={token}
    //   configOverwrite={{
    //     startWithAudioMuted: true,
    //     prejoinPageEnabled: false,
    //   }}

    //   getIFrameRef={handleJaaSIFrameRef}
    // />
    <Conf_custom token={token} />
  );
}

Conf_screen.propTypes = {};

export default Conf_screen;
