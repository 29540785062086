import React, { useEffect, useState } from "react";
import Frame from "../components/common/Frame";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { getCheckDuplicate, getTokenVerify, postLogin } from "../api/apiClient";
import { useNavigate } from "react-router-dom";
import LogoSVG from "../assets/common/logo.svg";

const Login = () => {
  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("name");
    localStorage.removeItem("type");
  }, []);

  const handleLogin = (e) => {
    // 에러 초기화
    setUsernameError("");
    setPasswordError("");
    setLoginError("");

    let valid = true;
    if (!loginInfo.username.trim()) {
      setUsernameError("아이디를 입력해주세요.");
      valid = false;
    }
    if (!loginInfo.password.trim()) {
      setPasswordError("비밀번호를 입력해주세요.");
      valid = false;
    }
    if (!valid) {
      return;
    }

    setLoading(true);

    postLogin(loginInfo)
      .then(async (res) => {
        console.log("login res : ", res.data);
        if (res.data.access) {
          window.localStorage.setItem("access", res.data?.access);
          window.localStorage.setItem("refresh", res.data?.refresh);
          checkUserType();
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.detail ===
          "지정된 자격 증명에 해당하는 활성화된 사용자를 찾을 수 없습니다"
        ) {
          setLoginError("입력하신 아이디 또는 비밀번호가 올바르지 않습니다.");
        } else if (
          error.code === "ERR_NETWORK" ||
          error.message.includes("timeout")
        ) {
          navigate("/error");
        } else if (error.response?.data?.detail) {
          setLoginError(error.response.data.detail);
        } else {
          setLoginError("로그인에 실패했습니다. 다시 시도해주세요.");
        }
        console.error(error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handleRegister = (e) => {
    e.preventDefault();
    navigate("/message/register");
  };

  const checkUserType = () => {
    getTokenVerify()
      .then((res) => {
        console.log("Token verify response : ", res);
        if (res.data.type === "external") {
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("type", res.data.type);
          localStorage.setItem("authority", JSON.stringify(res.data.authority));
          navigate("/send");
        } else {
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("type", res.data.type);
          localStorage.setItem("authority", JSON.stringify(res.data.authority));
          navigate("/landing");
        }
      })
      .catch((error) => {
        console.error("Token verify error : ", error);
        // navigate("/login");
        alert("토큰 검증에 실패했습니다.");
      })
      .finally(() => {
        setLoading(false); // 최종적으로 로딩 상태 해제
      });
  };

  return (
    <Frame title={"로그인"} showTabMenu={false} className={"my-auto"}>
      <Row
        className="w-50 border rounded px-3 py-5 justify-content-center row-gap-5"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Col xs={7} className={"py-5"}>
          <Row className={"gap-3"}>
            <Col xs={12} className={"text-center"}>
              <img src={LogoSVG} alt="logo" className="w-50" />
            </Col>
            <Col xs={12}>
              <h3 className="text-center" style={{ color: "#3FB5EC" }}>
                스마트 경로당 로그인
              </h3>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
            className={"mt-3"}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label
                column
                sm="3"
                className={"border rounded-3 text-center fw-bold mb-auto"}
              >
                아이디
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="ID"
                  value={loginInfo.username}
                  onChange={(e) => {
                    setLoginInfo({ ...loginInfo, username: e.target.value });
                    setUsernameError("");
                    setPasswordError("");
                  }}
                  isInvalid={!!usernameError}
                />
                <Form.Control.Feedback type="invalid">
                  {usernameError}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label
                column
                sm="3"
                className={"border rounded-3 text-center fw-bold mb-auto"}
              >
                비밀번호
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={loginInfo.password}
                  onChange={(e) => {
                    setLoginInfo({ ...loginInfo, password: e.target.value });
                    setUsernameError("");
                    setPasswordError("");
                  }}
                  isInvalid={!!passwordError || !!loginError}
                />
                {/* 에러 메시지 보여주기 */}
                <Form.Control.Feedback type="invalid">
                  {passwordError || loginError}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Row>
              <Col xs={6} className="mt-4">
                <Button
                  type="submit"
                  className="btn btn-primary btn-lg w-100 fw-bold"
                  variant={"outline-primary"}
                  // onClick={handleLogin}
                  disabled={loading}
                >
                  로그인
                </Button>
              </Col>
              <Col xs={6} className="mt-4">
                <Button
                  type="button"
                  className="btn btn-lg w-100 rounded-5 fw-bold"
                  variant={"outline-secondary"}
                  onClick={handleRegister}
                  style={{
                    backgroundColor: "none",
                  }}
                  disabled={loading}
                >
                  회원가입
                </Button>
              </Col>
            </Row>
          </Form>

          {loading ? (
            <Col
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
              }}
            >
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Frame>
  );
};

export default Login;
