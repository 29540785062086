import React, { useState } from "react";
import Map from "../../assets/common/map.png";
import * as d3 from 'd3';

const districtsCoordinates = {
  "계양구": { x: 430, y: 100 },
  "부평구": { x: 420, y: 190 },
  "남동구": { x: 430, y: 290 },
  "중구": { x: 110, y: 200 },
  "서구": { x: 320, y: 100 },
  "동구": { x: 310, y: 210 },
  "미추홀구": { x: 350, y: 260 },
  "연수구": { x: 330, y: 315 },
  "강화군": { x: 50, y: 50 },
  "옹진군": { x: 50, y: 150 },
};

const MapVisualization = ({ data, type }) => {
  if (!Array.isArray(data)) {
    return <div>Error: Invalid data</div>;
  }

  // 선택된 타입에 따라 최대값 계산
  const maxMeasurement = d3.max(data, (d) => d[type]?.total_duration) || 0;
  const sizeScale = d3.scaleSqrt()
    .domain([0, maxMeasurement])
    .range([5, 50]);

  return (
    <div className="map-container m-3" style={{ position: 'relative', width: '500px', height: '370px' }}>
      <svg
        width="500"
        height="370"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundImage: `url(${Map})`,
          backgroundSize: 'cover',
        }}
      >
        {data
          // 데이터가 있고, 해당 타입의 total_duration이 0보다 큰 경우만 렌더링
          .filter((item) => item[type]?.total_duration > 0)
          .map((item, index) => {
          const district = districtsCoordinates[item.district_name];
          if (!district) return null; // 자치구 좌표가 없는 경우 스킵

          const value = item[type]?.total_duration || 0; // 선택된 타입의 값
          return (
            <circle
              key={index}
              cx={district.x}
              cy={district.y}
              r={sizeScale(value)} // 원 크기 설정
              fill="rgba(0, 200, 100, 0.5)"
              stroke="green"
              strokeWidth="1"
            />
          );
        })}
      </svg>
    </div>
  );
};

export default MapVisualization;
