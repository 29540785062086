import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import {
  deleteAccountsCare,
  deleteAccountsCenter,
  getAccountsAuthority,
  getAccountsCare,
  getAccountsCareDetail,
  getAccountsCenter,
  getCenterDetail,
  getDistricts,
  getSeniorCenter,
  postAccountsCare,
  postAccountsCenter,
  putAccountsCare,
  putAccountsCenter,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";
import CenterSearch from "../common/search/CenterSearch";

const ManageCenter = ({ permissionLevel }) => {
  const [videoDetailModalShow, setVideoDetailModalShow] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false); // 추가/수정
  const [healthDetailModalShow, setHealthDetailModalShow] = useState(false);

  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
    keyword: "",
    center_code: "",
  });

  const [detailFormData, setDetailFormData] = useState({
    name: "",
    district: "",
    center_id: "",
    address: "",
  });

  const [districtList, setDistrictList] = useState([]);
  const [centerList, setCenterList] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "순번" },
    { key: "district.name", title: "소속 구" },
    { key: "name", title: "경로당 명" },
    { key: "center_id", title: "경로당 ID" },
    { key: "address", title: "위치" },
    { key: "edit", title: "수정하기" },
  ];

  const [careTableData, setCareTableData] = useState([]);
  const [careCurrentPage, setCareCurrentPage] = useState(1);
  const careColumns = [
    { key: "id", title: "순번" },
    { key: "center.district.name", title: "소속 구" },
    { key: "center.name", title: "경로당 명" },
    // {key: "center.id", title: "경로당 ID"},
    { key: "user_id", title: "사용자 ID" },
    { key: "gender", title: "성별" },
    { key: "edit", title: "수정하기" },
  ];

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const getDistrictList = () => {
    getDistricts()
      .then((res) => {
        console.log("getDistricts res : ", res.data);
        setDistrictList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 경로당 리스트
  const getSeniorCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getAccountsCenterList = () => {
    getAccountsCenter(
      currentPage,
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      searchParam.center_code
    )
      .then((res) => {
        console.log("getAccountsCenter res : ", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAccountsCareList = () => {
    getAccountsCare(
      careCurrentPage,
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      searchParam.center_code
    )
      .then((res) => {
        console.log("getAccountsCare res : ", res.data);
        setCareTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictList();
    getSeniorCenterList();
    getAccountsCenterList();
    getAccountsCareList();
  }, []);

  useEffect(() => {
    getSeniorCenterList();
    // getAccountsCenterList();
  }, [searchParam.local]);

  const handleAdd = () => {
    setDetailFormData({
      name: "",
      district: "",
      center_id: "",
      address: "",
    });
    setVideoDetailModalShow(true);
    setIsDetailView(false); // 추가 상태로 변경
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("Search parameters:", searchParam);
    setCurrentPage(1);
    getAccountsCenterList();
    setCareCurrentPage(1);
    getAccountsCareList();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getAccountsCenterList();
  };

  const handleCarePageChange = (page) => {
    setCareCurrentPage(page);
    // getAccountsCareList();
  };

  useEffect(() => {
    getAccountsCenterList();
  }, [currentPage]);

  useEffect(() => {
    getAccountsCareList();
  }, [careCurrentPage]);

  const handleDetail = (detail) => {
    getCenterDetail(detail?.id)
      .then((res) => {
        console.log("detail", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));

    setVideoDetailModalShow(true);
    setIsDetailView(true);
  };

  const handleCareAdd = () => {
    setCareDetailFormData({
      center: {
        id: "",
        name: "",
        district: {
          id: null,
          name: "",
        },
      },
      id: null,
      senior_center: null,
      user_id: "",
      gender: "",
    });

    setHealthDetailModalShow(true);
    setIsDetailView(false); // 추가 상태로 변경
  };

  // 저장 버튼
  const handleSave = () => {
    if (!window.confirm("생성 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      district: detailFormData.district,
      center_id: detailFormData.center_id,
      address: detailFormData.address,
    };

    console.log("updatedData", updatedData);

    postAccountsCenter(updatedData)
      .then((res) => {
        console.log("post data", res.data);
        getAccountsCenterList(); // 데이터 갱신
        setVideoDetailModalShow(false);
      })
      .catch((err) => console.error(err));
  };

  // 수정 버튼
  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      district: detailFormData.district.id,
      center_id: detailFormData.center_id,
      address: detailFormData.address,
    };

    console.log("수정 요청 데이터:", updatedData);

    putAccountsCenter(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        setVideoDetailModalShow(false);
        getAccountsCenterList(); // 데이터 갱신
      })
      .catch((error) => {
        console.error("수정 실패:", error);
      });
  };

  // 삭제 버튼
  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteAccountsCenter(detailFormData.id, "center")
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        setVideoDetailModalShow(false); // 모달 닫기
        getAccountsCenterList(); // 데이터 갱신
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderVideoDetail = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row className={"row-gap-3"}>
              <Col
                xs={12}
                className={"d-flex flex-column my-auto align-middle"}
              >
                <span className="fs-4 fw-bold">
                  {!isDetailView ? "| 경로당 ID 생성" : "| 경로당 ID 수정"}
                </span>
                {permissionLevel === 2 || permissionLevel === 1 ? (
                  <span className="my-3 ">&#8251; 수정 권한이 없습니다.</span>
                ) : (
                  <></>
                )}
              </Col>

              {/* 경로당 아이디 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    경로당 명
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    placeholder={"아이디는 자동으로 생성됩니다."}
                    className={"w-100 h-100"}
                    value={detailFormData.center_id}
                    onChange={(e) =>
                      setDetailFormData({
                        ...detailFormData,
                        center_id: e.target.value,
                      })
                    }
                    disabled
                  />
                </Col>
              </Row>

              {/* 경로당 검색 필터 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    경로당 검색 필터
                  </span>
                </Col>
                <Col xs={6}>
                  <Row className="h-100">
                    <Col className="">
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={
                          !isDetailView
                            ? detailFormData.district
                            : detailFormData.district?.id
                        }
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            district: e.target.value,
                          })
                        }
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>전체</option>
                        {districtList.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={detailFormData?.name}
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            name: e.target.value,
                          })
                        }
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>경로당</option>
                        {tableData?.results?.map((center) => (
                          <option key={center.id} value={center.name}>
                            {center.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* 경로당 위치 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    위치 설정
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    placeholder={"경로당 위치"}
                    className={"w-100 h-100"}
                    value={detailFormData.address}
                    onChange={(e) =>
                      setDetailFormData({
                        ...detailFormData,
                        address: e.target.value,
                      })
                    }
                    disabled={permissionLevel === 2 || permissionLevel === 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>

          {!isDetailView ? (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                variant={"primary"}
                onClick={handleSave}
              >
                생성
              </Button>
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5"}
                variant={"secondary"}
                onClick={() =>
                  setDetailFormData({
                    name: "",
                    district: "",
                    center_id: "",
                    address: "",
                  })
                }
              >
                초기화
              </Button>
            </Col>
          ) : (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              {permissionLevel >= 3 && (
                <>
                  <Button
                    type={"button"}
                    className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                    onClick={handleEdit}
                  >
                    저장
                  </Button>
                  <Button
                    type={"button"}
                    variant={"danger"}
                    className={"rounded-5 px-5 fw-bold fs-5"}
                    onClick={handleDelete}
                  >
                    삭제
                  </Button>
                </>
              )}

              {permissionLevel === 2 && (
                <>
                  <Button
                    type={"button"}
                    variant={"secondary"}
                    className={"rounded-5 px-5 fw-bold fs-5 "}
                    onClick={() => setVideoDetailModalShow(false)}
                  >
                    확인
                  </Button>
                </>
              )}

              {permissionLevel === 1 && (
                <>
                  <Button
                    type={"button"}
                    variant={"secondary"}
                    className={"rounded-5 px-5 fw-bold fs-5 "}
                    onClick={() => setVideoDetailModalShow(false)}
                  >
                    확인
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
      </>
    );
  };

  const [careDetailFormData, setCareDetailFormData] = useState({
    center: {
      id: "",
      name: "",
      district: {
        id: null,
        name: "",
      },
    },
    gender: "",
    id: null,
    senior_center: null,
    user_id: "",
  });

  const handleCareDetail = (detail) => {
    getAccountsCareDetail(detail?.id)
      .then((res) => {
        console.log("care detail", res.data);
        setCareDetailFormData(res.data);
      })
      .catch((err) => console.error(err));

    setHealthDetailModalShow(true);
    setIsDetailView(true);
  };

  useEffect(() => {
    console.log("careDetailFormData", careDetailFormData);
  }, [careDetailFormData]);

  const handleCareSave = () => {
    if (!window.confirm("생성 하시겠습니까?")) return;

    const updatedData = {
      user_id: careDetailFormData.user_id,
      gender: careDetailFormData.gender,
      senior_center: careDetailFormData.center.id,
    };

    console.log("updatedData", updatedData);

    postAccountsCare(updatedData)
      .then((res) => {
        console.log("post data", res.data);
        getAccountsCareList(); // 데이터 갱신
        setHealthDetailModalShow(false);
      })
      .catch((err) => console.error(err));
  };

  const handleCareEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updatedData = {
      center: careDetailFormData.center.id,
      username: careDetailFormData.user_id,
      gender: careDetailFormData.gender,
    };

    putAccountsCare(careDetailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        getAccountsCareList(); // 데이터 갱신
        setHealthDetailModalShow(false);
      })
      .catch((error) => {
        console.error("수정 실패:", error);
      });
  };

  const handleCareDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteAccountsCare(careDetailFormData.id)
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        setHealthDetailModalShow(false); // 모달 닫기
        getAccountsCareList(); // 데이터 갱신
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderHealthDetail = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row className={"row-gap-3"}>
              <Col
                xs={12}
                className={"d-flex flex-column my-auto align-middle"}
              >
                <span className="fs-4 fw-bold">
                  {!isDetailView
                    ? "| 헬스&생활케어 ID 생성"
                    : "| 헬스&생활케어 ID 수정"}
                </span>
                {permissionLevel === 2 || permissionLevel === 1 ? (
                  <span className="my-3 ">&#8251; 수정 권한이 없습니다.</span>
                ) : (
                  <></>
                )}
              </Col>

              {/* 경로당 아이디 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    아이디
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    placeholder={"아이디를 입력하세요."}
                    className={"w-100 h-100"}
                    value={careDetailFormData.user_id}
                    onChange={(e) =>
                      setCareDetailFormData({
                        ...careDetailFormData,
                        user_id: e.target.value,
                      })
                    }
                    disabled={permissionLevel === 2 || permissionLevel === 1}
                  />
                </Col>
              </Row>

              {/* 경로당 검색 필터 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    경로당 검색 필터
                  </span>
                </Col>
                <Col xs={6}>
                  <Row className="h-100">
                    <Col className="">
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={
                          !isDetailView
                            ? careDetailFormData.center.district
                            : careDetailFormData.center.district?.id
                        }
                        onChange={(e) =>
                          setCareDetailFormData({
                            ...careDetailFormData,
                            center: {
                              ...careDetailFormData.center,
                              // district: e.target.value,
                              district: {
                                id: e.target.value,
                                name: "",
                              },
                            },
                          })
                        }
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>전체</option>
                        {districtList.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={careDetailFormData?.center.id}
                        onChange={(e) =>
                          setCareDetailFormData({
                            ...careDetailFormData,
                            center: {
                              ...careDetailFormData.center,
                              id: e.target.value,
                              name: e.target.selectedOptions[0].dataset.name,
                            },
                          })
                        }
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>경로당</option>
                        {tableData?.results?.map((center) => (
                          <option
                            key={center.id}
                            value={center.id}
                            data-name={center.name}
                          >
                            {center.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* 성별 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    성별 선택
                  </span>
                </Col>

                <Col xs={6} className="d-flex gap-3">
                  {/*  input radio on/off */}
                  <Form.Check
                    type="radio"
                    label="남성"
                    className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                    name="gender"
                    id="male"
                    checked={careDetailFormData.gender === "male"}
                    onChange={(e) =>
                      setCareDetailFormData({
                        ...careDetailFormData,
                        gender: e.target.id,
                      })
                    }
                    disabled={permissionLevel === 2 || permissionLevel === 1}
                  />
                  <Form.Check
                    type="radio"
                    label="여성"
                    className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                    name={"gender"}
                    id={"female"}
                    checked={careDetailFormData.gender === "female"}
                    onChange={(e) =>
                      setCareDetailFormData({
                        ...careDetailFormData,
                        gender: e.target.id,
                      })
                    }
                    disabled={permissionLevel === 2 || permissionLevel === 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>

          {!isDetailView ? (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                variant={"primary"}
                onClick={handleCareSave}
              >
                생성
              </Button>
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5"}
                variant={"secondary"}
                onClick={() =>
                  setCareDetailFormData({
                    center: {
                      id: "",
                      name: "",
                      district: {
                        id: null,
                        name: "",
                      },
                    },
                    id: null,
                    senior_center: null,
                    user_id: "",
                    gender: "",
                  })
                }
              >
                초기화
              </Button>
            </Col>
          ) : (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              {permissionLevel >= 3 && (
                <>
                  <Button
                    type={"button"}
                    className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                    onClick={handleCareEdit}
                  >
                    저장
                  </Button>
                  <Button
                    type={"button"}
                    variant={"danger"}
                    className={"rounded-5 px-5 fw-bold fs-5"}
                    onClick={handleCareDelete}
                  >
                    삭제
                  </Button>
                </>
              )}

              {permissionLevel === 2 && (
                <>
                  <Button
                    type={"button"}
                    variant={"secondary"}
                    className={"rounded-5 px-5 fw-bold fs-5 "}
                    onClick={() => setHealthDetailModalShow(false)}
                  >
                    확인
                  </Button>
                </>
              )}

              {permissionLevel === 1 && (
                <>
                  <Button
                    type={"button"}
                    variant={"secondary"}
                    className={"rounded-5 px-5 fw-bold fs-5 "}
                    onClick={() => setHealthDetailModalShow(false)}
                  >
                    확인
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        {/* 검색 */}
        <Form onSubmit={handleSearch} className="p-0">
          <Col
            xs={12}
            className="p-3 border rounded d-flex justify-content-start"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Col>
              <Row className="">
                <Col xs={1} className="pe-0 my-auto">
                  <span className="fs-4 fw-bold">| 경로당 검색</span>
                </Col>
                <Col xs={1} className="pe-0">
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.local}
                    onChange={(e) =>
                      setSearchParam({ ...searchParam, local: e.target.value })
                    }
                  >
                    <option value={""}>구</option>
                    {districtList &&
                      districtList.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.center}
                    onChange={handleCenterChange}
                  >
                    <option value={""}>경로당</option>
                    {centerList &&
                      centerList.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={1} className="border rounded text-center p-2 fs-5">
                  경로당 명
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.keyword}
                    onChange={handleKeywordChange}
                    disabled={searchParam.center !== ""}
                  />
                </Col>
                <Col xs={1} className="border rounded text-center p-2 fs-5">
                  검색어
                </Col>
                <Col xs={2} className="pe-0">
                  <Form.Control
                    type="text"
                    placeholder="아이디를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.center_code}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        center_code: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={1} className="">
                  <Button
                    className="w-100 h-100 fs-5 fw-bold"
                    onClick={handleSearch}
                  >
                    검색
                  </Button>
                </Col>
              </Row>
            </Col>
          </Col>
        </Form>

        <Row
          className="gap-3 m-0 p-0"
          style={{
            // height: "calc(100vh - 18rem)",
            overflow: "hidden",
          }}
        >
          {/* 양방향 */}
          <Col
            className={"border p-3 rounded-3"}
            style={{
              backgroundColor: "#1D222A",
              height: "calc(100vh - 17.5rem)",
            }}
          >
            <Row className={"row-gap-3"}>
              <Col xs={6}>
                <span className="fs-4 fw-bold">| 양방향 화상 계정 정보</span>
              </Col>
              {(permissionLevel === 3 || permissionLevel === 2) && (
                <Col xs={6} className={"text-end"}>
                  <Button
                    type={"button"}
                    variant={"primary"}
                    className="px-5 h-100 fs-5 fw-bold"
                    onClick={handleAdd}
                  >
                    생성
                  </Button>
                </Col>
              )}

              <Col xs={12}>
                {tableData ? (
                  <CustomTable
                    columns={columns}
                    tableData={tableData}
                    handleDetail={handleDetail}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>

          {/* 헬스&생활 */}
          <Col
            className={"border p-3 rounded-3"}
            style={{
              backgroundColor: "#1D222A",
              height: "calc(100vh - 17.5rem)",
            }}
          >
            <Row className={"row-gap-3"}>
              <Col xs={6}>
                <span className="fs-4 fw-bold">| 헬스&생활케어 계정 정보</span>
              </Col>

              {(permissionLevel === 3 || permissionLevel === 2) && (
                <Col xs={6} className={"text-end"}>
                  <Button
                    type={"button"}
                    variant={"primary"}
                    className="px-5 h-100 fs-5 fw-bold"
                    onClick={() => handleCareAdd()}
                  >
                    생성
                  </Button>
                </Col>
              )}

              <Col xs={12}>
                {careTableData ? (
                  <CustomTable
                    columns={careColumns}
                    tableData={careTableData}
                    handleDetail={handleCareDetail}
                    currentPage={careCurrentPage}
                    handlePageChange={handleCarePageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

      <CustomModal
        show={videoDetailModalShow}
        onHide={() => {
          setVideoDetailModalShow(false);
          setDetailFormData({
            name: "",
            district: "",
            center_id: "",
            address: "",
          });
        }}
      >
        {renderVideoDetail()}
      </CustomModal>

      <CustomModal
        show={healthDetailModalShow}
        onHide={() => {
          setHealthDetailModalShow(false);
          setCareDetailFormData({
            center: {
              id: "",
              name: "",
              district: {
                id: null,
                name: "",
              },
            },
            id: null,
            senior_center: null,
            user_id: "",
          });
        }}
      >
        {renderHealthDetail()}
      </CustomModal>
    </>
  );
};

export default ManageCenter;
