import React, {useState} from "react";
import Map from "../../assets/common/map.png";
import * as d3 from 'd3';

const districtsCoordinates = {
  "계양구": {x: 430, y: 100},
  "부평구": {x: 420, y: 190},
  "남동구": {x: 430, y: 290},
  "중구": {x: 110, y: 200},
  "서구": {x: 320, y: 100},
  "동구": {x: 310, y: 210},
  "미추홀구": {x: 350, y: 260},
  "연수구": {x: 330, y: 315},
  "강화군": {x: 50, y: 50},
  "옹진군": {x: 50, y: 150},
};

const MapVisualization = ({data, type}) => {

  if (!Array.isArray(data)) {
    // console.error('Invalid data: `data` is not an array.', data);
    return <div>Error: Invalid data</div>;
  }

  const maxMeasurement = d3.max(data, (d) => d.measurements[type]) || 0;
  const sizeScale = d3.scaleSqrt() // 원의 반지름을 설정하는 sqrt scale
    .domain([0, maxMeasurement]) // 측정값 범위
    .range([5, 50]); // 반지름 범위 (최소 5px, 최대 50px)

  /*
   - domain: 입력 데이터 범위
    domain([0, maxMeasurement])
    0은 total_measurements의 최소값(측정값이 없는 경우)
    maxMeasurement는 데이터에서 가장 큰 total_measurements 값

   - range: 출력 반지름 범위
    range([0, 50])
    최소값이 0px, 최대값이 50px로 설정
    즉, total_measurements가 0일 경우 반지름이 0px, 최대값일 경우 반지름이 50px로 설정됩
  */

  return (
    <div className="map-container m-3" style={{position: 'relative', width: '500px', height: '370px'}}>
      <svg
        width="500"
        height="370"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundImage: `url(${Map})`, // 지도 이미지
          backgroundSize: 'cover',
        }}
      >
        {data
          .filter((d) => d.measurements[type] > 0) // 0보다 큰 데이터만 렌더링
          .map((item, index) => {
            const district = districtsCoordinates[item.district.name];
            if (!district) return null; // 자치구 좌표가 없는 경우 스킵

            return (
              <circle
                key={index}
                cx={district.x}
                cy={district.y}
                r={sizeScale(item.measurements[type])} // 원 크기 설정
                fill="rgba(0, 200, 100, 0.5)"
                stroke="green"
                strokeWidth="1"
              />
            );
          })}
      </svg>
    </div>
  );
};

export default MapVisualization;
