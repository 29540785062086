import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Table } from "react-bootstrap";
import CustomTable from "../table/CustomTable";
import CustomModal from "../common/CustomModal";
import {
  deleteAuthority,
  getAuthority,
  getDetailAuthority,
  getMainMenu,
  postAuthority,
  putAuthority,
} from "../../api/apiClient";

const Authority = ({ permissionLevel }) => {
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false); // 추가/수정
  const [menuList, setMenuList] = useState(null);

  const [detailFormData, setDetailFormData] = useState({
    name: "",
    description: "",
    menu_permissions: [],
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "순번" },
    { key: "name", title: "직책" },
    { key: "description", title: "직책 권한 설명" },

    // { key: "actions", title: "상세보기" },
    ...(permissionLevel === 3 ? [{ key: "actions", title: "상세보기" }] : []),
  ];

  // 메인 메뉴 리스트
  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("mainMenu", res.data);
        setMenuList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getAuthorityList = () => {
    getAuthority(currentPage)
      .then((res) => {
        console.log("getAuthority", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAuthorityList();
    getMainMenuList();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAuthorityList();
  };

  // 상세보기 버튼
  const handleDetail = (data) => {
    setDetailModalShow(true);
    setIsDetailView(true); // 상세보기 상태로 변경

    getDetailAuthority(data?.id)
      .then((res) => {
        console.log("detail", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 추가 버튼
  const handleAdd = () => {
    setDetailModalShow(true);
    setIsDetailView(false); // 추가 상태로 변경

    setDetailFormData({ description: "", name: "", menu_permissions: [] });
  };

  // 저장 버튼
  const handleSave = () => {
    if (!detailFormData.name || !detailFormData.description) {
      alert("모든 필드를 입력해주세요.");
      return;
    }

    if (!window.confirm("저장 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      description: detailFormData.description,
      menu_permissions: detailFormData.menu_permissions.map((perm) => ({
        main_menu: perm.main_menu.id,
        permission_level: perm.permission_level,
      })),
    };

    console.log("updatedData", updatedData);

    postAuthority(updatedData)
      .then((res) => {
        console.log(res);
        getAuthorityList();
        setDetailModalShow(false);
      })
      .catch((err) => console.error(err));
  };

  // 수정 버튼
  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      description: detailFormData.description,
      menu_permissions: detailFormData.menu_permissions.map((perm) => ({
        main_menu: perm.main_menu.id,
        permission_level: perm.permission_level,
      })),
    };

    console.log("수정 요청 데이터:", updatedData);

    putAuthority(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        getAuthorityList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error("수정 실패:", error);
      });
  };

  // 삭제 버튼
  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteAuthority(detailFormData.id)
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        getAuthorityList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const detail = () => {
    return (
      <Row className={"justify-content-center"}>
        <Col xs={11}>
          <Form>
            <Row className={"row-gap-3"}>
              <Col xs={12} className={"my-auto align-middle"}>
                <span className="fs-4 fw-bold">
                  {isDetailView ? "| 직책 수정하기" : "| 직책 추가하기"}
                </span>
              </Col>

              <Col xs={12} className={"d-flex flex-column gap-3"}>
                <Row>
                  <Col xs={3} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      직책명
                    </span>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      className="w-100 h-100"
                      type="text"
                      placeholder="직책명"
                      value={detailFormData.name || ""}
                      onChange={(e) =>
                        setDetailFormData({
                          ...detailFormData,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={3} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      직책 권한 설명
                    </span>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      className={"w-100 h-100"}
                      type="text"
                      placeholder="직책 권한 설명 입력"
                      value={detailFormData.description || ""}
                      onChange={(e) =>
                        setDetailFormData({
                          ...detailFormData,
                          description: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={3} className={"align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      권한 선택
                    </span>
                  </Col>
                  <Col xs={8}>
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>권한1</th>
                          <th>권한2</th>
                          <th>권한3</th>
                        </tr>
                      </thead>
                      <tbody>
                        {menuList?.map((menu) => {
                          // 현재 메뉴의 권한 데이터 찾기
                          const permission =
                            detailFormData.menu_permissions?.find(
                              (perm) => perm.main_menu.id === menu.id
                            );

                          return (
                            <tr key={menu.id}>
                              {/* 메뉴명 */}
                              <td>
                                <Form.Check className="ps-1 d-flex align-items-center">
                                  <Form.Check.Input
                                    type="checkbox"
                                    id={`menu-checkbox-${menu.id}`}
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                    }}
                                    // checked={
                                    //   permission?.permission_level !== 0 &&
                                    //   !!permission
                                    // }
                                    // onChange={(e) => {
                                    //   const updatedPermissions = e.target
                                    //     .checked
                                    //     ? [
                                    //         ...detailFormData.menu_permissions,
                                    //         {
                                    //           main_menu: {
                                    //             id: menu.id,
                                    //             display_name: menu.display_name,
                                    //           },
                                    //           permission_level: 1,
                                    //         },
                                    //       ]
                                    //     : detailFormData.menu_permissions.filter(
                                    //         (perm) =>
                                    //           perm.main_menu.id !== menu.id
                                    //       );
                                    //   setDetailFormData({
                                    //     ...detailFormData,
                                    //     menu_permissions: updatedPermissions,
                                    //   });
                                    // }}

                                    // permission_level 0일때 체크 X
                                    checked={detailFormData.menu_permissions.some(
                                      (perm) =>
                                        perm.main_menu.id === menu.id &&
                                        perm.permission_level > 0
                                    )}
                                    onChange={(e) => {
                                      setDetailFormData((prevData) => {
                                        const exists =
                                          prevData.menu_permissions.find(
                                            (perm) =>
                                              perm.main_menu.id === menu.id
                                          );

                                        const updatedPermissions = e.target
                                          .checked
                                          ? exists
                                            ? prevData.menu_permissions.map(
                                                (perm) =>
                                                  perm.main_menu.id === menu.id
                                                    ? {
                                                        ...perm,
                                                        permission_level: 1,
                                                      } // 체크하면 기본값 1로 변경
                                                    : perm
                                              )
                                            : [
                                                ...prevData.menu_permissions,
                                                {
                                                  main_menu: {
                                                    id: menu.id,
                                                    display_name:
                                                      menu.display_name,
                                                  },
                                                  permission_level: 1, // 기본 권한 설정
                                                },
                                              ]
                                          : prevData.menu_permissions.map(
                                              (perm) =>
                                                perm.main_menu.id === menu.id
                                                  ? {
                                                      ...perm,
                                                      permission_level: 0,
                                                    } // 체크 해제 시 permission_level을 0으로 변경
                                                  : perm
                                            );

                                        return {
                                          ...prevData,
                                          menu_permissions: updatedPermissions,
                                        };
                                      });
                                    }}
                                  />
                                  <Form.Check.Label
                                    htmlFor={`menu-checkbox-${menu.id}`}
                                    className="ms-2 fs-5"
                                  >
                                    {menu.display_name}
                                  </Form.Check.Label>
                                </Form.Check>
                              </td>

                              {/* 권한 체크 */}
                              {[1, 2, 3].map((level) => (
                                <td key={level}>
                                  <Form.Check
                                    type="radio"
                                    name={`permission-${menu.id}`}
                                    value={level}
                                    checked={
                                      permission?.permission_level === level
                                    }
                                    disabled={!permission} // 체크박스가 선택되지 않으면 비활성화
                                    onChange={(e) => {
                                      const updatedPermissions =
                                        detailFormData.menu_permissions.map(
                                          (perm) =>
                                            perm.main_menu.id === menu.id
                                              ? {
                                                  ...perm,
                                                  permission_level: Number(
                                                    e.target.value
                                                  ),
                                                }
                                              : perm
                                        );
                                      setDetailFormData({
                                        ...detailFormData,
                                        menu_permissions: updatedPermissions,
                                      });
                                    }}
                                  />
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={12}
                className={
                  "d-flex justify-content-center gap-3 text-center mt-4"
                }
              >
                {isDetailView ? (
                  <>
                    {permissionLevel === 3 && (
                      <>
                        <Button
                          type={"button"}
                          variant={"secondary"}
                          className={"rounded-5 px-5 fw-bold fs-5"}
                          onClick={handleEdit}
                        >
                          수정
                        </Button>
                        <Button
                          type={"button"}
                          variant={"danger"}
                          className={"rounded-5 px-5 fw-bold fs-5"}
                          onClick={handleDelete}
                        >
                          삭제
                        </Button>
                      </>
                    )}

                    {permissionLevel === 2 && (
                      <>
                        <Button
                          type={"button"}
                          variant={"secondary"}
                          className={"rounded-5 px-5 fw-bold fs-5"}
                          onClick={() => {
                            setDetailModalShow(false);
                          }}
                        >
                          확인
                        </Button>
                      </>
                    )}

                    {permissionLevel === 1 && (
                      <>
                        <Button
                          type={"button"}
                          variant={"secondary"}
                          className={"rounded-5 px-5 fw-bold fs-5"}
                          onClick={() => {
                            setDetailModalShow(false);
                          }}
                        >
                          확인
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      type={"button"}
                      className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                      variant={"primary"}
                      onClick={handleSave}
                    >
                      저장
                    </Button>
                    <Button
                      type={"button"}
                      className={"rounded-5 px-5 fw-bold fs-5"}
                      variant={"secondary"}
                      onClick={() =>
                        setDetailFormData({
                          description: "",
                          name: "",
                          menu_permissions: [],
                        })
                      }
                    >
                      초기화
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row className="gap-3">
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 13rem)",
          }}
        >
          <Row className={"row-gap-2"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| 직책 권한 관리</span>
            </Col>

            {(permissionLevel === 3 || permissionLevel === 2) && (
              <>
                <Col xs={12}>
                  <Button
                    className="fs-5 mt-2 fw-bold px-4"
                    type={"button"}
                    variant={"primary"}
                    onClick={handleAdd}
                  >
                    추가
                  </Button>
                </Col>
              </>
            )}

            <Col xs={12} className="mt-3">
              <CustomTable
                columns={columns}
                tableData={tableData}
                handleDetail={handleDetail}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomModal
        show={detailModalShow}
        onHide={() => setDetailModalShow(false)}
      >
        {detail()}
      </CustomModal>
    </>
  );
};

export default Authority;
