import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import CustomTable from "../table/CustomTable";
import {
  deleteAccountsUserStaff,
  getAccountsAuthority,
  getAccountsUserStaff,
  getAccountsUserStaffDetail,
  getAuthorities,
  postAccountsUserStaff,
  putAccountsUserStaff,
} from "../../api/apiClient";
import { formatPhoneNumber } from "../../hooks/usePhoneNumberFormatter";
import usePermissionLevel from "../../hooks/usePermissionLevel";
import getPermissionLevelText from "../../hooks/usePermissionLevel";

const ManageAccount = ({ permissionLevel }) => {
  const [searchParam, setSearchParam] = useState({
    authority: "",
    search: "",
  });

  const [accountsAuthorityList, setAccountsAuthorityList] = useState([]); // 템플릿 카테고리 api
  const [accountsAuthorityContent, setAccountsAuthorityContent] = useState([]); // 템플릿 카테고리 api

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "순번" },
    { key: "authority.name", title: "직책" },
    { key: "username", title: "관리자 ID" },
    { key: "name", title: "이름" },
    { key: "phone", title: "연락처" },
    { key: "actions", title: "상세보기" },
  ];

  const getAuthorityList = () => {
    getAuthorities()
      .then((res) => {
        console.log(res);
        setAccountsAuthorityList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAuthorityIdValue = (authorityId) => {
    return typeof authorityId === "object" && authorityId !== null
      ? authorityId.id
      : authorityId;
  };

  const getAccountsAuthorityContent = (authorityId) => {
    console.log("authorityId1", authorityId);
    console.log("authorityId2", authorityId.id);
    getAccountsAuthority(getAuthorityIdValue(authorityId))
      .then((res) => {
        console.log("getAccountsAuthority", res.data);
        setAccountsAuthorityContent(res.data);
      })
      .catch((error) => {
        console.error(error);
        setAccountsAuthorityContent({});
      });
  };

  const getStaffList = () => {
    getAccountsUserStaff(currentPage, searchParam.search, searchParam.authority)
      .then((res) => {
        console.log("getStaffList", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        setTableData([]);
        console.assert(error);
      });
  };

  const [detailStaffInfo, setDetailStaffInfo] = useState({
    name: "",
    phone: "",
    username: "",
    password: "",
    confirm_password: "",
    authority: "",
  });

  const getStaff = (id, authorityId) => {
    console.log("id", id);
    if (id) {
      getAccountsUserStaffDetail(id)
        .then((res) => {
          console.log("detailStaffInfo", res.data);
          // setDetailStaffInfo(res.data);
          setDetailStaffInfo({
            ...res.data,
            id: id, // id를 상태에 저장
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (authorityId) {
      console.log("authorityId", authorityId);
      getAccountsAuthorityContent(authorityId);
    }
  };

  useEffect(() => {
    getAuthorityList();
    getStaffList();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(searchParam);
    setCurrentPage(1);
    getStaffList();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getStaffList();
  };

  useEffect(() => {
    getStaffList();
  }, [currentPage]);

  const [detailModalShow, setDetailModalShow] = useState(false);
  const [detailRegisterModalShow, setDetailRegisterModalShow] = useState(false);

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setDetailStaffInfo({ ...detailStaffInfo, phone: formatted });
  };

  const handlePutStaff = () => {
    if (detailStaffInfo.password !== detailStaffInfo.confirm_password) {
      alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    if (!window.confirm("수정 하시겠습니까?")) return;

    console.log("detailStaffInfo", detailStaffInfo);

    const updatedData = {
      name: detailStaffInfo.name,
      phone: detailStaffInfo.phone,
      username: detailStaffInfo.username,
      password: detailStaffInfo.password,
      confirm_password: detailStaffInfo.confirm_password,
      authority:
        typeof detailStaffInfo.authority === "object"
          ? detailStaffInfo.authority?.id
          : detailStaffInfo.authority,
    };

    putAccountsUserStaff(detailStaffInfo.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        getStaffList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteStaff = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    console.log("d", detailStaffInfo);
    deleteAccountsUserStaff(detailStaffInfo.id)
      .then((res) => {
        console.log(res);
        getStaffList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderDetailEdit = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                {/* 사용자명 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      사용자명
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"사용자명을 입력하세요."}
                      className={"h-100"}
                      value={detailStaffInfo?.name || ""}
                      onChange={(e) =>
                        setDetailStaffInfo({
                          ...detailStaffInfo,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 연락처 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      사용자 연락처
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"사용자 연락처를 입력하세요."}
                      className={"h-100"}
                      value={detailStaffInfo?.phone || ""}
                      onChange={handleContact}
                    />
                  </Col>
                </Row>

                {/* 아이디 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      아이디
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"아이디를 입력하세요."}
                      className={"h-100"}
                      value={detailStaffInfo?.username || ""}
                      onChange={(e) =>
                        setDetailStaffInfo({
                          ...detailStaffInfo,
                          username: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 비밀번호 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      비밀번호
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"password"}
                      placeholder={"비밀번호를 입력하세요."}
                      className={"h-100"}
                      value={detailStaffInfo?.password || ""}
                      onChange={(e) =>
                        setDetailStaffInfo({
                          ...detailStaffInfo,
                          password: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 비밀번호 확인 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      비밀번호 확인
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"password"}
                      placeholder={"비밀번호를 다시 입력하세요."}
                      className={"h-100"}
                      value={detailStaffInfo?.confirm_password || ""}
                      onChange={(e) =>
                        setDetailStaffInfo({
                          ...detailStaffInfo,
                          confirm_password: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 직책 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      직책
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Select
                      aria-label="Default select example"
                      value={
                        detailStaffInfo?.authority &&
                        detailStaffInfo?.authority?.id
                      }
                      className={"h-100"}
                      onChange={(e) => {
                        console.log("eee", e.target.value);
                        setDetailStaffInfo({
                          ...detailStaffInfo,
                          authority: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>직책 리스트</option>
                      {accountsAuthorityList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                {/* 권한 내용 확인 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      권한 내용 확인
                    </span>
                  </Col>
                  <Col xs={10}>
                    {/*accountsAuthorityContent*/}
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>순번</th>
                          <th>메뉴</th>
                          <th>접근 권한</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountsAuthorityContent.menu_permissions &&
                          accountsAuthorityContent.menu_permissions.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.main_menu.id}</td>
                                <td>{item.main_menu.display_name}</td>
                                <td>
                                  {getPermissionLevelText(
                                    item.permission_level
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {permissionLevel === 3 && (
            <>
              <Col
                xs={12}
                className={
                  "d-flex justify-content-center gap-3 text-center mt-4"
                }
              >
                {/* 버튼 */}
                <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  variant={"secondary"}
                  onClick={(e) => {
                    // console.log(detailStaffInfo);
                    e.preventDefault();
                    handlePutStaff();
                  }}
                >
                  수정
                </Button>
                <Button
                  type={"button"}
                  variant={"danger"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => {
                    handleDeleteStaff();
                  }}
                >
                  삭제
                </Button>
              </Col>
            </>
          )}

          {(permissionLevel === 2 || permissionLevel === 1) && (
            <>
              <Col
                xs={12}
                className={
                  "d-flex justify-content-center gap-3 text-center mt-4"
                }
              >
                {/* 버튼 */}
                <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  variant={"secondary"}
                  onClick={(e) => {
                    // console.log(detailStaffInfo);
                    e.preventDefault();
                    setDetailModalShow(false);
                  }}
                >
                  확인
                </Button>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  };

  const [registerInfo, setRegisterInfo] = useState({
    name: "",
    phone: "",
    username: "",
    password: "",
    confirm_password: "",
    authority: "",
  });

  const handleContactRegister = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setRegisterInfo({ ...registerInfo, phone: formatted });
  };

  const handlePostStaff = () => {
    // 유효성 검사
    const { name, phone, username, password, confirm_password, authority } =
      registerInfo;

    if (
      !name.trim() ||
      !phone.trim() ||
      !username.trim() ||
      !password.trim() ||
      !authority.trim()
    ) {
      alert("모든 필드를 입력해주세요.");
      return;
    }

    if (password !== confirm_password) {
      alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    if (!window.confirm("생성 하시겠습니까?")) return;

    console.log("registerInfo", registerInfo);
    postAccountsUserStaff(registerInfo)
      .then((res) => {
        console.log(res);
        getStaffList();
        setDetailRegisterModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderDetailRegister = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                {/* 사용자명 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      사용자명
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"사용자명을 입력하세요."}
                      className={"h-100"}
                      value={registerInfo.name || ""}
                      onChange={(e) =>
                        setRegisterInfo({
                          ...registerInfo,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 연락처 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      사용자 연락처
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"사용자 연락처를 입력하세요."}
                      className={"h-100"}
                      value={registerInfo.phone || ""}
                      onChange={handleContactRegister}
                    />
                  </Col>
                </Row>

                {/* 아이디 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      아이디
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"text"}
                      placeholder={"아이디를 입력하세요."}
                      className={"h-100"}
                      value={registerInfo.username || ""}
                      onChange={(e) =>
                        setRegisterInfo({
                          ...registerInfo,
                          username: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 비밀번호 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      비밀번호
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"password"}
                      placeholder={"비밀번호를 입력하세요."}
                      className={"h-100"}
                      value={registerInfo.password || ""}
                      onChange={(e) =>
                        setRegisterInfo({
                          ...registerInfo,
                          password: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 비밀번호 확인 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      비밀번호 확인
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type={"password"}
                      placeholder={"비밀번호를 다시 입력하세요."}
                      className={"h-100"}
                      value={registerInfo.confirm_password || ""}
                      onChange={(e) =>
                        setRegisterInfo({
                          ...registerInfo,
                          confirm_password: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {/* 직책 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      직책
                    </span>
                  </Col>
                  <Col xs={5}>
                    <Form.Select
                      aria-label="Default select example"
                      value={registerInfo.authority}
                      className={"h-100"}
                      onChange={(e) =>
                        setRegisterInfo({
                          ...registerInfo,
                          authority: e.target.value,
                        })
                      }
                    >
                      <option value={""}>직책 리스트</option>
                      {accountsAuthorityList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                {/* 권한 내용 확인 */}
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      권한 내용 확인
                    </span>
                  </Col>
                  <Col xs={10}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>순번</th>
                          <th>메뉴</th>
                          <th>접근 권한</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountsAuthorityContent.menu_permissions &&
                          accountsAuthorityContent.menu_permissions.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.main_menu.id}</td>
                                <td>{item.main_menu.display_name}</td>
                                <td>
                                  {getPermissionLevelText(
                                    item.permission_level
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center mt-4"}
          >
            {/* 버튼 */}

            <Button
              type={"button"}
              variant={"success"}
              className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
              onClick={() => {
                handlePostStaff();
              }}
            >
              생성
            </Button>
            <Button
              type={"button"}
              variant={"secondary"}
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={() => {
                setAccountsAuthorityContent([]);
                setRegisterInfo({
                  name: "",
                  phone: "",
                  username: "",
                  password: "",
                  confirm_password: "",
                  authority: "",
                });
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const handleDetail = (info) => {
    console.log("info", info);
    getStaff(info.id, info.authority.id);
    setDetailModalShow(true);
  };

  useEffect(() => {
    if (registerInfo?.authority) {
      getAccountsAuthorityContent(registerInfo?.authority);
    }
  }, [registerInfo.authority]);

  useEffect(() => {
    // console.log(detailStaffInfo.authority);
    if (detailStaffInfo?.authority) {
      getAccountsAuthorityContent(detailStaffInfo?.authority);
    }
  }, [detailStaffInfo.authority]);

  return (
    <>
      <Row className={"row-gap-3"}>
        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form>
            <Row className={"row-gap-3"}>
              <Col xs={1} className="my-auto pe-0">
                <span className="fs-4 fw-bold">| 관리자 검색</span>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      직책
                    </span>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="Default select example"
                      value={searchParam.authority ?? ""}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          authority: e.target.value,
                        })
                      }
                    >
                      <option value={""}>직책 리스트</option>
                      {accountsAuthorityList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      검색어
                    </span>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type={"text"}
                      className="h-100 fs-5"
                      placeholder={"아이디 또는 이름을 입력하세요."}
                      value={searchParam.search}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          search: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      variant={"primary"}
                      type={"submit"}
                      className="px-5 h-100 fs-5 fw-bold"
                      onClick={(e) => {
                        handleSearch(e);
                      }}
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
              </Col>

              {(permissionLevel === 3 || permissionLevel === 2) && (
                <Col className={"text-end"}>
                  <Button
                    variant={"primary"}
                    type={"button"}
                    className="px-5 h-100 fs-5 fw-bold"
                    onClick={() => {
                      setAccountsAuthorityContent([]);
                      setDetailRegisterModalShow(true);
                    }}
                  >
                    생성
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Col>

        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 18rem)",
            overflow: "hidden",
          }}
        >
          <Row className={"row-gap-3"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| 사용자 리스트</span>
            </Col>
            <Col xs={12}>
              {tableData || tableData.length > 0 ? (
                <CustomTable
                  columns={columns}
                  tableData={tableData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={detailModalShow}
        onHide={() => {
          setDetailModalShow(false);
          setDetailStaffInfo({});
        }}
      >
        {renderDetailEdit()}
      </CustomModal>
      <CustomModal
        show={detailRegisterModalShow}
        onHide={() => {
          setDetailRegisterModalShow(false);
          setRegisterInfo({
            name: "",
            phone: "",
            username: "",
            password: "",
            authority: "",
          });
        }}
      >
        {renderDetailRegister()}
      </CustomModal>
    </>
  );
};

export default ManageAccount;
