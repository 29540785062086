import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CustomModal from "../../common/CustomModal";
import useDaysInMonth from "../../../hooks/useDaysInMonth";
import {
  deleteTvsSchedule,
  getTvsScheduleCategory,
  getTvsSchedulesCalendar,
  getTvsVod,
  getTvsVodCategory,
  getTvsVodDetail,
  postTvsSchedule,
  putTvsSchedule,
} from "../../../api/apiClient";
import CustomTable from "../../table/CustomTable";
import { STATIC_FILE_URL } from "../../../config";
import Live from "../../../assets/thumbnail/1_live.jpg";
import Workout from "../../../assets/thumbnail/2_workout.jpg";
import Cook from "../../../assets/thumbnail/3_cook.jpg";
import Sing from "../../../assets/thumbnail/4_sing.jpg";
import Art from "../../../assets/thumbnail/5_art.jpg";
import Education from "../../../assets/thumbnail/6_education.jpg";
import Health from "../../../assets/thumbnail/7_health.jpg";

const Scheduler = ({
  selectedSchedule,
  setSelectedSchedule,
  setSelectedContent,
  permissionLevel,
}) => {
  const [registerModalShow, setRegisterModalShow] = React.useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [vodListModalShow, setVodListModalShow] = useState(false);

  const [searchParam, setSearchParam] = useState({
    year: selectedSchedule.year,
    month: selectedSchedule.month,
    day: selectedSchedule.day,
    category: "",
    resolution: "",
  });

  const [vodCategoryList, setVodCategoryList] = useState([]);
  const [scheduleCategoryList, setScheduleCategoryList] = useState([]);

  console.log("category.id", scheduleCategoryList);

  const getCategoryList = () => {
    getTvsVodCategory()
      .then((res) => {
        console.log("getTvsVodCategory res : ", res.data);
        setVodCategoryList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getTvsScheduleCategory()
      .then((res) => {
        console.log("getTvsScheduleCategory res : ", res.data);
        setScheduleCategoryList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [eventList, setEventList] = useState([]);

  const getScheduleList = () => {
    getTvsSchedulesCalendar(
      searchParam.year,
      searchParam.month,
      searchParam.category,
      searchParam.resolution
    )
      .then((res) => {
        console.log("getTvsSchedulesCalendar res : ", res.data);
        setEventList(res.data.calendar);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCategoryList();
    getScheduleList();
  }, []);

  const calendarRef = useRef(null);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(
      new Date(searchParam.year, searchParam.month - 1, searchParam.day)
    );

    getScheduleList();
  }, [searchParam]);

  const handleYearChange = (e) => {
    setSearchParam({ ...searchParam, year: parseInt(e.target.value) });
  };

  const handleMonthChange = (e) => {
    setSearchParam({ ...searchParam, month: parseInt(e.target.value) });
  };

  const handleDateClick = (arg) => {
    // alert(`Date clicked: ${arg.dateStr}`);
    setSelectedSchedule({
      year: new Date(arg.dateStr).getFullYear(),
      month: new Date(arg.dateStr).getMonth() + 1,
      day: new Date(arg.dateStr).getDate(),
    });

    setSelectedContent("ScheduleSetting");
  };

  const handleEventClick = (arg) => {
    const eventDate = new Date(arg.event.start);
    // alert(`Event date: ${eventDate.getFullYear()}-${eventDate.getMonth() + 1}-${eventDate.getDate()}`);
    setSelectedSchedule({
      year: eventDate.getFullYear(),
      month: eventDate.getMonth() + 1,
      day: eventDate.getDate(),
    });

    setSelectedContent("ScheduleSetting");
  };

  const [formData, setFormData] = useState({
    year: selectedSchedule.year,
    // month: selectedSchedule.month,
    month: new Date().getMonth() + 1,
    // day: selectedSchedule.day,
    day: new Date().getDate(),
    startHour: "00",
    startMinute: "00",
    endHour: "00",
    endMinute: "00",
    category: "",
    resolution: "",
    contentType: "live",
    thumbnail: "",
    title: "",
    content: "",
    vod: {},
  });

  useEffect(() => {
    console.log("formData: ", formData);
  }, [formData]);

  const daysInMonth = useDaysInMonth(formData.year, formData.month);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        thumbnail: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  /*  useEffect(() => {
      // formData.contentType 이 변경될 경우 카테고리, 해상도, 썸네일 초기화
      setFormData({
        ...formData,
        vod: "",
        category: "",
        resolution: "",
        thumbnail: "",
      });
    }, [formData.contentType]);*/

  // 편성 등록
  const registerSchedule = () => {
    console.log("formData : ", formData);

    setFormData({
      year: selectedSchedule.year,
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      startHour: "00",
      startMinute: "00",
      endHour: "00",
      endMinute: "00",
      category: "",
      resolution: "",
      contentType: "live",
      thumbnail: "",
      title: "",
      content: "",
    });

    const postData = new FormData();
    postData.append(
      "start_time",
      `${formData.startHour}:${formData.startMinute}:00`
    );
    postData.append("end_time", `${formData.endHour}:${formData.endMinute}:00`);
    postData.append("title", formData.title);
    postData.append("description", formData.content);
    postData.append("resolution", formData.resolution);
    if (formData.file) {
      postData.append("thumbnail", formData.file);
    } else {
      // postData.append("thumbnail", formData.vod.thumbnail);
    }
    // postData.append("thumbnail", formData.file);
    postData.append("content_type", formData.contentType);
    postData.append("schedule_category", formData.category);
    if (formData.contentType === "vod") {
      postData.append("vod", formData.vod.id);
    }
    // else {
    //   postData.append("schedule_category", formData.category);
    // }
    postData.append("date-type", "single");
    postData.append("year-month", `${formData.year}-${formData.month}`);
    postData.append("date", `${formData.day}`);

    postTvsSchedule(postData)
      .then((res) => {
        console.log("postTvsSchedule res : ", res.data);
        setRegisterModalShow(false);
        getScheduleList();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderRegister = () => {
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className={"d-flex flex-column gap-3"}>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        편성일
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center gap-3">
                      <Col xs={2} className="h-100">
                        <Form.Select
                          name="year"
                          value={formData.year}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className="h-100"
                        >
                          <option value={""}>년 리스트</option>
                          <option value={"2024"}>2024년</option>
                          <option value={"2025"}>2025년</option>
                        </Form.Select>
                      </Col>
                      <Col xs={2} className="h-100">
                        <Form.Select
                          name="month"
                          value={formData.month}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}월
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={2} className="h-100">
                        <Form.Select
                          name="day"
                          value={formData.day}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                              {day}일
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        방송 시간
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center gap-3">
                      <Col xs={2} className={"h-100"}>
                        <Form.Select
                          name="startHour"
                          value={formData.startHour}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          {Array.from({ length: 24 }, (_, i) => (
                            <option key={i} value={i}>
                              {i}시
                            </option>
                          ))}
                          {/* Add start time options */}
                        </Form.Select>
                      </Col>
                      <Col xs={2} className={"h-100"}>
                        <Form.Select
                          name="startMinute"
                          value={formData.startMinute}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          {Array.from({ length: 60 }, (_, i) => (
                            <option key={i} value={i}>
                              {i}분
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      ~
                      <Col xs={2} className={"h-100"}>
                        <Form.Select
                          name="endHour"
                          value={formData.endHour}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          {Array.from({ length: 24 }, (_, i) => (
                            <option key={i} value={i}>
                              {i}시
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={2} className={"h-100"}>
                        <Form.Select
                          name="endMinute"
                          value={formData.endMinute}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          {Array.from({ length: 60 }, (_, i) => (
                            <option key={i} value={i}>
                              {i}분
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        컨텐츠 종류
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-3">
                      <Form.Check
                        type={"radio"}
                        label={"실시간 스트리밍"}
                        name={"contentType"}
                        id={"live"}
                        value={"live"}
                        checked={formData.contentType === "live"}
                        onChange={handleInputChange}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                      />
                      <Form.Check
                        type={"radio"}
                        label={"녹화 영상 콘텐츠"}
                        name={"contentType"}
                        id={"vod"}
                        value={"vod"}
                        checked={formData.contentType === "vod"}
                        onChange={handleInputChange}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                      />
                      {formData.contentType === "vod" && (
                        <>
                          <Form.Control
                            type={"text"}
                            name="vod"
                            value={formData?.vod?.title}
                            placeholder={"선택된 콘텐츠 명"}
                            className={"w-25"}
                            // className="d-inline border rounded p-2 m-0 d-flex align-items-center"
                            readOnly={true}
                          />
                          <Button
                            type={"button"}
                            variant={"primary"}
                            className={""}
                            onClick={() => {
                              setVodListModalShow(true);
                              setCurrentPage(1);
                            }}
                          >
                            녹화 영상 선택
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        카테고리
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center">
                      <Col xs={2} className="h-100">
                        <Form.Select
                          name="category"
                          value={formData.category}
                          onChange={handleInputChange}
                          aria-label="Default select example"
                          className={"h-100"}
                        >
                          <option value={""}>스케줄 카테고리</option>
                          {scheduleCategoryList.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                  {formData.contentType === "live" && (
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                        <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                        >
                          해상도
                        </span>
                      </Col>
                      <Col xs={10} className="d-flex align-items-center">
                        <Col xs={2} className="h-100">
                          <Form.Select
                            name="resolution"
                            value={formData.resolution}
                            onChange={handleInputChange}
                            aria-label="Default select example"
                            className={"h-100"}
                          >
                            <option value={""}>해상도</option>
                            <option value={"480p"}>480p</option>
                            <option value={"720p"}>720p</option>
                            <option value={"1080p"}>1080p</option>
                            <option value={"4K"}>4K</option>
                          </Form.Select>
                        </Col>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        썸네일 업로드
                      </span>
                    </Col>
                    <Col xs={5} className="h-100">
                      <Form.Control
                        type={"file"}
                        accept={"image/*"}
                        name="thumbnail"
                        // onChange={(e) => setFormData({...formData, thumbnail: e.target.files[0]})}
                        className="h-100 align-content-center"
                        onChange={handleThumbnailChange}
                      />
                    </Col>
                  </Row>

                  {formData.thumbnail && (
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                        <span
                          className={
                            "d-block w-100 p-2 rounded-3 text-center fs-5"
                          }
                        >
                          &nbsp;
                        </span>
                      </Col>
                      <Col xs={2}>
                        {formData.thumbnail && (
                          <img
                            // src={formData.thumbnail}
                            // src={formData.thumbnail? STATIC_FILE_URL + formData.thumbnail : "https://via.placeholder.com/150"}
                            src={
                              formData.thumbnail && formData.file
                                ? formData.thumbnail
                                : STATIC_FILE_URL + formData.thumbnail
                            }
                            alt={"thumbnail"}
                            className={"img-fluid w-100 mt-3"}
                          />
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        타이틀
                      </span>
                    </Col>
                    <Col xs={5} className="h-100">
                      <Form.Control
                        type={"text"}
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        placeholder={"타이틀 입력"}
                        className="h-100"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        내용
                      </span>
                    </Col>
                    <Col xs={5} className="h-100">
                      <Form.Control
                        type={"text"}
                        name="content"
                        value={formData.content}
                        onChange={handleInputChange}
                        placeholder={"내용 입력"}
                        className="h-100"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center mt-4"}
          >
            <Button
              variant="secondary"
              onClick={() => {
                setPreviewModalShow(true);
              }}
              className={"rounded-5 px-5 fw-bold fs-5"}
            >
              미리보기
            </Button>
            <Button
              variant="primary"
              className={"rounded-5 px-5 fw-bold fs-5 text-white btn-send"}
              onClick={() => {
                const result = window.confirm("저장 하시겠습니까?");
                if (result) {
                  // 확인 버튼 클릭 시 실행할 코드
                  // alert("확인을 클릭했습니다!");
                  registerSchedule();
                }
              }}
            >
              저장
            </Button>
            <Button
              variant="secondary"
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={() => {
                setFormData({
                  year: selectedSchedule.year,
                  month: selectedSchedule.month,
                  day: new Date().getDate(),
                  startHour: "00",
                  startMinute: "00",
                  endHour: "00",
                  endMinute: "00",
                  category: "",
                  resolution: "",
                  contentType: "live",
                  thumbnail: "",
                  title: "",
                  content: "",
                });
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
        <CustomModal
          show={previewModalShow}
          onHide={() => {
            setPreviewModalShow(false);
          }}
          size={"lg"}
        >
          {renderPreview()}
        </CustomModal>
        <CustomModal
          show={vodListModalShow}
          onHide={() => {
            setVodListModalShow(false);
            setVodSearchParam({
              category: "",
              search: "",
            });
          }}
          size={"lg"}
        >
          {renderVodList()}
        </CustomModal>
      </>
    );
  };

  // 카테고리 기본 이미지 설정
  const defaultCategoryImages = {
    1: Live,
    2: Workout,
    3: Cook,
    5: Sing,
    6: Art,
    7: Education,
    8: Health,
  };

  const renderPreview = () => {
    console.log("selectedSchedule : ", selectedSchedule);
    console.log("formData : ", formData);

    const isBlobUrl = formData?.thumbnail?.startsWith("blob:");

    const thumbnail = isBlobUrl
      ? formData.thumbnail // 새로 업로드한 썸네일은 그대로 사용
      : formData?.thumbnail
      ? STATIC_FILE_URL + formData.thumbnail // 기존 썸네일이면 URL 붙이기
      : formData?.vod?.thumbnail
      ? STATIC_FILE_URL + formData.vod.thumbnail
      : defaultCategoryImages[formData.category] || "";

    // const thumbnail = formData?.thumbnail
    //   ? STATIC_FILE_URL + formData?.thumbnail
    //   : formData?.vod?.thumbnail
    //   ? STATIC_FILE_URL + formData?.vod?.thumbnail
    //   : "";
    const time = `${formData?.startHour}:${formData?.startMinute}`;
    const title = formData?.title;

    return (
      <>
        <Row className={"row-gap-3 py-5"}>
          <Col xs={3}>
            <span
              className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}
            >
              {selectedSchedule.year}.{formData.month}.{formData.day}
              {/* {selectedSchedule.month}. {selectedSchedule.day} */}
            </span>
          </Col>
          <Col xs={12}>
            <Row className={"row-gap-3"}>
              <Col xs={6}>
                <Row className={"row-gap-3"}>
                  <Col xs={12}>
                    {/* <img
                      src={thumbnail ? thumbnail : ""}
                      alt={"thumbnail"}
                      className={"img-fluid w-100 mt-3 border rounded-4"}
                      style={{ aspectRatio: "360/112", objectFit: "cover" }}
                    /> */}
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt=""
                        className="img-fluid w-100 mt-3 border rounded-4"
                        style={{ aspectRatio: "360/112", objectFit: "cover" }}
                        loading="lazy"
                      />
                    ) : (
                      <div
                        className="w-100 mt-3 border rounded-4 text-center"
                        style={{
                          aspectRatio: "360/112",
                          backgroundColor: "#1D222A",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        카테고리를 선택하거나,
                        <br /> 이미지를 업로드 해주세요.
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <span className="fs-4 fw-bold">
                      {time} {title}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const [vodSearchParam, setVodSearchParam] = useState({
    category: "",
    search: "",
  });

  const [vodTableData, setVodTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "category.name", title: "카테고리" },
    { key: "resolution", title: "해상도" },
    { key: "title", title: "콘텐츠 명" },
    { key: "thumbnail", title: "썸네일 유무" },
    { key: "select", title: "선택하기" },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDetail = (row) => {
    console.log("handleDetail row : ", row);

    // state 초기화
    setVodSearchParam({
      category: "",
      search: "",
    });

    // Add this handler for clearing the input value
    const clearThumbnailInput = () => {
      const thumbnailInput = document.querySelectorAll(
        'input[name="thumbnail"]'
      );
      if (thumbnailInput) {
        // thumbnailInput.value = ""; // Reset the value to an empty string
        thumbnailInput.forEach((input) => {
          input.value = "";
        });
      }
    };

    getTvsVodDetail(row.id)
      .then((res) => {
        console.log("getTvsVodDetail res : ", res.data);
        setFormData({
          ...formData,
          // vod: res.data.title,
          vod: res.data,
          category: res.data.category.id,
          resolution: res.data.resolution,
          thumbnail: res.data.thumbnail,
          file: "",
        });
        clearThumbnailInput(); // Clear the input value
        setVodListModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleVodSearch = (e) => {
    e.preventDefault();
    // VOD 영상 리스트 조회
    getVODList();
    setCurrentPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleVodSearch(e);
    }
  };

  const getVODList = () => {
    getTvsVod(vodSearchParam.category, vodSearchParam.search, currentPage)
      .then((res) => {
        console.log("getTvsVod res : ", res.data);
        setVodTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getVODList();
  }, [vodListModalShow, currentPage]);

  const renderVodList = () => {
    return (
      <>
        <Row className={"row-gap-4"}>
          <Col xs={12}>
            <Row className={"row-gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 녹화 영상 선택하기</span>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={4}>
                    <Form>
                      <Form.Select
                        aria-label="Default select example"
                        value={vodSearchParam.category}
                        onChange={(e) =>
                          setVodSearchParam({
                            ...vodSearchParam,
                            category: e.target.value,
                          })
                        }
                      >
                        <option value={""}>카테고리</option>
                        {vodCategoryList.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                        {/* {scheduleCategoryList.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))} */}
                      </Form.Select>
                    </Form>
                  </Col>
                  <Col xs={6}>
                    <Form.Control
                      type={"text"}
                      value={vodSearchParam.search}
                      onChange={(e) =>
                        setVodSearchParam({
                          ...vodSearchParam,
                          search: e.target.value,
                        })
                      }
                      onKeyDown={handleKeyPress}
                      placeholder={"검색어를 입력하세요."}
                    />
                  </Col>
                  <Col xs={2}>
                    <Button
                      type={"button"}
                      className={"w-100"}
                      onClick={(e) => {
                        // VOD 영상 리스트 조회
                        handleVodSearch(e);
                      }}
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            {vodTableData ? (
              <CustomTable
                columns={columns}
                tableData={vodTableData}
                handleDetail={handleDetail}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            ) : (
              <Col className="w-100 text-center my-5">
                <span>검색 결과가 없습니다.</span>
              </Col>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form>
            <Row>
              <Col xs={1} className={"my-auto pe-0"}>
                <span className="fs-4 fw-bold">| 편성 캘린더</span>
              </Col>
              <Col xs={5} className="d-flex gap-3">
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    value={searchParam.year}
                    onChange={handleYearChange}
                  >
                    <option value={2024}>2024년</option>
                    <option value={2025}>2025년</option>
                    <option value={2026}>2026년</option>
                    <option value={2027}>2027년</option>
                    <option value={2028}>2028년</option>
                    {/* Add more years as needed */}
                  </Form.Select>
                </Col>
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    value={searchParam.month}
                    onChange={handleMonthChange}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.category}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value={""}>스케줄 카테고리 전체</option>
                    {/*{vodCategoryList.map((category) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}*/}
                    {scheduleCategoryList.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.resolution}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        resolution: e.target.value,
                      })
                    }
                  >
                    <option value={""}>해상도 전체</option>
                    <option value={"480p"}>480p</option>
                    <option value={"720p"}>720p</option>
                    <option value={"1080p"}>1080p</option>
                    <option value={"4K"}>4K</option>
                  </Form.Select>
                </Col>
              </Col>
              <Col xs={1}>
                <Button
                  type={"button"}
                  className="w-100 h-100 fs-5 fw-bold"
                  onClick={() => {
                    setRegisterModalShow(true);
                  }}
                  disabled={permissionLevel === 1}
                >
                  편성등록
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          xs={12}
          className={"border p-1 rounded-3 datepicker-container"}
          style={{ height: "calc(100vh - 20rem)" }}
        >
          <Row className={"justify-content-center"}>
            <Col xs={10}>
              <FullCalendar
                ref={calendarRef}
                headerToolbar={false} // 헤더 툴바 숨기기
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                /*events={[
                      {title: '14:00 노래교실', date: '2024-12-12'},
                      {title: '18:00 환절기 건강정보', date: '2024-12-13'}
                    ]}*/
                events={eventList.flatMap((day) =>
                  day.schedules.map((schedule) => ({
                    title: `${schedule.time} ${schedule.title}`,
                    date: day.date,
                  }))
                )}
                eventBackgroundColor={"3FB5EC"}
                eventBorderColor={"3FB5EC"}
                locale={"ko"}
                contentHeight={"calc(100vh - 20.5rem)"}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomModal
        show={registerModalShow}
        onHide={() => {
          setRegisterModalShow(false);
          // state 초기화
          setFormData({
            year: selectedSchedule.year,
            month: selectedSchedule.month,
            day: selectedSchedule.day,
            startHour: "00",
            startMinute: "00",
            endHour: "00",
            endMinute: "00",
            category: "",
            resolution: "",
            contentType: "live",
            thumbnail: "",
            title: "",
            content: "",
          });
        }}
        onConfirm={() => alert("편성 등록")}
      >
        {renderRegister()}
      </CustomModal>
    </>
  );
};

export default Scheduler;
