import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";
import {
  getDistrict,
  getSeniorCenter,
  getStatisticsIssue,
} from "../../api/apiClient";
import CenterSearch from "../common/search/CenterSearch";

const IssueOccurrenceStatus = () => {
  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
    keyword: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getStatisticsData = () => {
    getStatisticsIssue(
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getStatisticsData", res);

        setIssueOccursData({
          title: "이슈 사항 발생 통계",
          xAxisCategories: ["장비 alive", "관리자 로그"],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                { y: res.data.issues.unresolved.device, color: "#2ECF8F" },
                { y: res.data.issues.unresolved.admin, color: "#FB72F4" },
              ],
            },
          ],
          yAxisTitle: "Scores",
          height: "80%",
        });

        setIssueProcessData({
          title: "이슈 사항 처리 통계",
          xAxisCategories: ["장비 alive", "관리자 로그"],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                { y: res.data.issues.resolved.device, color: "#2ECF8F" },
                { y: res.data.issues.resolved.admin, color: "#FB72F4" },
              ],
            },
          ],
          yAxisTitle: "Scores",
          height: "80%",
        });
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getStatisticsData();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const [issueOccursData, setIssueOccursData] = useState({});

  const [issueProcessData, setIssueProcessData] = useState({});

  /*const columnChartData1 = {
    title: "이슈 사항 발생 통계",
    xAxisCategories: ["장비 alive", "관리자 로그"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          {y: 70, color: "#2ECF8F"},
          {y: 90, color: "#FB72F4"},
        ],
      },
    ],
    yAxisTitle: "Scores",
    height: "80%",
  };*/
  /*const columnChartData2 = {
    title: "이슈 사항 처리 통계",
    xAxisCategories: ["장비 alive", "관리자 로그"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          {y: 90, color: "#2ECF8F"},
          {y: 70, color: "#FB72F4"},
        ],
      },
    ],
    yAxisTitle: "Scores",
    height: "80%",
  };*/

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    getStatisticsData();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className={"row-gap-3 m-0"}>
        {/* 경로당 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <CenterSearch
            title={"이슈 사항 검색"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            districtList={districtList}
            centerList={centerList}
            handleKeywordChange={handleKeywordChange}
            handleCenterChange={handleCenterChange}
            onSearch={handleSubmit}
          />
        </Col>
        {/* 차트 */}
        <Col
          xs={12}
          style={{
            height: "calc(100vh - 21rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={6} className="ps-0">
              <BasicColumnChart {...issueOccursData} />
            </Col>
            <Col xs={6} className="pe-0">
              <BasicColumnChart {...issueProcessData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default IssueOccurrenceStatus;
