import React, { useEffect, useState } from "react";
import Frame from "../components/common/Frame";
import { Button, Col, Form, Row } from "react-bootstrap";
import { formatPhoneNumber } from "../hooks/usePhoneNumberFormatter";
import {
  getCenters,
  getCheckDuplicate,
  getDistricts,
  getSeniorCenter,
  postAccountsUserRequest,
} from "../api/apiClient";
import { useNavigate } from "react-router-dom";

const MessageRegister = () => {
  const [formData, setFormData] = useState({
    name: "", // 실명
    phone: "", // 연락처
    username: "", // 아이디
    password: "", // 비밀번호
    passwordConfirm: "", // 비밀번호 확인
    center: {
      // 경로당
      district: "", // 행정구
      center: "", // 경로당
    },
    seniorName: "", // 어르신 성함
    seniorPhone: "", // 어르신 연락처
    relation: "", // 관계
    file: "", // 첨부파일
  });

  const [districtsList, setDistrictsList] = useState([]);
  const [centersList, setCentersList] = useState([]);

  const getDistrictsList = () => {
    getDistricts()
      .then((res) => {
        setDistrictsList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCentersList = () => {
    getSeniorCenter(formData.center.district)
      .then((res) => {
        // console.log(res.data);
        setCentersList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictsList();
    getCentersList();
  }, []);

  useEffect(() => {
    getCentersList();
  }, [formData.center.district]);

  const handleUserContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, phone: formatted });
  };

  const handleSeniorContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, seniorPhone: formatted });
  };

  const [checkDuplicated, setCheckDuplicated] = useState(false);

  const handleCheckDuplicate = () => {
    getCheckDuplicate(formData.username)
      .then((res) => {
        // console.log(res);
        if (res.data.is_duplicate) {
          alert("중복된 아이디입니다.");
        } else {
          alert("사용 가능한 아이디입니다.");
          setCheckDuplicated(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileChange = (file) => {
    setFormData({ ...formData, file: file });
  };

  const navigate = useNavigate();

  const postRegister = () => {
    const postFormData = new FormData();
    postFormData.append("username", formData.username);
    postFormData.append("password", formData.password);
    postFormData.append("confirm_password", formData.passwordConfirm);
    postFormData.append("name", formData.name);
    postFormData.append("phone", formData.phone);
    postFormData.append("senior_center", formData.center.center);
    postFormData.append("senior_name", formData.seniorName);
    postFormData.append("senior_phone", formData.seniorPhone);
    postFormData.append("senior_relationship", formData.relation);
    postFormData.append("attachment", formData.file);

    postAccountsUserRequest(postFormData)
      .then((res) => {
        console.log(res);
        // alert("회원가입 신청 완료");
        if (res.status === 201) {
          alert("회원가입 신청 완료");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Frame
      title={"스마트경로당 메시지"}
      showTabMenu={false}
      className={"my-auto"}
    >
      <Form
        className="d-flex flex-column justify-content-center gap-3 p-5"
        style={{
          border: "1px solid #868686",
          borderRadius: "12px",
          backgroundColor: "#1D222A",
        }}
        onSubmit={() => {}}
      >
        <Row className={"py-5"}>
          <Col
            xs={12}
            className={"p-5 rounded-3"}
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"row-gap-5 justify-content-center"}>
              <Col xs={11}>
                <span className="fs-4 fw-bold">| 회원가입</span>
              </Col>
              <Col xs={12}>
                <Row className={"justify-content-around"}>
                  <Col xs={5}>
                    <Row className={"row-gap-4"}>
                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              신청자 명
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="text"
                              className={"w-100 h-100"}
                              placeholder="신청자 명을 입력하세요"
                              value={formData.name}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              연락처
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="text"
                              className={"w-100 h-100"}
                              placeholder="010-0000-0000"
                              value={formData.phone}
                              onChange={(e) => {
                                handleUserContact(e);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              아이디
                            </span>
                          </Col>
                          <Col xs={7}>
                            <Form.Control
                              type="text"
                              className={"w-100 h-100"}
                              placeholder="아이디를 입력하세요"
                              value={formData.username}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  username: e.target.value,
                                });
                              }}
                              disabled={checkDuplicated}
                            />
                          </Col>
                          <Col xs={2}>
                            <Button
                              type={"button"}
                              variant={"primary"}
                              className={"w-100 h-100 "}
                              onClick={() => {
                                handleCheckDuplicate();
                              }}
                            >
                              중복확인
                            </Button>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              비밀번호
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="password"
                              className={"w-100 h-100"}
                              placeholder="비밀번호를 입력하세요"
                              value={formData.password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              비밀번호 확인
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="password"
                              className={"w-100 h-100"}
                              placeholder="비밀번호를 다시 입력하세요"
                              value={formData.passwordConfirm}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  passwordConfirm: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={5}>
                    <Row className={"row-gap-4"}>
                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              경로당 선택
                            </span>
                          </Col>
                          <Col xs={4}>
                            <Form.Select
                              className={"w-100 h-100"}
                              value={formData.center.district}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  center: {
                                    ...formData.center,
                                    district: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value={""}>행정구선택</option>
                              {districtsList.map((district) => (
                                <option key={district.id} value={district.id}>
                                  {district.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col xs={5}>
                            <Form.Select
                              className={"w-100 h-100"}
                              value={formData.center.center}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  center: {
                                    ...formData.center,
                                    center: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value={""}>경로당선택</option>
                              {centersList.map((center) => (
                                <option key={center.id} value={center.id}>
                                  {center.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              어르신 성함
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="text"
                              className={"w-100 h-100"}
                              placeholder="어르신 성함을 입력하세요"
                              value={formData.seniorName}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  seniorName: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              어르신 연락처
                            </span>
                          </Col>
                          <Col xs={9}>
                            <Form.Control
                              type="text"
                              className={"w-100 h-100"}
                              placeholder="010-0000-0000"
                              value={formData.seniorPhone}
                              onChange={(e) => {
                                handleSeniorContact(e);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              관계
                            </span>
                          </Col>
                          <Col xs={7}>
                            <Form.Select
                              className={"w-100 h-100"}
                              value={formData.relation}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  relation: e.target.value,
                                });
                              }}
                            >
                              <option value={""}>관계를 선택하세요</option>
                              <option value={"son"}>아들</option>
                              <option value={"daughter"}>딸</option>
                              <option value={"grandson"}>손자</option>
                              <option value={"granddaughter"}>손녀</option>
                              <option value={"etc"}>기타</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <Row>
                          <Col xs={3} className={"my-auto align-middle"}>
                            <span
                              className={
                                "d-block w-100 border p-2 rounded-3 text-center fs-5"
                              }
                            >
                              첨부파일
                            </span>
                          </Col>
                          <Col xs={7}>
                            <Form.Control
                              type="file"
                              className={"w-100 h-100 align-content-center"}
                              onChange={(e) => {
                                handleFileChange(e.target.files[0]);
                              }}
                            />
                          </Col>
                          <Col xs={2}>
                            <Button
                              type={"button"}
                              variant={"primary"}
                              className={"w-100 h-100"}
                              onClick={() => {
                                document
                                  .querySelector('input[type="file"]')
                                  .click();
                              }}
                            >
                              업로드
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className={"text-center"}>
                <Button
                  type={"submit"}
                  variant={"outline-primary"}
                  className={"py-2 px-5 h-100"}
                  onClick={(e) => {
                    e.preventDefault();
                    postRegister();
                  }}
                >
                  회원가입 신청
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Frame>
  );
};

export default MessageRegister;
