import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";
import { formatPhoneNumber } from "../../hooks/usePhoneNumberFormatter";
import CenterSearch from "../common/search/CenterSearch";
import {
  deleteHealthCareDevice,
  getDistrict,
  getHealthCareDevice,
  getHealthCareDeviceDetail,
  getSeniorCenter,
  putHealthCareDevice,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";
import OnOffState from "../common/OnOffState";

const HealthCareStatus = ({ permissionLevel }) => {
  const [showHealthCareModal, setShowHealthCareModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "district.name", title: "구" },
    { key: "center", title: "경로당" },
    { key: "name", title: "기기명" },
    {
      key: "is_active",
      title: "연결상태",
    },
    {
      key: "device_id",
      title: "기기 시리얼넘버",
    },
    {
      key: "actions",
      title: "상세보기",
    },
  ];

  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
    keyword: "",
    device_type: "",
  });

  const [detailFormData, setDetailFormData] = useState({
    district: "",
    center: "",
    is_active: true, // 연결 여부: true(연결됨), false(연결 안됨)
    manager_phone_number: "",
    device_id: "",
  });

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setDetailFormData({
      ...detailFormData,
      manager_phone_number: formatted,
    });
  };

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [selectedCategory, setSelectedCategory] = useState("");

  const handleButtonClick = (deviceType) => {
    setSelectedCategory(deviceType);

    setSearchParam((prev) => ({
      ...prev,
      device_type: deviceType,
    }));
  };

  useEffect(() => {
    getHealthCareList();
  }, [searchParam.device_type]);

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 헬스케어 기기 동작 현황
  const getHealthCareList = () => {
    getHealthCareDevice(
      currentPage,
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      searchParam.device_type
    )
      .then((res) => {
        console.log("getHealthCare : ", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getHealthCareList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const handleSearch = (e) => {
    e.preventDefault();
    getHealthCareList();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getHealthCareList();
  }, [currentPage]);

  const handleDetail = (data) => {
    console.log("handleDetail id : ", data);
    setShowHealthCareModal(true);
    // setDetailFormData(data);

    getHealthCareDeviceDetail(data?.id)
      .then((res) => {
        console.log("id response data:", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 수정 버튼
  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updatedData = {
      center: detailFormData.center,
      manager_phone_number: detailFormData.manager_phone_number,
      device_id: detailFormData.device_id,
    };

    putHealthCareDevice(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료 : ", res.data);
        setShowHealthCareModal(false);
        getHealthCareList();
      })
      .catch((err) => console.error(err));
  };

  // 삭제 버튼
  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteHealthCareDevice(detailFormData.id)
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        setShowHealthCareModal(false);
        getHealthCareList();
      })
      .catch((err) => console.error(err));
  };

  // 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기명
                      </span>
                    </Col>
                    <Col xs={2}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {detailFormData?.name}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={
                          detailFormData.district ||
                          detailFormData.center?.district?.id
                        }
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            center: {
                              ...detailFormData,
                              district: e.target.value,
                            },
                          });
                        }}
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>구</option>
                        {districtList?.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 명
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={detailFormData?.center?.id}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            center: e.target.value,
                          });
                        }}
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      >
                        <option value={""}>경로당</option>
                        {centerList?.map((center) => (
                          <option key={center.id} value={center.id}>
                            {center.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        연결상태
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-3">
                      <Form.Check
                        type={"radio"}
                        label={"연결됨"}
                        name={"connection"}
                        id={"connection"}
                        className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                        value={true} // 연결됨은 true 값
                        checked={detailFormData.is_active === true} // 체크 상태
                        onChange={() => {
                          setDetailFormData({
                            ...detailFormData,
                            is_active: true, // 연결됨으로 설정
                          });
                        }}
                        disabled
                      />
                      <Form.Check
                        type={"radio"}
                        label={"연결 안 됨"}
                        name={"connection"}
                        id={"disconnection"}
                        className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                        value={false} // 연결 안 됨은 false 값
                        checked={detailFormData.is_active === false} // 체크 상태
                        onChange={() => {
                          setDetailFormData({
                            ...detailFormData,
                            is_active: false, // 연결 안 됨으로 설정
                          });
                        }}
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관리자 연락처
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type={"text"}
                        placeholder="연락처를 입력하세요."
                        className={"w-100 h-100"}
                        value={detailFormData.manager_phone_number || ""}
                        onChange={(e) => {
                          handleContact(e);
                        }}
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기 시리얼넘버
                      </span>
                    </Col>
                    <Col xs={3}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="기기 시리얼넘버"
                        value={detailFormData.device_id || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            device_id: e.target.value,
                          });
                        }}
                        disabled={
                          permissionLevel === 2 || permissionLevel === 1
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          {permissionLevel === 3 && (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center"}
            >
              <Button
                className={"rounded-5 px-5 fw-bold fs-5"}
                variant="secondary"
                onClick={handleEdit}
              >
                수정
              </Button>
              <Button
                className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                variant="danger"
                onClick={handleDelete}
              >
                삭제
              </Button>
            </Col>
          )}

          {(permissionLevel === 2 || permissionLevel === 1) && (
            <>
              <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 text-center"}
              >
                {/* 버튼 */}
                <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  variant={"secondary"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowHealthCareModal(false);
                  }}
                >
                  확인
                </Button>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-2"}>
        {/* 경로당 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <OnOffState type={"healthcare"} />
          <CenterSearch
            title={"경로당 검색"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            districtList={districtList}
            centerList={centerList}
            handleKeywordChange={handleKeywordChange}
            handleCenterChange={handleCenterChange}
            onSearch={handleSearch}
          />
        </Col>

        {/* 테이블 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowX: "hidden",
          }}
        >
          <Col xs={6} className="ps-3 mb-3">
            <Row className="row-gap-2 w-100">
              <Col xs={2} className="border rounded text-center p-2 fs-5">
                기기선택
              </Col>
              {[
                { name: "전체", device_type: "" },
                { name: "혈압", device_type: "blood_pressure" },
                { name: "체온", device_type: "body_temperature" },
                { name: "체중(BMI)", device_type: "weight" },
                { name: "심전도", device_type: "electrocardiogram" },
              ].map((category, index) => (
                <Col xs={2} key={index} className="pe-0">
                  <Button
                    className={`w-100 h-100 btn-outline-primary fs-5 ${
                      selectedCategory === category.device_type
                        ? "bg-primary text-black"
                        : ""
                    }`}
                    style={{ color: "#fff" }}
                    onClick={() => handleButtonClick(category.device_type)}
                  >
                    {category.name}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>

          {tableData ? (
            <CustomTable
              columns={columns}
              tableData={tableData}
              handleDetail={handleDetail}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          ) : (
            <Col className="w-100 text-center my-5">
              <span>검색 결과가 없습니다.</span>
            </Col>
          )}
        </Col>
      </Row>
      <CustomModal
        show={showHealthCareModal}
        onHide={() => setShowHealthCareModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default HealthCareStatus;
