import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import LineChart from "../chart/LineChart";
import {
  getDistrict,
  getIssueStatistics,
  getSeniorCenter,
} from "../../api/apiClient";
import DateSearch from "../common/search/DateSearch";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import CenterSearch from "../common/search/CenterSearch";
import StockChart from "../chart/StockChart";

const IssueStatistics = () => {
  // const lineChartData = {
  //   title: "",
  //   xAxisCategories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

  //   series: [
  //     {
  //       type: "line",
  //       name: "이벤트 주간 발생 현황",
  //       data: [3, 5, 7, 2, 10, 6, 4],
  //     },
  //   ],
  //   yAxisTitle: "Counts",
  // };

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
  });

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const [issueStatistics, setIssueStatistics] = useState(null);
  const [adminLog, setAdminLog] = useState(null);
  const [deviceAlive, setDeviceAlive] = useState(null);
  const [riskCritical, setRiskCritical] = useState(null);

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const [chartData, setChartData] = useState({
    deviceAliveSeries: [],
    adminCountSeries: [],
    riskSeries: {
      very_low: [],
      low: [],
      medium: [],
      high: [],
      critical: [],
    },
  });

  const transformChartData = (data) => {
    const deviceAliveSeries = [];
    const adminCountSeries = [];
    const riskSeries = {
      very_low: [],
      low: [],
      medium: [],
      high: [],
      critical: [],
    };

    Object.entries(data).forEach(([date, values]) => {
      const timestamp = new Date(date).getTime(); // 날짜를 타임스탬프로 변환

      // 장비 상태와 관리자 로그 데이터 추가
      deviceAliveSeries.push([timestamp, values.device_alive_count]);
      adminCountSeries.push([timestamp, values.admin_count]);

      // 위험도 데이터 추가
      Object.keys(riskSeries).forEach((key) => {
        riskSeries[key].push([timestamp, values.risk_levels[key]]);
      });
    });

    return { deviceAliveSeries, adminCountSeries, riskSeries };
  };

  const getIssueStatisticsList = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getIssueStatistics(
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      startData,
      endData
    )
      .then((res) => {
        console.log("getIssueStatistics res : ", res.data);
        const transformedData = transformChartData(res.data); // 데이터 변환
        console.log("transformedData", transformedData);
        setChartData(transformedData); // 상태 업데이트
      })
      .catch((err) => console.error(err));
  };

  const { deviceAliveSeries, adminCountSeries, riskSeries } = chartData;
  const { very_low, low, medium, high, critical } = riskSeries;

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getIssueStatisticsList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const handleSearch = (e) => {
    e.preventDefault();
    getIssueStatisticsList();
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Form className="p-0" onSubmit={handleSearch}>
          <Row className="row-gap-3">
            <DateSearch
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />

            <CenterSearch
              title={"경로당 필터 검색"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              districtList={districtList}
              centerList={centerList}
              handleKeywordChange={handleKeywordChange}
              handleCenterChange={handleCenterChange}
              onSearch={handleSearch}
            />
          </Row>
        </Form>
      </Col>

      {/* 내용 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 23rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">
              | 이슈 사항 카테고리 별 발생 건 수
            </span>
          </Col>

          {/* 장비 차트 */}
          <Row className="justify-content-between m-0">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              장비 alive
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="장비 alive"
                series={deviceAliveSeries}
                lineColor={"#27AE60"}
              />
            </Col>
          </Row>

          {/* 관리자 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              관리자 로그
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="관리자 로그"
                series={adminCountSeries}
                lineColor={"#E467DE"}
              />
            </Col>
          </Row>

          {/* 위험도 긴급 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 긴급
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="위험도 긴급"
                series={critical}
                lineColor={"#27AE60"}
              />
            </Col>
          </Row>

          {/* 위험도 상 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 상
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}{" "}
              <StockChart
                title="위험도 상"
                series={high}
                lineColor={"#E467DE"}
              />
            </Col>
          </Row>

          {/* 위험도 중 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 중
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="위험도 중"
                series={medium}
                lineColor={"#FB7B38"}
              />
            </Col>
          </Row>

          {/* 위험도 하 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 하
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="위험도 하"
                series={very_low}
                lineColor={"#27AE60"}
              />
            </Col>
          </Row>

          {/* 위험도 일반 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 일반
            </Col>
            <Col xs={10}>
              {/* <LineChart {...lineChartData} /> */}
              <StockChart
                title="위험도 일반"
                series={low}
                lineColor={"#E467DE"}
              />
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default IssueStatistics;
