import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import MonitoringImg from "../../assets/landing/monitoring.png";
import Landing from "../../pages/Landing";
import {
  getMainMenu,
  getSubMenu,
  putMainMenu,
  putSubMenu,
} from "../../api/apiClient";
import LogoSvg from "../../assets/common/logo.svg";
import LogoutSvg from "../../assets/common/logout.svg";
import TabMenu from "../common/TabMenu";

const Menu = ({ permissionLevel }) => {
  const STATIC_FILE_URL = process.env.REACT_APP_STATIC_FILE_URL;

  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [subMenuModalShow, setSubMenuModalShow] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [imagePreviews, setImagePreviews] = useState(Array(8).fill(null)); // Assuming 8 menu items
  const fileInputRefs = useRef([]);

  const [menuData, setMenuData] = useState(
    Array(8).fill({
      order: "",
      display_name: "",
      thumbnail: null,
    })
  );
  const [initialMenuList, setInitialMenuList] = useState([]);

  // 메뉴 리스트
  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("mainMenu", res.data);
        setInitialMenuList(res.data); // 초기 데이터 저장
        setMenuData(res.data.map((menu) => ({ ...menu }))); // 상태 업데이트
        setImagePreviews(res.data.map((menu) => menu.thumbnail || null)); // 이미지 초기화
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getMainMenuList();
  }, []);

  // 입력값 변경 핸들러
  const handleMenuInputChange = (index, field, value) => {
    const updatedMenus = [...menuData];

    if (field === "order") {
      const newOrder = parseInt(value, 10); // 선택된 order 값

      // 다른 항목에서 동일한 order 값을 제거
      updatedMenus.forEach((menu, idx) => {
        if (menu.order === newOrder && idx !== index) {
          updatedMenus[idx].order = ""; // 중복된 order 값을 비움
        }
      });

      // 현재 항목의 order 값 업데이트
      updatedMenus[index].order = newOrder || "";
    } else {
      // 일반 필드 업데이트
      updatedMenus[index][field] = value;
    }

    setMenuData(updatedMenus); // 상태 업데이트
  };

  // 이미지 변경
  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    const newImagePreviews = [...imagePreviews];
    const updatedMenus = [...menuData];

    if (file) {
      newImagePreviews[index] = URL.createObjectURL(file); // 미리보기 설정
      updatedMenus[index] = { ...updatedMenus[index], thumbnail: file }; // 파일 저장
    } else {
      newImagePreviews[index] = null;
      updatedMenus[index] = { ...updatedMenus[index], thumbnail: null };
    }

    setImagePreviews(newImagePreviews);
    setMenuData(updatedMenus);
  };

  // 메뉴 초기화 버튼
  const handleMenuReset = () => {
    setMenuData(initialMenuList.map((menu) => ({ ...menu }))); // 초기 데이터 복원
    setImagePreviews(initialMenuList.map((menu) => menu.thumbnail || null)); // 이미지 복원

    // 모든 파일 입력 필드 초기화
    fileInputRefs.current.forEach((input) => {
      if (input) input.value = null;
    });
  };

  const hasEmptyMenuOrder = menuData.some((menu) => menu.order === "");

  // 저장 버튼 (수정)
  const handleMenuSave = () => {
    if (hasEmptyMenuOrder) {
      alert("모든 순서를 선택해주세요.");
      return;
    }

    if (!window.confirm("저장 하시겠습니까?")) return;

    const updatedData = menuData.map((menu) => ({
      id: menu.id,
      order: menu.order,
      display_name: menu.display_name,
      thumbnail:
        menu.thumbnail instanceof File ? menu.thumbnail.name : menu.thumbnail,
    }));

    console.log("수정 데이터:", updatedData);

    putMainMenu(updatedData)
      .then((res) => {
        console.log(`수정 완료:`, res.data);
      })
      .catch((err) => {
        console.error(`수정 실패:`, err);
      });
  };

  // menuData를 order 기준으로 정렬
  const sortedMenuData = [...menuData].sort((a, b) => a.order - b.order || 0);

  const sortedPreviews = sortedMenuData.map((menu) =>
    menu.thumbnail instanceof File
      ? URL.createObjectURL(menu.thumbnail)
      : `${STATIC_FILE_URL}${menu.thumbnail}`
  );

  const preview = () => {
    return (
      <Row>
        <Col xs={12}>
          <Row className="row-gap-4 gap-4">
            {sortedMenuData.map((menu, idx) => (
              <Col
                key={idx}
                className="px-4 border rounded-4 text-center p-3"
                style={{
                  backgroundColor: "#1D222A",
                }}
              >
                {/* 로딩 중일 때 스피너 표시 */}
                {/* {!loaded && (
                  <Col className="d-flex justify-content-center my-3">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                )} */}
                <img
                  className="d-block mx-auto"
                  src={sortedPreviews[idx]}
                  alt={menu.order}
                  style={{
                    width: "10rem",
                    height: loaded ? "10rem" : "",
                    objectFit: "contain",
                    objectPosition: "center",
                    visibility: loaded ? "" : "hidden",
                  }}
                  loading="lazy"
                  onLoad={() => {
                    setLoaded(true);
                  }}
                />
                <h2 className="fs-4 card-name mt-4 fw-bold">
                  {menu.display_name}
                </h2>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  };

  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(localStorage.getItem("name"));
  }, []);

  const [initialSubMenu, setInitialSubMenu] = useState([]); // 초기 데이터 저장
  const [subMenu, setSubMenu] = useState([]);
  const [selectedSubMenu, setSelectedSubMenu] = useState(1); // 선택한 카테고리 ID

  // 서브 메뉴 리스트 조회
  const getSubMenuList = () => {
    getSubMenu(selectedSubMenu)
      .then((res) => {
        console.log("getSubMenu", res.data);
        setInitialSubMenu(res.data);
        setSubMenu(res.data.map((menu) => ({ ...menu })));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (selectedSubMenu) {
      getSubMenuList(selectedSubMenu); // 선택된 카테고리의 서브 메뉴 불러오기
    }
  }, [selectedSubMenu]);

  // const sortedSubMenuData = [...subMenu].sort((a, b) => a.order - b.order || 0);
  const sortedSubMenuData = [
    ...initialSubMenu
      .sort((a, b) => a.order - b.order || 0) // order 기준 정렬
      .reduce((map, item) => {
        if (!map.has(item.display_name)) {
          map.set(item.display_name, item); // display_name이 처음 등장하면 추가
        }
        return map;
      }, new Map())
      .values(), // 중복 제거된 값들만 배열로 변환
  ];

  console.log("=======sortedSubMenuData", sortedSubMenuData);
  //

  // 원하는 순서대로 id를 지정한 배열
  const newArray = [
    { id: 9 },
    { id: 41 },
    { id: 11 },
    { id: 10 },
    { id: 13 },
    { id: 12 },
    { id: 14 },
    { id: 15 },
  ];

  // newArray에서 id 목록을 추출
  const desiredOrder = newArray.map((item) => item.id);
  // console.log("desiredOrder", desiredOrder);

  // subMenu 배열을 desiredOrder의 순서대로 정렬
  // const newSortedSubMenuData = [...subMenu].sort((a, b) => {
  //   const indexA = desiredOrder.indexOf(a.id);
  //   const indexB = desiredOrder.indexOf(b.id);
  //   // 두 id 모두 newArray에 존재하는 경우
  //   return indexA - indexB;
  // });

  const newSortedSubMenuData = [
    ...subMenu
      .filter((item) => desiredOrder.includes(item.id)) // desiredOrder에 없는 id 제거
      .sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.id);
        const indexB = desiredOrder.indexOf(b.id);
        return indexA - indexB; // desiredOrder 순서대로 정렬
      })
      .reduce((map, item) => {
        if (!map.has(item.display_name)) {
          map.set(item.display_name, item); // display_name 중복 제거
        }
        return map;
      }, new Map())
      .values(), // 중복 제거된 값들만 배열로 변환
  ];

  // console.log("=======newSortedSubMenuData", newSortedSubMenuData);

  // 입력값 변경 핸들러
  const handleSubMenuInputChange = (index, field, value) => {
    const updatedMenus = [...subMenu];

    if (field === "order") {
      const newOrder = parseInt(value, 10); // 선택된 order 값

      // 다른 항목에서 동일한 order 값을 제거
      updatedMenus.forEach((menu, idx) => {
        if (menu.order === newOrder && idx !== index) {
          updatedMenus[idx].order = ""; // 중복된 order 값을 비움
        }
      });

      // 현재 항목의 order 값 업데이트
      updatedMenus[index].order = newOrder || "";
    } else {
      // 일반 필드 업데이트
      updatedMenus[index][field] = value;
    }

    setSubMenu(updatedMenus); // 상태 업데이트
  };

  // 세부 카테고리 초기화 버튼
  const handleSubMenuReset = () => {
    setSubMenu(initialSubMenu.map((menu) => ({ ...menu })));
  };

  const hasEmptySubMenuOrder = subMenu.some((menu) => menu.order === "");

  // 세부 카테고리 저장 버튼
  const handleSubMenuSave = () => {
    if (hasEmptySubMenuOrder) {
      alert("모든 순서를 선택해주세요.");
      return;
    }

    if (!window.confirm("저장 하시겠습니까?")) return;

    const updatedData = subMenu.map((menu) => ({
      id: menu.id,
      order: menu.order,
      display_name: menu.display_name,
    }));

    console.log("수정 데이터:", updatedData);

    putSubMenu(selectedSubMenu, updatedData)
      .then((res) => {
        console.log(`수정 완료:`, res.data);
      })
      .catch((err) => {
        console.error(`수정 실패:`, err);
      });
  };

  const categoryPreview = () => {
    return (
      <Row className="border rounded justify-content-center">
        <Row className="header p-4 px-2 w-100">
          <Col xs={12} md={6} className="w-100">
            <Row className="justify-content-between">
              <Col className="d-flex justify-content-start align-items-center gap-2">
                <img
                  src={LogoSvg}
                  alt="Logo"
                  className="border-end pe-2 custom-border-secondary w-25"
                />
                <h3 className="fs-6 fw-bold m-0">실시간 모니터링</h3>
              </Col>
              {username && (
                <Col className="d-flex justify-content-end align-items-center gap-2 h-100">
                  <span
                    className="border-end pe-2 custom-border-secondary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {username} 님
                  </span>
                  <div
                    className="d-flex justify-content-center align-items-center gap-2  cursor-hover"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <img src={LogoutSvg} alt="logout" className="w-25" />
                    <p className="m-0">로그아웃</p>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mb-4 px-0 py-1 tab-menu">
          <Col className="d-flex justify-content-start gap-2 px-3">
            {sortedSubMenuData.map((sub, idx) => (
              <button
                key={idx}
                className={`btn btn-outline-secondary border-0  
                ${
                  sortedSubMenuData[0].display_name === sub.display_name
                    ? "custom-tab-btn text-black"
                    : "btn btn-outline-secondary border-0"
                }
                `}
                style={{ fontSize: "0.75rem", pointerEvents: "none" }}
              >
                {sub.display_name}
              </button>
            ))}
          </Col>
        </Row>
      </Row>
    );
  };

  return (
    <>
      <Row
        className={"gap-3"}
        style={{
          height: "calc(100vh - 13rem)",
        }}
      >
        {/* 메인화면 수정하기 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form className="h-100">
            <Row className="row-gap-3 h-100">
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 메인화면 수정하기</span>
              </Col>
              <Col xs={12} className="px-5">
                <Row>
                  {menuData.map((menu, index) => (
                    <Col key={index}>
                      <Row>
                        {/* 순서 선택 */}
                        <Col xs={12} className="mb-2">
                          <Form.Select
                            className="fs-5"
                            value={menu.order || ""}
                            onChange={(e) =>
                              handleMenuInputChange(
                                index,
                                "order",
                                e.target.value
                              )
                            }
                            disabled={permissionLevel !== 3}
                          >
                            {/* 기본 "순서" 옵션 */}
                            <option value="">순서</option>
                            {/* 순서 목록 생성 */}
                            {Array.from({ length: menuData.length }).map(
                              (_, idx) => {
                                const optionValue = idx + 1; // 1부터 시작하는 순서 값
                                return (
                                  <option key={optionValue} value={optionValue}>
                                    {optionValue}
                                  </option>
                                );
                              }
                            )}
                          </Form.Select>
                        </Col>

                        {/* 이미지 업로드 */}
                        <Col xs={12} className="mb-2">
                          <Form.Control
                            className="fs-5"
                            type="file"
                            ref={(el) => (fileInputRefs.current[index] = el)}
                            onChange={(e) => handleImageChange(index, e)}
                            accept="image/*"
                            disabled={permissionLevel !== 3}
                          />
                          {/* 로딩 중일 때 스피너 표시 */}
                          {/* {!loaded && (
                            <Col className="d-flex justify-content-center my-3">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </Col>
                          )} */}
                          <img
                            src={
                              menu.thumbnail instanceof File
                                ? imagePreviews[index] // 업로드된 파일 미리보기
                                : `${STATIC_FILE_URL}${menu.thumbnail}` // 기존 URL
                            }
                            alt={`Image Preview ${index}`}
                            className="p-4"
                            style={{
                              width: "10rem",
                              height: "10rem",
                              objectFit: "contain",
                              objectPosition: "center",
                              // display: loaded ? "" : "none",
                            }}
                            loading="lazy"
                            onLoad={() => {
                              setLoaded(true);
                            }}
                          />
                        </Col>

                        {/* 메뉴 이름 수정 */}
                        <Col xs={12}>
                          <Form.Control
                            className="fs-5"
                            type="text"
                            placeholder="메뉴 수정"
                            value={menu.display_name || ""}
                            onChange={(e) =>
                              handleMenuInputChange(
                                index,
                                "display_name",
                                e.target.value
                              )
                            }
                            disabled={permissionLevel !== 3}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>

              {/* 버튼 그룹 */}
              <Col
                xs={12}
                className="d-flex justify-content-center gap-3 mt-auto"
              >
                <Button
                  type="button"
                  variant="secondary"
                  className="rounded-5 px-5 fw-bold fs-5"
                  onClick={() => {
                    if (hasEmptyMenuOrder) {
                      alert("모든 순서를 선택해주세요.");
                      return;
                    }
                    setPreviewModalShow(true);
                  }}
                >
                  미리보기
                </Button>
                {permissionLevel === 3 && (
                  <>
                    <Button
                      type="button"
                      variant="secondary"
                      className="rounded-5 px-5 fw-bold fs-5"
                      onClick={handleMenuReset}
                    >
                      초기화
                    </Button>

                    <Button
                      type="button"
                      className="rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                      onClick={handleMenuSave}
                    >
                      저장
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Col>

        {/* 세부 카테고리 수정하기 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form className="h-100">
            <Row className={"row-gap-3 h-100"}>
              <Col xs={12}>
                <Row>
                  <Col xs={2}>
                    <span className="fs-4 fw-bold">
                      | 세부 카테고리 수정하기
                    </span>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      className={"fs-5"}
                      value={selectedSubMenu}
                      onChange={(e) => setSelectedSubMenu(e.target.value)}
                    >
                      {initialMenuList.map((menu) => (
                        <option key={menu.id} value={menu.id}>
                          {menu.display_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col xs={8} className="mx-auto">
                <Row
                  className={"row-gap-3"}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)", // 2열
                    gridTemplateRows: "repeat(4, 1fr)", // 4행
                    gap: "1rem", // 셀 간 간격
                    height: "14rem", // 전체 높이
                  }}
                >
                  {subMenu[1]?.main_menu === 2
                    ? newSortedSubMenuData?.map((menu, idx) => (
                        <Col xs={6} key={menu.id} className="w-100">
                          <Row>
                            <Col xs={3}>
                              <Form.Select
                                className="fs-5"
                                style={{ flex: "1" }}
                                value={menu.order || ""}
                                onChange={(e) =>
                                  handleSubMenuInputChange(
                                    idx,
                                    "order",
                                    e.target.value
                                  )
                                }
                                disabled={permissionLevel !== 3}
                              >
                                <option value="">순서</option>
                                {Array.from({ length: subMenu.length }).map(
                                  (_, optionIdx) => {
                                    const optionValue = optionIdx + 1;
                                    return (
                                      <option
                                        key={optionValue}
                                        value={optionValue}
                                      >
                                        {optionValue}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </Col>
                            <Col xs={9}>
                              <Form.Control
                                className="fs-5"
                                style={{ flex: "2" }}
                                type="text"
                                value={menu.display_name || ""}
                                placeholder="세부 카테고리 명"
                                onChange={(e) =>
                                  handleSubMenuInputChange(
                                    idx,
                                    "display_name",
                                    e.target.value
                                  )
                                }
                                disabled={permissionLevel !== 3}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ))
                    : subMenu?.map((menu, idx) => (
                        <Col xs={6} key={menu.id} className="w-100">
                          <Row>
                            <Col xs={3}>
                              <Form.Select
                                className="fs-5"
                                style={{ flex: "1" }}
                                value={menu.order || ""}
                                onChange={(e) =>
                                  handleSubMenuInputChange(
                                    idx,
                                    "order",
                                    e.target.value
                                  )
                                }
                                disabled={permissionLevel !== 3}
                              >
                                <option value="">순서</option>
                                {Array.from({ length: subMenu.length }).map(
                                  (_, optionIdx) => {
                                    const optionValue = optionIdx + 1;
                                    return (
                                      <option
                                        key={optionValue}
                                        value={optionValue}
                                      >
                                        {optionValue}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </Col>
                            <Col xs={9}>
                              <Form.Control
                                className="fs-5"
                                style={{ flex: "2" }}
                                type="text"
                                value={menu.display_name || ""}
                                placeholder="세부 카테고리 명"
                                onChange={(e) =>
                                  handleSubMenuInputChange(
                                    idx,
                                    "display_name",
                                    e.target.value
                                  )
                                }
                                disabled={permissionLevel !== 3}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ))}
                </Row>
              </Col>
              <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 mt-auto"}
                style={{
                  height: "3rem",
                }}
              >
                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => {
                    if (hasEmptySubMenuOrder) {
                      alert("모든 순서를 선택해주세요.");
                      return;
                    }
                    setSubMenuModalShow(true);
                  }}
                >
                  미리보기
                </Button>
                {permissionLevel === 3 && (
                  <>
                    <Button
                      type={"button"}
                      variant="secondary"
                      className={"rounded-5 px-5 fw-bold fs-5"}
                      onClick={handleSubMenuReset}
                    >
                      초기화
                    </Button>

                    <Button
                      type={"button"}
                      className={
                        "rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                      }
                      onClick={handleSubMenuSave}
                    >
                      저장
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <CustomModal
        show={previewModalShow}
        onHide={() => setPreviewModalShow(false)}
        onConfirm={() => alert("미리보기")}
      >
        {preview()}
      </CustomModal>
      <CustomModal
        show={subMenuModalShow}
        onHide={() => setSubMenuModalShow(false)}
        onConfirm={() => alert("미리보기")}
      >
        {categoryPreview()}
      </CustomModal>
    </>
  );
};

export default Menu;
