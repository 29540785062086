import React, { useEffect, useState } from "react";
import Frame from "../components/common/Frame";
import ProgramSchedule from "../components/VideoConference/ProgramSchedule";
import VideoSystemManagement from "../components/VideoConference/VideoSystemManagement";
import CMS from "../components/VideoConference/CMS";
import ConvenientFunctionManagement from "../components/VideoConference/ConvenientFunctionManagement";
import MessageMemberManagement from "../components/VideoConference/MessageMemberManagement";
import DeviceOperationStatus from "../components/VideoConference/DeviceOperationStatus";
import Usage from "../components/VideoConference/Usage";
import { useLocation, useNavigate } from "react-router-dom";
import { getMainMenu, getSubMenu, getTokenVerify } from "../api/apiClient";
import { Col, Spinner } from "react-bootstrap";
import Error from "../components/common/Error";
import ClientMonitoring from "../components/VideoConference/ClientMonitoring";
import SetTopBoxStatus from "../components/monitoring/SetTopBoxStatus";

const VideoConference = () => {
  // get location State
  const location = useLocation();
  // console.log(location.state);

  const permissionLevel = location.state.menu.permission_level;
  console.log(permissionLevel);

  /* 토큰 검증 */
  const navigate = useNavigate();

  useEffect(() => {
    getTokenVerify()
      .then((res) => {
        console.log("Token verify response : ", res);
      })
      .catch((error) => {
        console.error("Token verify error : ", error);
        navigate("/login");
      });
  }, []);

  const [initialSubMenu, setInitialSubMenu] = useState([]); // 초기 데이터 저장
  const [selectedSubMenu, setSelectedSubMenu] = useState(2); // 선택한 카테고리 ID
  const [selectedContent, setSelectedContent] = useState(null);
  const [mainTitle, setMainTitle] = useState([]);

  const [loading, setLoading] = useState(true);

  // 메인 메뉴
  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("getMainMenu", res.data);
        setMainTitle(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 서브 메뉴 리스트 조회
  const getSubMenuList = () => {
    setLoading(true);

    getSubMenu(selectedSubMenu)
      .then((res) => {
        console.log("getSubMenu", res.data);
        setInitialSubMenu(res.data);
        if (res.data.length > 0) {
          setSelectedContent(res.data[0].order + 8); // Set default selected tab
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedSubMenu) {
      getSubMenuList(selectedSubMenu); // 선택된 카테고리의 서브 메뉴 불러오기
    }
    getMainMenuList();
  }, [selectedSubMenu]);

  // const sortedSubMenuData = [...initialSubMenu].sort(
  //   (a, b) => a.order - b.order || 0
  // );

  const sortedSubMenuData = [
    ...initialSubMenu
      .sort((a, b) => a.order - b.order || 0) // order 기준 정렬
      .reduce((map, item) => {
        if (!map.has(item.display_name)) {
          map.set(item.display_name, item); // display_name이 처음 등장하면 추가
        }
        return map;
      }, new Map())
      .values(), // 중복 제거된 값들만 배열로 변환
  ];

  console.log("=======sortedSubMenuData", sortedSubMenuData);

  const subMenuComponents = {
    9: <ProgramSchedule permissionLevel={permissionLevel} />,
    10: <VideoSystemManagement permissionLevel={permissionLevel} />,
    11: <CMS permissionLevel={permissionLevel} />,
    12: <ConvenientFunctionManagement permissionLevel={permissionLevel} />,
    13: <MessageMemberManagement permissionLevel={permissionLevel} />,
    14: <SetTopBoxStatus permissionLevel={permissionLevel} />,
    15: <Usage />,
    41: <ClientMonitoring permissionLevel={permissionLevel} />,
    // 9: <ProgramSchedule permissionLevel={permissionLevel} />, // 편성표 관리
    // 10: <ClientMonitoring />, // 실시간
    // 11: <CMS permissionLevel={permissionLevel} />, // CMS
    // 12: <VideoSystemManagement permissionLevel={permissionLevel} />, //경로당간 영상통화 관리
    // 13: <MessageMemberManagement permissionLevel={permissionLevel} />, // 외부 메세지 회원 관리
    // 14: <ConvenientFunctionManagement permissionLevel={permissionLevel} />, // 편의기능 관리
    // 15: <DeviceOperationStatus permissionLevel={permissionLevel} />, // 셋톱기기 동작 현황
    // 16: <Usage />, // 이용량
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Col
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100vw", height: "80vh", zIndex: 100 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      );
    }

    const selectedMenu = initialSubMenu.find(
      (menu) => menu.id === selectedContent
    );
    return selectedMenu ? (
      subMenuComponents[selectedMenu.id] || <Error />
    ) : (
      <Error />
    );
  };

  return (
    <Frame
      // title={`${mainTitle[1]?.display_name || ""}`}
      title={
        mainTitle.find((menu) => menu.id === selectedSubMenu)?.display_name ||
        ""
      }
      showTabMenu={true}
      subMenu={sortedSubMenuData}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default VideoConference;
