import React, {useEffect, useState} from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import {
  getDetailIssue,
  getDistrict,
  getIssue,
  getIssueCategory,
  getRiskCategory,
  getSeniorCenter,
  getSolutionCategory,
  postIssueMessage,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import CustomTable from "../table/CustomTable";
import "./Issue.scss";

const IssueManagement = ({
                           permissionLevel
                         }) => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [messageFormData, setMessageFormData] = useState({
    content: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {key: "created_at", title: "발생일시"},
    {key: "category_info.name", title: "카테고리"},
    {key: "risk_level_info.name", title: "위험도"},
    {key: "center_info.district", title: "구"},
    {key: "center_info.name", title: "경로당명"},
    {key: "scenario_info.name", title: "내용"},
    {key: "manager_info.name", title: "담당자"},
    {key: "manager_info.phone", title: "연락처"},
    {key: "is_resolved", title: "처리상태"},
    {key: "actions", title: "상세보기"},
  ];

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
    search: "",
    risk_level: "",
    category: "",
    is_resolved: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [issueCategory, setIssueCategory] = useState();
  const [riskCategory, setRiskCategory] = useState();
  const [solutionCategory, setSolutionCategory] = useState();
  const [selectedIssue, setSelectedIssue] = useState(null);

  const getIssueCategoryList = () => {
    getIssueCategory()
      .then((res) => {
        console.log("getIssueCategoryList", res.data);
        setIssueCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getRiskCategoryList = () => {
    getRiskCategory()
      .then((res) => {
        console.log("getRistCategoy", res.data);
        setRiskCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getSolutionCategoryList = () => {
    getSolutionCategory()
      .then((res) => {
        console.log("getSolutionCategory", res.data);
        setSolutionCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getIssueList = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getIssue(
      currentPage,
      searchParam.search,
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      searchParam.risk_level,
      searchParam.category,
      searchParam.is_resolved,
      startData,
      endData
    )
      .then((res) => {
        console.log("getIssueList", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getIssueList();
    getIssueCategoryList();
    getRiskCategoryList();
    getSolutionCategoryList();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getIssueList();
  };

  useEffect(() => {
    getIssueList();
  }, [currentPage]);

  const handleDetail = (data) => {
    setShowDetailModal(true);
    getDetailIssue(data?.id)
      .then((res) => {
        console.log("getDetailIssue", res.data);
        setSelectedIssue(res.data);
      })
      .catch((err) => console.error(err));
  };

  const postMessage = () => {
    postIssueMessage(selectedIssue.id, messageFormData)
      .then((res) => {
        console.log(res.data);
        setMessageFormData({content: ""});
        getDetailIssue(selectedIssue.id)
          .then((res) => {
            // console.log("업데이트된 이슈 데이터:", res.data);
            setSelectedIssue(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    getIssueList();
  };

  console.log("selectedIssue", selectedIssue);

  // 모달창 내용
  const renderEdit = ({onConfirm}) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <BootstrapTable striped hover responsive>
                      <thead>
                      <tr>
                        {[
                          "발생일시",
                          "카테고리",
                          "위험도",
                          "구",
                          "경로당명",
                          "내용",
                          "담당자",
                          "연락처",
                          "처리상태",
                        ].map((col, idx) => (
                          <th key={idx}>{col}</th>
                        ))}
                      </tr>
                      </thead>
                      <tbody>
                      <tr className="modal-table">
                        <td>{selectedIssue?.created_at}</td>
                        <td>{selectedIssue?.category_info.category.name}</td>
                        <td>
                          {selectedIssue?.category_info.risk_level.name}
                        </td>
                        <td>{selectedIssue?.center_info.district}</td>
                        <td>{selectedIssue?.center_info.name}</td>
                        <td>{selectedIssue?.category_info.name}</td>
                        <td>{selectedIssue?.center_info.manager.name}</td>
                        <td>{selectedIssue?.center_info.manager.phone}</td>
                        <td>
                          {selectedIssue?.is_resolved === true
                            ? "처리완료"
                            : "미처리"}
                        </td>
                      </tr>
                      </tbody>
                    </BootstrapTable>
                  </Row>

                  {(permissionLevel === 3 || permissionLevel === 2) && (
                    <Row
                      className="p-3 border rounded"
                      style={{backgroundColor: "#1D222A"}}
                    >
                      <Col xs={12} className="mb-2">
                        <span className="fs-4 fw-bold">| 메세지 보내기</span>
                      </Col>
                      <Col xs={10}>
                        <Form.Control
                          type="text"
                          className="w-100 h-100"
                          value={messageFormData.content || ""}
                          onChange={(e) =>
                            setMessageFormData({
                              ...messageFormData,
                              content: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          className="fs-5 fw-bold w-100"
                          onClick={postMessage}
                        >
                          보내기
                        </Button>
                      </Col>
                    </Row>
                  )}

                  <Row
                    className="p-3 border rounded justify-content-center"
                    style={{backgroundColor: "#1D222A"}}
                  >
                    <Col className="mb-3">
                      <span className="fs-4 fw-bold">| 메세지 히스토리</span>
                    </Col>
                    <Row
                      style={{
                        height: "calc(100vh - 50rem)",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {selectedIssue?.messages &&
                        Object.entries(
                          selectedIssue?.messages.reduce((grouped, message) => {
                            const date = new Date(
                              message.created_at
                            ).toLocaleDateString();
                            if (!grouped[date]) grouped[date] = [];
                            grouped[date].push(message);
                            return grouped;
                          }, {})
                        ).map(([date, messages]) => (
                          <React.Fragment key={date}>
                            {/* 날짜 표시 */}
                            <Col
                              xs={12}
                              className="border-top border-bottom text-center my-2"
                            >
                              <span>{date}</span>
                            </Col>

                            {/* 메시지 리스트 */}
                            {messages.map((message) => (
                              <Col xs={12} key={message.id} className="my-2">
                                {message.sender_info.name ===
                                selectedIssue.center_info.manager.name ? (
                                  <Row>
                                    {/* 담당자 */}
                                    <Col xs={6}>
                                      <span>{message.sender_info.name}</span>
                                      <Form.Control
                                        as="textarea"
                                        className="w-75"
                                        value={message.content}
                                        readOnly
                                      />
                                      <span className="text-secondary">
                                        {new Date(
                                          message.created_at
                                        ).toLocaleTimeString("ko-KR", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </span>
                                    </Col>
                                    <Col xs={6}></Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col xs={6}></Col>
                                    {/* 발신자 */}
                                    <Col xs={6} className="text-end">
                                      <span>{message.sender_info.name}</span>
                                      <Form.Control
                                        as="textarea"
                                        className="w-75 ms-auto"
                                        value={message.content}
                                        readOnly
                                      />
                                      <span className="text-secondary">
                                        {new Date(
                                          message.created_at
                                        ).toLocaleTimeString("ko-KR", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                <Col xs={6}></Col>
                              </Col>
                            ))}
                          </React.Fragment>
                        ))}
                      {/* <Col
                        xs={12}
                        className="border-top border-bottom text-center"
                      >
                        <span>수/발신 시간</span>
                      </Col> */}

                      {/* <Col>
                        <Col>
                          <Row>
                            <Col xs={6} className="mt-2">
                              <span>담당자 ID</span>
                              <Form.Control className="w-75" as="textarea" />
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="mt-2">
                              <span>담당자 ID</span>
                              <Form.Control className="w-75" as="textarea" />
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                        </Col>

                        <Col>
                          <Row>
                            <Col xs={6}></Col>
                            <Col xs={6} className="text-end mt-2">
                              <span>발신자 ID</span>
                              <Form.Control
                                className="w-75 ms-auto"
                                as="textarea"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}></Col>
                            <Col xs={6} className="text-end mt-2">
                              <span>발신자 ID</span>
                              <Form.Control
                                className="w-75 ms-auto"
                                as="textarea"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Col> */}
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Form className="p-0" onSubmit={handleSearch}>
          <Col
            xs={12}
            className="p-3 border rounded"
            style={{backgroundColor: "#1D222A"}}
          >
            <Row className="justify-content-between mb-2">
              <Col xs={3}>
                <span className="fs-4 fw-bold">| 이슈 사항 필터 검색</span>
              </Col>
              <Col xs={1} className="text-end">
                <Button
                  variant="secondary"
                  className="w-100 h-100 fs-5 fw-bold"
                  onClick={() =>
                    setSearchParam({
                      year: oneWeekAgo.getFullYear(),
                      month: oneWeekAgo.getMonth() + 1,
                      day: oneWeekAgo.getDate(),
                      year2: today.getFullYear(),
                      month2: today.getMonth() + 1,
                      day2: today.getDate(),
                      local: "",
                      center: "",
                      keyword: "",
                      search: "",
                      risk_level: "",
                      category: "",
                      is_resolved: "",
                    })
                  }
                >
                  초기화
                </Button>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={7} className="d-flex gap-2">
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  발생 일자
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.year}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        year: e.target.value,
                      })
                    }
                  >
                    <option value={""}>년 리스트</option>
                    <option value={2024}>2024년</option>
                    <option value={2025}>2025년</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.month}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        month: e.target.value,
                      })
                    }
                  >
                    {Array.from({length: 12}, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.day}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        day: e.target.value,
                      })
                    }
                  >
                    <option value={""}>일 리스트</option>
                    {daysInMonth.map((day) => (
                      <option key={day} value={day}>
                        {day}일
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col
                  className="border rounded text-center p-2 my-auto align-middle"
                  style={{maxWidth: "2rem"}}
                >
                  ~
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.year2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        year2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>년 리스트</option>
                    <option value={2024}>2024년</option>
                    <option value={2025}>2025년</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.month2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        month2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>월 리스트</option>
                    {Array.from({length: 12}, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.day2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        day2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>일 리스트</option>
                    {daysInMonth.map((day) => (
                      <option key={day} value={day}>
                        {day}일
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Col>

              <Col xs={4}>
                <Row className="row-gap-2">
                  <Col xs={3} className="border rounded text-center p-2 fs-5">
                    카테고리
                  </Col>
                  <Col xs={8}>
                    <Form.Select
                      className="h-100 fs-5"
                      value={searchParam.category}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          category: e.target.value,
                        })
                      }
                    >
                      <option value={""}>카테고리 리스트</option>
                      {issueCategory &&
                        issueCategory.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className=" mb-2">
              <Col xs={7} className="d-flex gap-2">
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  경로당 구분
                </Col>
                <Col xs={2} className="pe-0">
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.local}
                    onChange={(e) =>
                      setSearchParam({...searchParam, local: e.target.value})
                    }
                  >
                    <option value={""}>구</option>
                    {districtList &&
                      districtList.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.center}
                    onChange={handleCenterChange}
                  >
                    <option value={""}>경로당</option>
                    {centerList &&
                      centerList.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  경로당명
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.keyword}
                    onChange={handleKeywordChange}
                    disabled={searchParam.center !== ""}
                  />
                </Col>
              </Col>

              <Col xs={4}>
                <Row className="row-gap-2">
                  <Col xs={3} className="border rounded text-center p-2 fs-5">
                    검색어
                  </Col>
                  <Col xs={6}>
                    <Form.Control
                      className="h-100 fs-5"
                      type="text"
                      placeholder="아이디 또는 이름"
                      value={searchParam.search}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          search: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="">
              <Col xs={7} className="d-flex gap-2">
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  위험도
                </Col>
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.risk_level}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        risk_level: e.target.value,
                      })
                    }
                  >
                    <option value={""}>위험도 선택</option>
                    {riskCategory &&
                      riskCategory.map((risk) => (
                        <option key={risk.id} value={risk.code}>
                          {risk.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Col>

              <Col xs={4}>
                <Row className="row-gap-2">
                  <Col xs={3} className="border rounded text-center p-2 fs-5">
                    처리 상태
                  </Col>
                  <Col xs={8}>
                    <Form.Select
                      className="h-100 fs-5"
                      value={searchParam.is_resolved}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          is_resolved: e.target.value,
                        })
                      }
                    >
                      <option value={""}>카테고리 리스트</option>
                      <option value={true}>처리 완료</option>
                      <option value={false}>미처리</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>

              <Col xs={1} className="">
                <Button
                  className="w-100 h-100 fs-5 fw-bold"
                  onClick={handleSearch}
                >
                  검색
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 30rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">| 이슈 사항 발생 내역</span>
            </Col>
            <Col xs={12} className="">
              {tableData ? (
                <CustomTable
                  columns={columns}
                  tableData={tableData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({onConfirm: () => alert("수정 완료")})}
      </CustomModal>
    </>
  );
};

export default IssueManagement;
