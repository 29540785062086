import React from "react";
import { useState } from "react";
import {
  getCentersLength,
  getHealthCareDeviceOnOff,
  getLifeCareDeviceOnOff,
  getStatisticsDashboard,
} from "../../api/apiClient";
import { useEffect } from "react";

const OnOffState = ({ type }) => {
  const [centerLegth, setCenterLength] = useState("-");
  const [onOffData, setOnOffData] = useState();
  const [heathCareOnOff, setHealthCareOnOff] = useState();
  const [lifeCareOnOff, setLifeCareOnOff] = useState();

  const getCenterData = () => {
    getCentersLength()
      .then((res) => {
        // console.log("getCentersLength", res.data);
        setCenterLength(res.data.length);
      })
      .catch((err) => console.error(err));
  };

  const getOnOffData = () => {
    getStatisticsDashboard()
      .then((res) => {
        console.log("getStatisticsDashboard : ", res.data);
        setOnOffData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getHealthCareOnOffData = () => {
    getHealthCareDeviceOnOff()
      .then((res) => {
        console.log("getHealthCareDeviceOnOff", res.data);
        setHealthCareOnOff(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getLifeCareOnOffData = () => {
    getLifeCareDeviceOnOff()
      .then((res) => {
        console.log("getLifeCareDeviceOnOff", res.data);
        setLifeCareOnOff(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getCenterData();
    getOnOffData();
    getHealthCareOnOffData();
    getLifeCareOnOffData();
  }, []);

  console.log("onoff", lifeCareOnOff);

  return (
    <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
      <div className={"my-auto align-middle"}>
        <p className="fs-4 fw-bold">| 전체 경로당 수 : {centerLegth}</p>
      </div>
      {/* on/off */}
      {type === "settop" && (
        <div
          className="d-flex justify-content-start align-items-center gap-3 ps-3"
          style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
        >
          <div>
            <span className="fs-4 fw-bold">셋톱박스</span>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
            <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
              ON
            </p>
            <p className="fs-4 fw-bold">{onOffData?.settopbox_count?.on}개</p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
            <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
              OFF
            </p>
            <p className="fs-4 fw-bold">{onOffData?.settopbox_count?.off}개</p>
          </div>
        </div>
      )}

      {type === "healthcare" && (
        <div className="d-flex justify-content-start align-items-center gap-3">
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            {/* 혈압 */}
            <div>
              <span className="fs-4 fw-bold">혈압</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.blood_pressure?.on}개
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.blood_pressure?.off}개
              </p>
            </div>
          </div>
          {/* 체온 */}
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            <div>
              <span className="fs-4 fw-bold">체온</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.body_temperature?.on}개
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.body_temperature?.off}개
              </p>
            </div>
          </div>
          {/* 체중 */}
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            <div>
              <span className="fs-4 fw-bold">체중</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.body_weight?.on}개
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.body_weight?.off}개
              </p>
            </div>
          </div>
          {/* 심전도 */}
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            <div>
              <span className="fs-4 fw-bold">심전도</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.electrocardiogram?.on}개
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {heathCareOnOff?.electrocardiogram?.off}개
              </p>
            </div>
          </div>
        </div>
      )}

      {type === "lifecare" && (
        <div className="d-flex justify-content-start align-items-center gap-3">
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            <div>
              <span className="fs-4 fw-bold">워킹머신</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">
                {lifeCareOnOff?.walking_machine?.on}개
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {lifeCareOnOff?.walking_machine?.off}개
              </p>
            </div>
          </div>
          <div
            className="d-flex justify-content-start align-items-center gap-3 ps-3"
            style={{ borderLeft: "2px solid rgba(134,134,134,0.5)" }}
          >
            <div>
              <span className="fs-4 fw-bold">스마트테이블</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#B2FF00" }}>
                ON
              </p>
              <p className="fs-4 fw-bold">{lifeCareOnOff?.smart_table?.on}개</p>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2 border rounded text-center p-2 fs-5">
              <p className="fs-4 fw-bold" style={{ color: "#FF1500" }}>
                OFF
              </p>
              <p className="fs-4 fw-bold">
                {lifeCareOnOff?.smart_table?.off}개
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnOffState;
