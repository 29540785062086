import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BasicBarChart from "../chart/BasicBarChart";
import BasicColumnChart from "../chart/BasicColumnChart";
import LineChart from "../chart/LineChart";
import PieChart from "../chart/PieChart";
import Bg01 from "../../assets/monitoring/bg01.png";
import Bg02 from "../../assets/monitoring/bg02.png";
import Bg03 from "../../assets/monitoring/bg03.png";
import Bg04 from "../../assets/monitoring/bg04.png";
import Bg05 from "../../assets/monitoring/bg05.png";
import Bg06 from "../../assets/monitoring/bg06.png";
import { getStatisticsDashboard } from "../../api/apiClient";

const Dashboard = () => {
  const barChartData = {
    title: "시스템별 이용 현황",
    xAxisCategories: ["생활케어 이용", "헬스케어 측정", "양방향 회의"],
    series: [
      {
        type: "bar",
        name: "Data",
        data: [
          { y: 63, color: "#2ECF8F" }, // 생활케어 이용
          { y: 48, color: "#FB72F4" }, // 헬스케어 측정
          { y: 22, color: "#FF8C18" }, // 양방향 회의
        ],
      },
    ],
    yAxisTitle: "Values",
    backgroundImage: Bg01,
    height: "50%",
  };

  const lineChartData = {
    title: "이슈 사항 주간 발생 현황",
    xAxisCategories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    series: [
      {
        type: "line",
        name: "이벤트 주간 발생 현황",
        data: [3, 5, 7, 2, 10, 6, 4],
      },
    ],
    yAxisTitle: "Counts",
    backgroundImage: Bg02,
    height: "50%",
  };

  const pieChartsData = [
    {
      title: "경로당 셋톱박스 on/off 현황",
      series: [
        {
          type: "pie",
          name: "Share",
          data: [
            {
              name: "ON",
              y: 61.41,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                stops: [
                  [0, "#3FB5EC"], // 시작 색
                  [1, "#9E54F3"], // 끝 색
                ],
              },
            },
            {
              name: "OFF",
              y: 20.84,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                stops: [
                  [0, "#F68D23"], // 아래쪽 시작 색
                  [0.5, "#FD6849"], // 중간 색
                  [1, "#F85E79"], // 위쪽 끝 색
                ],
              },
            },
          ],
        },
      ],
      centerText1: "중앙 텍스트 1",
      centerText2: "중앙 텍스트 2",
      backgroundImage: Bg03,
    },
    {
      title: "헬스케어 기기 on/off 현황",
      series: [
        {
          type: "pie",
          name: "Share",
          data: [
            {
              name: "ON",
              y: 70.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                stops: [
                  [0, "#3FB5EC"], // 시작 색
                  [1, "#9E54F3"], // 끝 색
                ],
              },
            },
            {
              name: "OFF",
              y: 20.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                stops: [
                  [0, "#F68D23"], // 아래쪽 시작 색
                  [0.5, "#FD6849"], // 중간 색
                  [1, "#F85E79"], // 위쪽 끝 색
                ],
              },
            },
          ],
        },
      ],
      centerText1: "중앙 텍스트 1",
      centerText2: "중앙 텍스트 2",
      backgroundImage: Bg04,
    },
    {
      title: "생활케어 기기 on/off 현황",
      series: [
        {
          type: "pie",
          name: "Share",
          data: [
            {
              name: "ON",
              y: 72.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                stops: [
                  [0, "#3FB5EC"], // 시작 색
                  [1, "#9E54F3"], // 끝 색
                ],
              },
            },
            {
              name: "OFF",
              y: 27.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                stops: [
                  [0, "#F68D23"], // 아래쪽 시작 색
                  [0.5, "#FD6849"], // 중간 색
                  [1, "#F85E79"], // 위쪽 끝 색
                ],
              },
            },
          ],
        },
      ],
      centerText1: "중앙 텍스트 1",
      centerText2: "중앙 텍스트 2",
      backgroundImage: Bg05,
    },
    {
      title: "헬프데스크 처리 현황",
      series: [
        {
          type: "pie",
          name: "Distribution",
          data: [
            {
              name: "ON",
              y: 40.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                stops: [
                  [0, "#3FB5EC"], // 시작 색
                  [1, "#9E54F3"], // 끝 색
                ],
              },
            },
            {
              name: "OFF",
              y: 30.0,
              color: {
                linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                stops: [
                  [0, "#F68D23"], // 아래쪽 시작 색
                  [0.5, "#FD6849"], // 중간 색
                  [1, "#F85E79"], // 위쪽 끝 색
                ],
              },
            },
          ],
        },
      ],
      centerText1: "중앙 텍스트 1",
      centerText2: "중앙 텍스트 2",
      backgroundImage: Bg06,
    },
  ];

  const [chartData, setChartData] = useState({});

  const [systemChartData, setSystemChartData] = useState({
    title: "시스템별 이용 현황",
    xAxisCategories: ["생활케어 이용", "헬스케어 측정", "양방향 회의"],
    series: [
      {
        type: "bar",
        name: "Data",
        data: [
          { y: 0, color: "#2ECF8F" }, // 생활케어 이용
          { y: 0, color: "#FB72F4" }, // 헬스케어 측정
          { y: 0, color: "#FF8C18" }, // 양방향 회의
        ],
      },
    ],
    yAxisTitle: "Values",
    backgroundImage: Bg01,
    height: "50%",
  });
  const [issueChartData, setIssueChartData] = useState({
    title: "이슈사항 주간 발생 현황",
    xAxisCategories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    series: [
      {
        type: "line",
        name: "이벤트 주간 발생 현황",
        // data: [3, 5, 7, 2, 10, 6, 4],
      },
    ],
    yAxisTitle: "Counts",
    backgroundImage: Bg02,
    height: "50%",
  });
  const [setTopBoxData, setSetTopBoxData] = useState({});
  const [healthcareDeviceData, setHealthcareDeviceData] = useState({});
  const [lifecareDeviceData, setLifecareDeviceData] = useState({});
  const [helpdeskData, setHelpdeskData] = useState({});

  const getDashboardData = () => {
    getStatisticsDashboard()
      .then((res) => {
        console.log("getStatisticsDashboard res : ", res.data);
        setSystemChartData({
          title: "시스템별 이용 현황",
          xAxisCategories: ["생활케어 이용", "헬스케어 측정", "양방향 회의"],
          series: [
            {
              type: "bar",
              name: "Data",
              data: [
                { y: res.data.system_usage_count.lifecare, color: "#2ECF8F" }, // 생활케어 이용
                { y: res.data.system_usage_count.healthcare, color: "#FB72F4" }, // 헬스케어 측정
                { y: res.data.system_usage_count.tvs, color: "#FF8C18" }, // 양방향 회의
              ],
            },
          ],
          yAxisTitle: "Values",
          backgroundImage: Bg01,
          height: "50%",
        });

        const issueDates = Object.keys(res.data.issues).sort();
        const issueCounts = issueDates.map(
          (date) => res.data.issues[date].issue_count
        );
        setIssueChartData({
          title: "이슈사항 주간 발생 현황",
          xAxisCategories: issueDates,
          series: [
            {
              type: "line",
              name: "이벤트 주간 발생 현황",
              data: issueCounts,
            },
          ],
          yAxisTitle: "Counts",
          backgroundImage: Bg02,
          height: "50%",
        });

        setSetTopBoxData({
          title: "경로당 셋톱박스 on/off 현황",
          series: [
            {
              type: "pie",
              name: "Share",
              data: [
                {
                  name: "ON",
                  y: res.data.settopbox_count.on,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                    stops: [
                      [0, "#3FB5EC"], // 시작 색
                      [1, "#9E54F3"], // 끝 색
                    ],
                  },
                },
                {
                  name: "OFF",
                  y: res.data.settopbox_count.off,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                    stops: [
                      [0, "#F68D23"], // 아래쪽 시작 색
                      [0.5, "#FD6849"], // 중간 색
                      [1, "#F85E79"], // 위쪽 끝 색
                    ],
                  },
                },
              ],
            },
          ],
          centerText1: `${res.data.settopbox_count.on}개`,
          centerText2: "ON",
          backgroundImage: Bg03,
        });
        setHealthcareDeviceData({
          title: "헬스케어 기기 on/off 현황",
          series: [
            {
              type: "pie",
              name: "Share",
              data: [
                {
                  name: "ON",
                  y: res.data.healthcare_device.on,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                    stops: [
                      [0, "#3FB5EC"], // 시작 색
                      [1, "#9E54F3"], // 끝 색
                    ],
                  },
                },
                {
                  name: "OFF",
                  y: res.data.healthcare_device.off,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                    stops: [
                      [0, "#F68D23"], // 아래쪽 시작 색
                      [0.5, "#FD6849"], // 중간 색
                      [1, "#F85E79"], // 위쪽 끝 색
                    ],
                  },
                },
              ],
            },
          ],
          centerText1: `${res.data.healthcare_device.on}개`,
          centerText2: "ON",
          backgroundImage: Bg04,
        });
        setLifecareDeviceData({
          title: "생활케어 기기 on/off 현황",
          series: [
            {
              type: "pie",
              name: "Share",
              data: [
                {
                  name: "ON",
                  y: res.data.lifecare_device.on,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                    stops: [
                      [0, "#3FB5EC"], // 시작 색
                      [1, "#9E54F3"], // 끝 색
                    ],
                  },
                },
                {
                  name: "OFF",
                  y: res.data.lifecare_device.off,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                    stops: [
                      [0, "#F68D23"], // 아래쪽 시작 색
                      [0.5, "#FD6849"], // 중간 색
                      [1, "#F85E79"], // 위쪽 끝 색
                    ],
                  },
                },
              ],
            },
          ],
          centerText1: `${res.data.lifecare_device.on}개`,
          centerText2: "ON",
          backgroundImage: Bg05,
        });
        setHelpdeskData({
          title: "헬프데스크 처리 현황",
          series: [
            {
              type: "pie",
              name: "Distribution",
              data: [
                {
                  name: "ON",
                  y: res.data.helpdesk.resolved,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 0, y2: 0.8 }, // 좌->우 그라데이션
                    stops: [
                      [0, "#3FB5EC"], // 시작 색
                      [1, "#9E54F3"], // 끝 색
                    ],
                  },
                },
                {
                  name: "OFF",
                  y: res.data.helpdesk.unresolved,
                  color: {
                    linearGradient: { x1: 0, x2: 0.75, y1: 1, y2: 0 }, // 아래에서 위로 대각선
                    stops: [
                      [0, "#F68D23"], // 아래쪽 시작 색
                      [0.5, "#FD6849"], // 중간 색
                      [1, "#F85E79"], // 위쪽 끝 색
                    ],
                  },
                },
              ],
            },
          ],
          centerText1: `${res.data.helpdesk.unresolved}건`,
          centerText2: "진행중",
          backgroundImage: Bg06,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <Row className={"row-gap-4 p-0"}>
      <Row>
        <Col xs={6}>
          {/* 시스템별 이용 현황 */}
          <BasicBarChart {...systemChartData} />
        </Col>
        <Col xs={6}>
          {/* 이슈사항 주간 발생 현황 */}
          <LineChart {...issueChartData} />
        </Col>
      </Row>
      <Row>
        {/*{pieChartsData.map((data, index) => (
              <Col key={index} xs={3}>
                <PieChart className={"border"} {...data} />
              </Col>
          ))}*/}
        <Col xs={3}>
          <PieChart className={"border"} {...setTopBoxData} />
        </Col>
        <Col xs={3}>
          <PieChart className={"border"} {...healthcareDeviceData} />
        </Col>
        <Col xs={3}>
          <PieChart className={"border"} {...lifecareDeviceData} />
        </Col>
        <Col xs={3}>
          <PieChart className={"border"} {...helpdeskData} />
        </Col>
      </Row>
    </Row>
  );
};

export default Dashboard;
