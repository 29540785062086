import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Landing from "./pages/Landing";
import Monitoring from "./pages/Monitoring";
import NotFoundPage from "./pages/NotFoundPage";
import VideoConference from "./pages/VideoConference";
import Healthcare from "./pages/Healthcare";
import Lifestyle from "./pages/Lifestyle";
import Issue from "./pages/Issue";
import Notice from "./pages/Notice";
import User from "./pages/User";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import MessageSend from "./pages/MessageSend";
import ProgramSchedule from "./components/VideoConference/ProgramSchedule";
import { useEffect } from "react";
import { postTokenVerify } from "./api/apiClient";
import MessageRegister from "./pages/MessageRegister";
import ErrorPage from "./pages/ErrorPage";
import ClientMonitoring from "./components/VideoConference/ClientMonitoring";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* 로그인 (메인) */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* 메시지 회원가입 */}
        <Route path="/message/register" element={<MessageRegister />} />
        {/* 메세지 보내기 */}
        <Route path="/send" element={<MessageSend />} />

        {/* 플랫폼*/}
        {/* 랜딩 페이지 */}
        <Route path="/landing" element={<Landing />} />

        {/* 실시간 모니터링 */}
        <Route path="/monitoring" element={<Monitoring />} />

        {/* 양방향 화상 시스템 운영 관리 */}
        <Route path="/video-conference" element={<VideoConference />} />
        {/*양방향 화상 시스템 운영 관리 - 편성표 관리 */}
        {/*<Route path="/video-conference/schedule" element={<ProgramSchedule />} />*/}

        {/* 헬스케어 시스템 운영 관리 */}
        <Route path="/healthcare" element={<Healthcare />} />

        {/* 생활케어 시스템 운영 관리 */}
        <Route path="/lifestyle" element={<Lifestyle />} />

        {/* 이슈사항 관리 */}
        <Route path="/issue" element={<Issue />} />

        {/* 공지사항 관리 */}
        <Route path="/notice" element={<Notice />} />

        {/* 사용자 관리 */}
        <Route path="/user" element={<User />} />

        {/* 설정 관리 */}
        <Route path="/settings" element={<Settings />} />

        {/* 점검 페이지 */}
        <Route path="/error" element={<ErrorPage />} />

        {/* 404 Not Found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
