import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  getDistrict,
  getSeniorCenter,
  getSmartTableContent,
  getSmartTableRanking,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import CenterSearch from "../common/search/CenterSearch";

const SmartTableContent = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
    content: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [contentList, setContentList] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getContentList = () => {
    getSmartTableContent()
      .then((res) => {
        console.log("getContentList", res.data);
        setContentList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getContentList();
    getDistrictList();
    getAccountsCenterList();
    getRanking();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [totalRanking, setTotalRanking] = useState([]);
  const [scoreRanking, setScoreRanking] = useState([]);
  const [usageRanking, setUsageRanking] = useState([]);
  const [overallRanking, setOverallRanking] = useState([]);

  const getRanking = () => {
    const startDate = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endDate = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getSmartTableRanking(
      searchParam.content,
      startDate,
      endDate,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getRanking", res.data);
        setTotalRanking(res.data.total_ranking);
        setScoreRanking(res.data.score_ranking);
        setUsageRanking(res.data.usage_ranking);
        setOverallRanking(res.data.overall_ranking);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="row-gap-3">
          <Col xs={12} className="d-flex w-100 justify-content-between gap-3">
            <Row className="w-100">
              <Col xs={4} className="my-auto pe-0">
                <span className="fs-4 fw-bold w-50">| 콘텐츠 검색</span>
              </Col>
              <Col className="w-100">
                <Form.Select
                  className="h-100 fs-5"
                  aria-label="Default select example"
                  value={searchParam.content}
                  onChange={(e) =>
                    setSearchParam({ ...searchParam, content: e.target.value })
                  }
                >
                  <option value={""}>콘텐츠 선택</option>
                  {contentList &&
                    contentList.map((content, idx) => (
                      <option key={idx} value={content.id}>
                        {content.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
            <DateSearch
              title={"기간 설정"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />
          </Col>

          <CenterSearch
            title={"경로당 검색 필터"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            districtList={districtList}
            centerList={centerList}
            handleKeywordChange={handleKeywordChange}
            handleCenterChange={handleCenterChange}
            onSearch={() => {
              getRanking();
            }}
          />
        </Row>
      </Col>

      {/* 내용 */}
      <Col
        xs={12}
        className=""
        style={{
          height: "calc(100vh - 22rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row className="d-flex gap-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 종합 순위</span>
              </Col>
              {totalRanking?.map((rank, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{rank.rank}위</Col>
                    <Col>{rank.district}</Col>
                    <Col>{rank.center_name}</Col>
                    <Col>{rank.user_id}</Col>
                    <Col>{rank.overall_score}</Col>
                  </Row>
                </Col>
              ))}

              {totalRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 점수 순위</span>
              </Col>
              {scoreRanking?.map((rank, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{rank.rank}위</Col>
                    <Col>{rank.district}</Col>
                    <Col>{rank.center_name}</Col>
                    <Col>{rank.user_id}</Col>
                    <Col>{rank.score}</Col>
                  </Row>
                </Col>
              ))}

              {scoreRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row className="d-flex gap-4 mt-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 이용횟수 순위</span>
              </Col>

              {usageRanking?.map((rank, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{rank.rank}위</Col>
                    <Col>{rank.district}</Col>
                    <Col>{rank.center_name}</Col>
                    <Col>{rank.user_id}</Col>
                    <Col>{rank.usage_count}</Col>
                  </Row>
                </Col>
              ))}

              {usageRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>

          {/* 빈공간 */}
          <Col></Col>
        </Row>

        <Row
          className="p-3 border rounded mt-4"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12} className={"mb-3"}>
            <span className="fs-4 fw-bold">| 전체 랭킹</span>
          </Col>
          <Col xs={12}>
            <Row>
              <Col>
                <Row
                  className={"text-center py-3 border-top border-bottom fs-5"}
                  style={{ backgroundColor: "#353C47" }}
                >
                  <Col>순위</Col>
                  <Col>구</Col>
                  <Col>경로당</Col>
                  <Col>ID</Col>
                  <Col>콘텐츠명</Col>
                  <Col>점수</Col>
                  <Col>이용 횟수</Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {overallRanking?.map((rank, idx) => (
            <Col xs={12}>
              <Row
                key={idx}
                className={"text-center py-3 border-top border-bottom fs-5"}
                style={{ backgroundColor: "" }}
              >
                <Col>{rank.rank}위</Col>
                <Col>{rank.district}</Col>
                <Col>{rank.center_name}</Col>
                <Col>{rank.user_id}</Col>
                <Col></Col>
                <Col>{rank.overall_score}</Col>
                <Col>{rank.usage_count}</Col>
              </Row>
            </Col>
          ))}

          {overallRanking.length === 0 && (
            <Col xs={12}>
              <Row
                className={"text-center py-3 border-top border-bottom fs-5"}
                style={{ backgroundColor: "" }}
              >
                <Col>데이터가 없습니다.</Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default SmartTableContent;
