import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";
import LineChart from "../chart/LineChart";
import PieChart from "../chart/PieChart";
import Map from "../../assets/common/map.png";
// import Map from "../../assets/test/sample-map.png";
import {
  getDistrict,
  getLifeCareStatistics,
  getLifeCareTotal,
  getSeniorCenter,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import CenterSearch from "../common/search/CenterSearch";
import usePrintPDF from "../../hooks/usePrintPDF";
import StockChart from "../chart/StockChart";
import MapVisualization from "../chart/MapVisualization";
import MapVisualizationLife from "../chart/MapVisualizationLife";

const LifeStyleUsage = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        // console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const [competition, setCompetition] = useState([]);
  const [smartTable, setSmartTable] = useState([]);
  const [totalUsage, setTotalUsage] = useState({});
  const [maleStats, setMaleStats] = useState({});
  const [femaleStats, setfemaleStats] = useState({});
  const [lifeCareTotal, setLifeCareTotal] = useState({});

  const transformData = (data, key) => {
    console.log("data:", data, "key:", key); // 데이터 확인

    // key가 없거나 해당 데이터가 배열이 아닐 경우 빈 배열 반환
    if (!data || !data[key] || !Array.isArray(data[key])) {
      console.error(`Error: ${key} is not an array`, data);
      return [];
    }

    // 선택한 키의 배열을 변환
    return data[key].map((item) => {
      const timestamp = new Date(item.date).getTime(); // 날짜를 타임스탬프로 변환
      return [timestamp, item.total_duration || 0];
    });
  };

  // 헬스케어 시스템 이용량
  const getLifeCareStatisticsList = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getLifeCareStatistics(
      startData,
      endData,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getLifeCareStatistics : ", res.data);

        // 날짜별 이용량 추이 변환
        const transformedCompetition = transformData(
          res.data?.daily_usage,
          "walking_machine"
        );

        const transformedSmartTable = transformData(
          res.data?.daily_usage,
          "smart_table"
        );

        setCompetition(transformedCompetition);
        setSmartTable(transformedSmartTable);

        // 기기별 이용시간
        setTotalUsage({
          xAxisCategories: ["워킹머신", "스마트테이블"],
          series: [
            {
              type: "column",
              name: "기기구분",
              data: [
                {
                  y: res.data.total_usage.walking_machine.total_duration || 0,
                  color: "#2ECF8F",
                },
                {
                  y: res.data.total_usage.smart_table.total_duration || 0,
                  color: "#FB72F4",
                },
              ],
            },
          ],
        });

        // 측정 횟수 남여별 현황
        setMaleStats({
          title: "남성",
          series: [
            {
              type: "pie",
              name: "측정횟수",
              data: [
                {
                  name: "워킹머신",
                  y:
                    res.data?.gender_usage.male.walking_machine
                      .total_duration || 0,
                  color: "#2ECF8F",
                },
                {
                  name: "스마트테이블",
                  y:
                    res.data?.gender_usage.male.smart_table.total_duration || 0,
                  color: "#FB72F4",
                },
              ],
            },
          ],
          innerSize: "0%",
        });

        setfemaleStats({
          title: "여성",
          series: [
            {
              type: "pie",
              name: "측정횟수",
              data: [
                {
                  name: "워킹머신",
                  y:
                    res.data?.gender_usage.female.walking_machine
                      .total_duration || 0,
                  color: "#2ECF8F",
                },
                {
                  name: "스마트테이블",
                  y:
                    res.data?.gender_usage.female.smart_table.total_duration ||
                    0,
                  color: "#FB72F4",
                },
              ],
            },
          ],
          innerSize: "0%",
        });
      })
      .catch((err) => console.error(err));
  };

  // 이용량(측정 횟수 현황)
  const getLifeCareTotalList = () => {
    const startDate = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endDate = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getLifeCareTotal(
      startDate,
      endDate,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getLifeCareTotal : ", res.data);
        setLifeCareTotal(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 차트 설정
  const getChartData = (competition, smartTable) => {
    return {
      tooltip: {
        shared: true, // 여러 시리즈 데이터 공유
        formatter: function () {
          // this.points는 여러 시리즈 데이터에 접근 가능
          return this.points
            .map(
              (point) =>
                `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <strong>${point.y}</strong>`
            )
            .join("<br/>"); // 시리즈별 데이터를 줄바꿈으로 연결
        },
      },
      series: [
        {
          name: "워킹머신",
          data: competition,
          color: "#2ECF8F",
          lineWidth: 4,
        },
        {
          name: "스마트테이블",
          data: smartTable,
          color: "#FB72F4",
          lineWidth: 4,
        },
      ],
    };
  };

  // 날짜별 이용량 추이 데이터
  const [chartData, setChartData] = useState({ series: [] });

  useEffect(() => {
    const series = getChartData(competition, smartTable);

    setChartData(series);
  }, [competition, smartTable]);

  // console.log("ccc", chartData);

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getLifeCareStatisticsList();
    getLifeCareTotalList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const handleSearch = (e) => {
    e.preventDefault();
    getLifeCareStatisticsList();
    getLifeCareTotalList();
  };

  /* PDF 출력 기능 */
  const { pdfRef, handlePrintPDF } = usePrintPDF();

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="d-flex p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="row-gap-3">
          <DateSearch
            title={"검색 일자"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            daysInMonth={daysInMonth}
          />
          <Row className="justify-content-between">
            <CenterSearch
              title={"경로당 필터 검색"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              districtList={districtList}
              centerList={centerList}
              handleKeywordChange={handleKeywordChange}
              handleCenterChange={handleCenterChange}
              onSearch={handleSearch}
            />
            <Col xs={2} className="text-end pe-0">
              <Button
                className="mt-auto fw-bold fs-5 px-4 h-100"
                onClick={() => {
                  // pdfRef의 overflowY가 auto이므로 스크롤 제거하고 전체 페이지 출력

                  pdfRef.current.style.height = "auto";
                  handlePrintPDF();

                  // handlePrintPDF 동작이 끝나면 height: "calc(100vh - 23rem)" 처리
                  setTimeout(() => {
                    pdfRef.current.style.height = "calc(100vh - 19rem)";
                  }, 10000);
                }}
              >
                PDF
              </Button>
            </Col>
          </Row>
        </Row>
      </Col>

      {/* 테이블 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 19rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        ref={pdfRef}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">| 날짜별 이용시간 추이</span>
          </Col>
          <Col xs={12} className="">
            <StockChart
              title="Test Stock Chart"
              series={chartData?.series || []}
              tooltip={chartData?.tooltip}
              style={{ backgroundColor: "#1D222A" }}
            />
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 기기별 이용시간</span>
            </Col>
            <Col className="pe-0">
              <BasicColumnChart {...totalUsage} />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 이용 시간 남여별 현황
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <PieChart {...maleStats} />
              <PieChart {...femaleStats} />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 워킹머신 이용시간</span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualizationLife
                data={lifeCareTotal?.centers}
                type={"competition"}
              />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 스마트테이블 콘텐츠 이용시간
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualizationLife
                data={lifeCareTotal?.centers}
                type={"smart_table"}
              />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 스마트테이블 키오스크 체험 이용시간
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualizationLife
                data={lifeCareTotal?.centers}
                type={"total_usage"}
              />
            </Col>
          </Col>
        </Row>

        <Col
          className="border rounded mt-3 p-3"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col className="w-100">
            <span className="fs-4 fw-bold w-100">| 이용 시간 현황</span>
          </Col>
          <Col className="fs-4 mt-3 mb-1">
            {lifeCareTotal.period?.start_date} ~{" "}
            {lifeCareTotal.period?.end_date}
          </Col>
          <Col>
            <BootstrapTable striped hover responsive>
              <thead>
                <tr className={"text-center"}>
                  {[
                    "순번",
                    "구",
                    "경로당명",
                    "콘텐츠명",
                    "콘텐츠 이용 시간",
                    "콘텐츠명",
                    "콘텐츠 이용 시간",
                    "전체 이용 시간",
                    "기간",
                  ].map((col, idx) => (
                    <th key={idx}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {lifeCareTotal.centers &&
                  lifeCareTotal.centers?.map((data, idx) => (
                    <tr key={data?.center_id} className={"text-center"}>
                      <td>{data.id}</td>
                      <td>{data.district_name}</td>
                      <td>{data.center_name}</td>
                      <td>워킹머신</td>
                      <td>{data?.walking_machine?.total_duration}</td>
                      <td>스마트테이블</td>
                      <td>{data?.smart_table?.total_duration}</td>
                      <td>{data?.total_duration}</td>
                      <td>
                        {lifeCareTotal.period?.start_date} ~{" "}
                        {lifeCareTotal.period?.end_date}
                      </td>
                    </tr>
                  ))}

                {lifeCareTotal.centers &&
                  lifeCareTotal.centers.length === 0 && (
                    <tr>
                      <td colSpan={8} className="text-center">
                        해당 내역이 없습니다.
                      </td>
                    </tr>
                  )}
              </tbody>
            </BootstrapTable>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default LifeStyleUsage;
