import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import BasicBarChart from "../chart/BasicBarChart";
import BasicColumnChart from "../chart/BasicColumnChart";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import {
  getStatisticsCenterDetail,
  getStatisticsCenters,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";

const SeniorCenterUsageStatus = () => {
  const [showDetailUsage, setShowDetailUsage] = useState(false);
  const [showContentModal01, setShowContentModal01] = useState(false);
  const [showContentModal02, setShowContentModal02] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "district", title: "구" },
    { key: "center_name", title: "경로당명" },
    { key: "settopbox_available", title: "셋톱박스" },
    { key: "blood_pressure_device_available", title: "혈압" },
    { key: "body_temperature_device_available", title: "체온" },
    { key: "weight_device_available", title: "체중" },
    { key: "electrocardiogram_device_available", title: "심전도" },
    { key: "walking_machine_available", title: "워킹머신" },
    { key: "smart_table_available", title: "스마트테이블" },
    { key: "actions", title: "상세보기" },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getTableData();
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  const [detailInfo, setDetailInfo] = useState({});
  const [header, setHeader] = useState({});
  const [tvs_usage_main, setTvs_usage_main] = useState({});
  const [tvs_usage_etc, setTvs_usage_etc] = useState({});
  const [health_care_usage, setHealth_care_usage] = useState({});
  const [life_care_usage, setLife_care_usage] = useState({});
  const [issues_resolved, setIssues_resolved] = useState({});
  const [issues_unresolved, setIssues_unresolved] = useState({});
  const [helpdesk, setHelpdesk] = useState([]);
  const [all_helpdesk, setAll_helpdesk] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleDetail = (row) => {
    // console.log("handleDetail row : ", row);
    getStatisticsCenterDetail(row.id)
      .then((res) => {
        console.log("getStatisticsCenterDetail res : ", res.data);
        setShowDetailUsage(true);
        setDetailInfo(res.data);
        setHeader(res.data.header);

        /* 차트 데이터 */
        const dates = Object.keys(res.data.tvs_usage.live_streaming);
        const latestDate = dates.sort().pop(); // Get the latest date

        setTvs_usage_main({
          title: "양방향 화상 - 메인 기능",
          xAxisCategories: ["실시간 방송", "녹화 영상", "IPTV"],
          series: [
            {
              type: "bar",
              name: "Data",
              data: [
                res.data.tvs_usage.live_streaming[latestDate],
                res.data.tvs_usage.vod[latestDate],
                res.data.tvs_usage.iptv[latestDate],
              ],
            },
          ],
          yAxisTitle: "Values",
        });

        setTvs_usage_etc({
          title: "양방향 화상 - 기타 기능 이용현황",
          xAxisCategories: [
            "날씨 정보",
            "버스 정보",
            "외부 메시지",
            "경로당간 영상통화",
          ],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                res.data.tvs_usage.weather[latestDate],
                res.data.tvs_usage.bus[latestDate],
                res.data.tvs_usage.message[latestDate],
                res.data.tvs_usage.video_call[latestDate],
              ],
            },
          ],
          yAxisTitle: "Scores",
        });

        setHealth_care_usage({
          title: "헬스케어 이용 현황",
          xAxisCategories: [
            "혈압측정",
            "체온측정",
            "체중 (BMI) 측정",
            "심전도 측정",
          ],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                res.data.health_care_usage.blood_pressure[latestDate],
                res.data.health_care_usage.body_temperature[latestDate],
                res.data.health_care_usage.weight[latestDate],
                res.data.health_care_usage.electrocardiogram[latestDate],
              ],
            },
          ],
        });

        setLife_care_usage({
          title: "생활케어 이용 현황",
          xAxisCategories: ["워킹머신", "스마트테이블"],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                res.data.life_care_usage.walking_machine[latestDate],
                res.data.life_care_usage.smart_table[latestDate],
              ],
            },
          ],
        });

        setIssues_unresolved({
          title: "이슈 사항 발생 통계",
          xAxisCategories: ["장비 alive", "관리자 로그"],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                res.data.issues.unresolved.device_alive,
                res.data.issues.unresolved.admin,
              ],
            },
          ],
        });

        setIssues_resolved({
          title: "이슈 사항 처리 통계",
          xAxisCategories: ["장비 alive", "관리자 로그"],
          series: [
            {
              type: "column",
              name: "Scores",
              data: [
                res.data.issues.resolved.device_alive,
                res.data.issues.resolved.admin,
              ],
            },
          ],
        });

        setAll_helpdesk(res.data.all_helpdesk);
        // setHealth_care_usage(res.data.health_care_usage);
        setHelpdesk(res.data.helpdesk);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTableData = () => {
    setLoading(true);

    getStatisticsCenters(currentPage)
      .then((res) => {
        console.log("getStatisticsCenters res : ", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTableData();
  }, []);

  const renderEdit01 = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>구현 예정</Col>
        </Row>
      </>
    );
  };

  const renderEdit02 = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>구현 예정</Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        {/* 테이블 */}
        {!showDetailUsage && (
          <Row>
            <Col
              className="p-3 border rounded text-center"
              style={{
                backgroundColor: "#1D222A",
                height: "calc(100vh - 13rem)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {loading ? (
                <Col className="d-flex justify-content-center align-items-center h-100">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              ) : (
                <CustomTable
                  columns={columns}
                  tableData={tableData}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  handleDetail={handleDetail}
                />
              )}
            </Col>
          </Row>
        )}

        {/* 상세보기 내용 */}
        {showDetailUsage && (
          <Col xs={12}>
            <Col
              className="d-flex border rounded p-3 justify-content-center align-items-center text-center fs-5"
              style={{
                backgroundColor: "#1D222A",
              }}
            >
              <Col xs={2} className="border-end h-100">
                <p className="h-100">{header && header.district}</p>
              </Col>
              <Col xs={2} className="h-100">
                <p className="">{header && header.center_name}</p>
              </Col>
              <Col xs={1} className="border-start border-end">
                <p>셋톱박스</p>
                <p
                  className="fw-bold"
                  style={
                    header.settopbox_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.settopbox_available ? "ON" : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="border-end">
                <p>혈압</p>
                <p
                  className="fw-bold"
                  style={
                    header.blood_pressure_device_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.blood_pressure_device_available
                    ? "ON"
                    : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="border-end">
                <p>체온</p>
                <p
                  className="fw-bold"
                  style={
                    header.body_temperature_device_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.body_temperature_device_available
                    ? "ON"
                    : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="border-end">
                <p>체중</p>
                <p
                  className="fw-bold"
                  style={
                    header.weight_device_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.weight_device_available ? "ON" : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="border-end">
                <p>심전도</p>
                <p
                  className="fw-bold"
                  style={
                    header.electrocardiogram_device_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.electrocardiogram_device_available
                    ? "ON"
                    : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="border-end">
                <p>워킹머신</p>
                <p
                  className="fw-bold"
                  style={
                    header.walking_machine_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.walking_machine_available ? "ON" : "OFF"}
                </p>
              </Col>
              <Col xs={1} className="">
                <p>스마트테이블</p>
                <p
                  className="fw-bold"
                  style={
                    header.smart_table_available
                      ? { color: "#B2FF00" }
                      : { color: "#FF1500" }
                  }
                >
                  {header && header.smart_table_available ? "ON" : "OFF"}
                </p>
              </Col>
            </Col>
            <Col
              style={{
                marginTop: "1.5rem",
                height: "calc(100vh - 18.5rem)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {/* 차트 */}
              <Row className="row-gap-3">
                <Col xs={6} className="">
                  {/* 양방향 화상 -메인 기능 */}
                  <BasicBarChart {...tvs_usage_main} />
                </Col>
                <Col xs={6} className="">
                  {/* 양방향 화상 - 기타 기능 이용 현황 */}
                  <BasicColumnChart {...tvs_usage_etc} />
                </Col>

                <Col xs={6} className="">
                  {/* 헬스케어 이용 현황 */}
                  <BasicColumnChart {...health_care_usage} />
                </Col>
                <Col xs={6} className="">
                  {/* 생활케어 이용 현황 */}
                  <BasicColumnChart {...life_care_usage} />
                </Col>

                <Col xs={6} className="">
                  {/* 이슈 사항 발생 통계 */}
                  <BasicColumnChart {...issues_unresolved} />
                </Col>
                <Col xs={6} className="">
                  {/* 이슈 사항 처리 통계 */}
                  <BasicColumnChart {...issues_resolved} />
                </Col>
              </Row>

              {/* 접수 현황 */}
              <Col
                className="p-3 border rounded mt-3"
                style={{ backgroundColor: "#1D222A" }}
              >
                <Row className="my-3">
                  <Col xs={2} className="d-flex align-items-center">
                    <span className="border-end pe-3 me-3 fs-5">
                      헬프데스크 접수 현황
                    </span>
                    <span>1</span>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <span className="border-end pe-3 me-3 fs-5">잔여 현황</span>
                    <span>1</span>
                  </Col>
                </Row>

                <Col>
                  <span>페이지네이션</span>
                  {/* 팝업 버튼 */}
                  <Button
                    className={
                      "rounded-5 px-5 fw-bold fs-5 btn-outline-primary"
                    }
                    style={{ borderColor: "#fff", color: "#fff" }}
                    onClick={() => setShowContentModal01(true)}
                  >
                    문의내용
                  </Button>
                </Col>

                <Row className="my-3">
                  <Col xs={4} className="d-flex align-items-center">
                    <span className="border-end pe-3 me-3 fs-5">
                      헬프데스크 처리 현황
                    </span>
                    <span>1</span>
                  </Col>
                </Row>

                <Col>
                  <span>페이지네이션</span>
                  {/* 팝업 버튼 */}
                  <Button
                    className={
                      "rounded-5 px-5 fw-bold fs-5 btn-outline-primary"
                    }
                    style={{ borderColor: "#fff", color: "#fff" }}
                    onClick={() => setShowContentModal02(true)}
                  >
                    문의내용
                  </Button>
                </Col>
              </Col>
            </Col>
          </Col>
        )}
      </Row>
      <CustomModal
        show={showContentModal01}
        onHide={() => setShowContentModal01(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit01({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
      <CustomModal
        show={showContentModal02}
        onHide={() => setShowContentModal02(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit02({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default SeniorCenterUsageStatus;
