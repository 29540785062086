import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Spinner } from "react-bootstrap";
import LeftImg from "../../../assets/slide/left.svg";
import Arr from "../../../assets/slide/arr.svg";
import Last from "../../../assets/slide/last.png";
import Live from "../../../assets/slide/live.svg";
import RightImg from "../../../assets/slide/right.svg";
// import { getProgramSchedule } from "../../../api/apiClient";
import { STATIC_FILE_URL } from "../../../config";

const Slide = ({ slideList }) => {
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true); // 슬라이드 이동 시 transition 활성화 여부
  const [isLoading, setIsLoading] = useState(false);
  const [lastEventEnded, setLastEventEnded] = useState(false);

  // console.log("isTransitionEnabled", isTransitionEnabled);

  // console.log("currentDateIndex", currentDateIndex);
  // console.log("visibleStartIndex", visibleStartIndex);

  // const getSlideList = () => {
  //   getProgramSchedule()
  //     .then((res) => {
  //       console.log("slideList res : ", res.data);
  //       setSlideList(res.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   getSlideList();
  // }, []);

  const handlePrevSlide = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex((prev) => prev - 1);
    } else if (currentDateIndex > 0) {
      setCurrentDateIndex((prev) => prev - 1);
      const prevSlide = slideList?.slides[currentDateIndex - 1];
      setVisibleStartIndex(prevSlide.events.length - 3);
      setIsTransitionEnabled(false); // 애니메이션 비활성화

      setTimeout(() => {
        // 애니메이션 비활성화 후 0.5초 뒤에 애니메이션 활성화
        setIsTransitionEnabled(true);
      }, 500);
    }
  };

  const handleNextSlide = () => {
    const currentSlide = slideList.slides[currentDateIndex];

    if (visibleStartIndex + 3 < currentSlide.events.length) {
      setVisibleStartIndex((prev) => prev + 1);
      setIsTransitionEnabled(true); // 애니메이션 활성화
    } else if (currentDateIndex < slideList?.slides.length - 1) {
      setCurrentDateIndex((prev) => prev + 1);
      setVisibleStartIndex(0);
      setIsTransitionEnabled(false); // 애니메이션 비활성화

      setTimeout(() => {
        // 애니메이션 비활성화 후 0.5초 뒤에 애니메이션 활성화
        setIsTransitionEnabled(true);
      }, 500);
    }
  };

  // "HH:mm" 포맷을 분 단위 숫자로 변환하는 함수
  const timeToMinutes = (timeStr) => {
    // console.log("timeStr", timeStr);
    if (!timeStr) return 0;

    const [hour, minute] = timeStr?.split(":").map(Number);
    return hour * 60 + minute;
  };

  const updateVisibleIndex = () => {
    if (!slideList || !slideList?.slides || slideList?.slides?.length === 0)
      return;

    const now = new Date();
    const nowMinutes = now.getHours() * 60 + now.getMinutes();
    let newStartIndex = 0;
    let found = false;

    const todayEvents = slideList?.slides[currentDateIndex]?.events;
    const lastEventIndex = todayEvents?.length - 1;
    const lastEventTime = timeToMinutes(
      todayEvents[lastEventIndex]?.start_time
    );

    // console.log(
    //   `현재 시간: ${now.getHours()}:${now.getMinutes()} (${nowMinutes}분)`
    // );

    // 현재 시간 이전의 방송 중 가장 가까운 방송 찾기
    for (let i = todayEvents.length - 1; i >= 0; i--) {
      const eventTime = timeToMinutes(todayEvents[i]?.start_time);
      // console.log(`이벤트 ${todayEvents[i].time} (${eventTime}분)`);

      if (eventTime <= nowMinutes) {
        newStartIndex = i;
        found = true;
        // console.log(`선택된 이벤트: ${todayEvents[i].time} (${eventTime}분)`);
        break;
      }
    }

    // 만약 현재 시간 이후의 방송만 있으면 가장 가까운 다음 방송을 찾음
    if (!found) {
      for (let i = 0; i < todayEvents.length; i++) {
        const eventTime = timeToMinutes(todayEvents[i]?.start_time);
        if (eventTime >= nowMinutes) {
          newStartIndex = i;
          // console.log(
          //   `가장 가까운 이후 이벤트: ${todayEvents[i].time} (${eventTime}분)`
          // );
          break;
        }
      }
    }

    // 마지막 방송이 끝났다면 마지막 이벤트를 유지
    if (!found && nowMinutes >= lastEventTime) {
      newStartIndex = lastEventIndex;
      // console.log(
      //   `마지막 방송: ${todayEvents[lastEventIndex].end_time} (${lastEventTime}분)`
      // );
    }

    if (visibleStartIndex !== newStartIndex) {
      setIsTransitionEnabled(true);
      setVisibleStartIndex(newStartIndex);
    }

    setTimeout(updateVisibleIndex, 30000);
  };

  const isLastEventEnded = () => {
    if (!slideList || !slideList?.slides || slideList?.slides.length === 0)
      return false;

    const todayEvents = slideList?.slides[currentDateIndex]?.events;
    if (todayEvents.length === 0) return false;

    const nowMinutes = new Date().getHours() * 60 + new Date().getMinutes(); // 현재 시간을 분 단위로 변환
    const lastEventTime = timeToMinutes(
      todayEvents[todayEvents.length - 1].end_time
    ); // 마지막 방송 종료 시간

    // console.log(`현재 시간: ${nowMinutes}분`);
    // console.log(`마지막 방송 종료 시간: ${lastEventTime}분`);

    return nowMinutes >= lastEventTime; // 현재 시간이 마지막 방송 종료 시간 이후라면 true
  };

  useEffect(() => {
    updateVisibleIndex();
    setLastEventEnded(isLastEventEnded());

    const interval = setInterval(() => {
      setLastEventEnded(isLastEventEnded()); // 주기적으로 마지막 방송 여부 체크
    }, 30000); // 30초마다 실행

    return () => {
      clearTimeout(updateVisibleIndex);
      clearInterval(interval); // 클린업
    };
  }, [slideList]);

  if (!slideList || !slideList?.slides || slideList?.slides?.length === 0) {
    return <div></div>;
  }

  const currentSlide = slideList?.slides[currentDateIndex];

  // console.log("slideList", slideList);

  return (
    <Row className={"footer"}>
      <Col xs={12} className={"w-100 h-100"}>
        <Col
          className={"display border-0 h-100"}
          style={{
            backgroundColor: "#181818",
          }}
        >
          <Row className={"justify-content-center h-100 gap-2"}>
            {/* 왼쪽 arr */}
            {/* <Col
              className={"text-center my-auto"}
              style={{
                flex: "0 0 auto",
                width: "10%",
              }}
            >
              <img
                src={LeftImg}
                alt={"slide"}
                className={"w-75 cursor-hover"}
                tabIndex={0}
                onClick={handlePrevSlide}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handlePrevSlide();
                  }
                }}
                // style={{
                //   visibility: currentDateIndex > 0 ? "visible" : "hidden",
                // }}
                style={{
                  visibility:
                    visibleStartIndex === 0 && currentDateIndex === 0
                      ? "hidden"
                      : "visible",
                }}
              />
            </Col> */}
            {/* 슬라이드 */}
            <Col
              className="my-auto pe-0 w-100"
              style={{
                position: "relative",
                overflow: "hidden",
                // width: "66%",
              }}
            >
              <div
                className="justify-content-between align-items-center"
                style={{
                  display: "grid",
                  // gap: "1rem",
                  gridTemplateColumns: `repeat(${Math.max(
                    currentSlide.events.length,
                    3
                  )}, 33.33%) auto auto`,
                  transition: isTransitionEnabled
                    ? "transform 0.5s ease"
                    : "none",
                  transform: `translateX(-${visibleStartIndex * 33.33}%)`,
                  // transform: `translateX(calc(-${
                  //   visibleStartIndex * 32.5
                  // }% - ${visibleStartIndex}rem))`,
                }}
              >
                {currentSlide.events.map((event, idx) => {
                  // 현재 보이는 첫 번째 슬라이드
                  const isFirstVisible = idx === visibleStartIndex;
                  // 마지막 슬라이드인지 확인
                  const isLastIndex = idx === currentSlide.events.length - 1;

                  return (
                    <React.Fragment key={idx}>
                      {lastEventEnded ? (
                        <Col
                          className="my-auto p-0 me-2"
                          style={{
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                            backgroundColor: "#000",
                            border: "none",
                            // border: isFirstVisible ? "3px solid #fff" : "none",
                            borderRadius: "16px",
                            overflow: "hidden",
                          }}
                        >
                          <Col
                            className="p-0"
                            style={{
                              minWidth: "428px",
                              borderRadius: "16px",
                              // height: "120px",
                            }}
                          >
                            <img
                              src={Last}
                              alt={"last"}
                              className={"w-100 h-100"}
                              style={{
                                borderRadius: "16px",
                                objectFit: "cover",
                                objectPosition: "center center",
                              }}
                            />
                          </Col>
                        </Col>
                      ) : (
                        <>
                          <div
                            className="my-auto p-0 pe-3"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              borderRadius: "16px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#000",
                                border: isFirstVisible
                                  ? "3px solid #fff"
                                  : "none",
                                borderRadius: "16px",
                              }}
                            >
                              <div
                                className={"overflow-hidden border-0"}
                                style={{
                                  maxHeight: "120px",
                                  width: "100%",
                                  borderRadius: 0,
                                }}
                              >
                                <div style={{ height: "120px" }}>
                                  <img
                                    src={
                                      event.image
                                        ? `${STATIC_FILE_URL}${event.image}`
                                        : "https://via.placeholder.com/150"
                                    }
                                    alt={""}
                                    className={"img-fluid w-100 h-100"}
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "center center",
                                      zIndex: -1,
                                      borderRadius: "12px 12px 0 0",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className={"mt-1 px-2 text-center"}
                                style={{
                                  maxWidth: "100%",
                                  height: "100%",
                                  overflow: "hidden",
                                  height: "2.5rem",
                                  whiteSpace: "nowrap", // 줄 바꿈 방지
                                  textOverflow: "ellipsis", // 말줄임 처리
                                }}
                              >
                                <span
                                  className={
                                    "fs-4 align-middle my-auto me-3 fw-bold"
                                  }
                                >
                                  {event.start_time}
                                </span>
                                {event.isLive && (
                                  <span className="me-2">
                                    <img
                                      src={Live}
                                      alt="live"
                                      style={{
                                        height: "60%",
                                      }}
                                    />
                                  </span>
                                )}

                                <span
                                  className={
                                    "fs-4 align-middle my-auto text-white"
                                  }
                                >
                                  {event.title}
                                </span>
                              </div>
                            </div>
                          </div>
                          {isLastIndex && (
                            <Col
                              className="p-0"
                              style={{
                                minWidth: "428px",
                                borderRadius: "16px",
                                // height: "120px",
                              }}
                            >
                              <img
                                src={Last}
                                alt={"last"}
                                className={"w-100 h-100"}
                                style={{
                                  borderRadius: "16px",
                                  objectFit: "cover",
                                  objectPosition: "center center",
                                }}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              {/* 오른쪽 arr */}
              <Col
                className={"text-center my-auto d-flex justify-content-center"}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  flex: "0 0 auto",
                  width: "60px",
                  // height: "170px",
                  height: "100%",
                  borderRadius: "16px",
                  backgroundImage:
                    "linear-gradient(to right, transparent, rgba(0,0,0,0.7))",
                  visibility:
                    // currentDateIndex < slideList.slides.length - 1 ||
                    visibleStartIndex <
                    Math.max(0, currentSlide?.events?.length - 3)
                      ? "visible"
                      : "hidden",
                }}
              >
                <img
                  src={Arr}
                  alt={"slide"}
                  className={"w-75"}
                  // tabIndex={0}
                  // onClick={handleNextSlide}
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     handleNextSlide();
                  //   }
                  // }}
                />
              </Col>
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default Slide;
