import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";

const HelpDeskStatus = () => {
  const [showDetail01, setShowDetail01] = useState(false);
  const [showDetail02, setShowDetail02] = useState(false);

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
  });

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const columns = [
    { key: "id", title: "번호" },
    { key: "date", title: "접수일자" },
    { key: "location", title: "구" },
    { key: "center", title: "경로당명" },
    { key: "category", title: "문의카테고리" },
    { key: "title", title: "제목" },
    {
      key: "actions",
      title: "상세보기",
      render: (_, row) => (
        <Button
          className={"rounded-5 px-5 fw-bold fs-5 btn-outline-primary"}
          onClick={() => setShowDetail01(true)}
          style={{ borderColor: "#fff", color: "#fff" }}
        >
          상세보기
        </Button>
      ),
    },
  ];

  const dummyData = [
    {
      id: 1,
      date: "2023-12-01",
      location: "남동구",
      center: "A 경로당",
      category: "기기 문제",
      title: "셋톱박스 연결 불량",
    },
    {
      id: 2,
      date: "2023-12-02",
      location: "연수구",
      center: "B 경로당",
      category: "사용 방법 문의",
      title: "리모컨 작동법",
    },
    {
      id: 3,
      date: "2023-12-03",
      location: "부평구",
      center: "C 경로당",
      category: "기기 문제",
      title: "화면 출력 이상",
    },
    {
      id: 4,
      date: "2023-12-04",
      location: "미추홀구",
      center: "D 경로당",
      category: "기타",
      title: "기타 문의 사항",
    },
  ];

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className={"row-gap-3 m-0"}>
        {/* 경로당 검색 */}
        <Col
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row>
            <DateSearch
              title={"접수일자 검색"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />
            <Col xs={1} className={"text-end ps-0"}>
              <Button
                type={"button"}
                className="w-100 h-100 fs-5 fw-bold"
                onClick={() => {}}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 테이블 */}
        <Row
          className="gap-3"
          style={{
            height: "calc(100vh - 18rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* 1 */}
          <Row className="gap-3 p-0 m-0">
            <Col
              className="p-3 border rounded"
              style={{
                backgroundColor: "#1D222A",
              }}
            >
              <Row className="my-3">
                <Col xs={2} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">접수 현황</span>
                  <span>1</span>
                </Col>
                <Col xs={2} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">잔여 현황</span>
                  <span>1</span>
                </Col>
              </Row>
              <PaginatedTable columns={columns} data={dummyData} pageSize={5} />
            </Col>
            {showDetail01 && (
              <Col
                xs={4}
                className="p-3 border rounded d-flex flex-column"
                style={{ backgroundColor: "#1D222A" }}
              >
                <Col xs={12} className="mb-2">
                  <span className="fs-4 fw-bold">| 상세내용</span>
                </Col>
                <Form.Control
                  as="textarea"
                  className="flex-grow-1 mt-2"
                  readOnly
                />
              </Col>
            )}
          </Row>

          {/* 2 */}
          <Row className="gap-3 p-0 m-0">
            <Col
              className="p-3 border rounded"
              style={{
                backgroundColor: "#1D222A",
              }}
            >
              <Row className="my-3">
                <Col xs={4} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">처리 현황</span>
                  <span>1</span>
                </Col>
              </Row>
              <PaginatedTable columns={columns} data={dummyData} pageSize={5} />
            </Col>
            {showDetail02 && (
              <Col
                xs={4}
                className="p-3 border rounded d-flex flex-column"
                style={{ backgroundColor: "#1D222A" }}
              >
                <Col xs={12} className="mb-2">
                  <span className="fs-4 fw-bold">| 상세내용</span>
                </Col>
                <Form.Control
                  as="textarea"
                  className="flex-grow-1 mt-2"
                  readOnly
                />
              </Col>
            )}
          </Row>
        </Row>
      </Row>
    </Form>
  );
};

export default HelpDeskStatus;
