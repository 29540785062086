import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  getDetailScenario,
  getIssueCategory,
  getRiskCategory,
  getScenario,
  getSolutionCategory,
  putScenario,
} from "../../api/apiClient";
import "./Issue.scss";

const IssueScenario = ({ permissionLevel }) => {
  const [scenarioList, setScenarioList] = useState([]);
  const [issueCategory, setIssueCategory] = useState();
  const [riskCategory, setRiskCategory] = useState();
  const [solutionCategory, setSolutionCategory] = useState();

  const [selectedScenarioId, setSelectedScenarioId] = useState(null);

  const [detailFormData, setDetailFormData] = useState({
    category: "",
    risk_level: "",
    solution: "",
  });

  const getIssueCategoryList = () => {
    getIssueCategory()
      .then((res) => {
        console.log("getIssueCategoryList", res.data);
        setIssueCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getRiskCategoryList = () => {
    getRiskCategory()
      .then((res) => {
        console.log("getRistCategoy", res.data);
        setRiskCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getSolutionCategoryList = () => {
    getSolutionCategory()
      .then((res) => {
        console.log("getSolutionCategory", res.data);
        setSolutionCategory(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getScenarioList = () => {
    getScenario()
      .then((res) => {
        console.log("getScenarioList", res.data);
        setScenarioList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getScenarioList();
    getIssueCategoryList();
    getRiskCategoryList();
    getSolutionCategoryList();
  }, []);

  const handleDetail = (id) => {
    setSelectedScenarioId(id);
    getDetailScenario(id)
      .then((res) => {
        console.log("getDetailScenario", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updateData = {
      category: detailFormData.category.id || detailFormData.category,
      risk_level: detailFormData.risk_level.id || detailFormData.risk_level,
      solution: detailFormData.solution.id || detailFormData.solution,
    };

    // console.log("handleEdit", updateData);

    putScenario(selectedScenarioId, updateData)
      .then((res) => {
        console.log("수정 완료 : ", res.data);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Row className="p-3 border rounded" style={{ backgroundColor: "#1D222A" }}>
      <Col className="w-100 mb-1">
        <span className="fs-4 fw-bold w-100">| 이슈 사항 리스트</span>
      </Col>
      <Row>
        {/* 왼쪽 - 리스트 */}
        <Col
          xs={6}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 17.5rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {scenarioList?.map((scenario) => (
            <Row
              className={`${
                selectedScenarioId === scenario.id ? "active" : ""
              } scenario-btn  mb-3`}
              key={scenario.id}
            >
              <Col xs={1} className="border rounded text-center me-3 py-3 fs-5">
                {scenario.id}
              </Col>
              <Col
                className="border rounded text-center py-3 fs-5 fw-bold scenario-content"
                onClick={() => handleDetail(scenario.id)}
              >
                {scenario.name}
              </Col>
            </Row>
          ))}
        </Col>

        {/* 오른쪽 - 선택 */}
        <Col xs={6} className="d-flex flex-column justity-content-between ps-5">
          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>카테고리 선택</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select
                className="primary-select"
                size="lg"
                value={detailFormData.category?.id}
                onChange={(e) =>
                  setDetailFormData({
                    ...detailFormData,
                    category: e.target.value,
                  })
                }
                disabled={permissionLevel === 1}
              >
                <option defaultChecked value={""}>
                  카테고리
                </option>
                {issueCategory &&
                  issueCategory.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Col>

          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>위험도</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select
                className="primary-select"
                size="lg"
                value={detailFormData.risk_level?.id}
                onChange={(e) =>
                  setDetailFormData({
                    ...detailFormData,
                    risk_level: e.target.value,
                  })
                }
                disabled={permissionLevel === 1}
              >
                <option defaultChecked value={""}>
                  위험도 선택
                </option>
                {riskCategory &&
                  riskCategory.map((risk) => (
                    <option key={risk.id} value={risk.id}>
                      {risk.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Col>

          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>처리방법</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select
                className="primary-select"
                size="lg"
                value={detailFormData.solution?.id}
                onChange={(e) =>
                  setDetailFormData({
                    ...detailFormData,
                    solution: e.target.value,
                  })
                }
                disabled={permissionLevel === 1}
              >
                <option defaultChecked value={""}>
                  처리방법 선택
                </option>
                {solutionCategory &&
                  solutionCategory.map((solution) => (
                    <option key={solution.id} value={solution.id}>
                      {solution.title}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Col>

          {(permissionLevel === 3 || permissionLevel === 2) && (
            <Col xs={12} className="text-end">
              <Button size="lg" className="btn-send me-3" onClick={handleEdit}>
                저장
              </Button>
              <Button
                size="lg"
                variant="secondary"
                // onClick={() => {
                //   setDetailFormData({
                //     category: "",
                //     risk_level: "",
                //     solution: "",
                //   });
                // }}
                onClick={() => {
                  setDetailFormData({
                    category: { id: "", name: "" },
                    risk_level: { id: "", name: "" },
                    solution: { id: "", title: "" },
                  });
                }}
              >
                초기화
              </Button>
            </Col>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default IssueScenario;
