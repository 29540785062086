import React from "react";
import {Modal, Button} from "react-bootstrap";
import "./CustomModal.scss";

const CustomModal = ({show, onHide, onConfirm, children, size = "xl"}) => {
  return (
    <Modal size={size} show={show} onHide={onHide} centered className="modal-bordered">
      <Modal.Header className={"border-bottom-0 d-flex justify-content-end"}>
        <Button
          variant="secondary"
          className={"px-4 fw-bold fs-5 btn"}
          onClick={onHide}>
          닫기
        </Button>
      </Modal.Header>
      <Modal.Body className="px-5">{children}</Modal.Body>
      {/* <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                    Save Changes
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer> */}
    </Modal>
  );
};

export default CustomModal;