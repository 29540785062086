import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  getDistrict,
  getLifeCareRanking,
  getSeniorCenter,
  getWalkingCourses,
  getWalkingSections,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import CenterSearch from "../common/search/CenterSearch";

const CourseRanking = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
    course: "",
    section: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const [overallRanking, setOverallRanking] = useState([]); // 종합 순위
  const [scoreRanking, setScoreRanking] = useState([]); // 점수 순위
  const [distanceRanking, setDistanceRanking] = useState([]); // 누적 거리 순위
  const [durationRanking, setDurationRanking] = useState([]); // 누적 이용시간 순위
  const [totalRanking, setTotalRanking] = useState([]); // 전체 랭킹

  const [courseCategory, setCourseCategory] = useState([]);
  const [sectionCategory, setSectionCategory] = useState([]);

  //  구 리스트
  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        // console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 생활케어 시스템 운영관리 - 워킹머신 랭킹
  const getLifeCareRankingList = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getLifeCareRanking(
      searchParam.course,
      searchParam.section,
      startData,
      endData,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getLifeCareRanking : ", res.data);
        setOverallRanking(res.data.overall_ranking);
        setScoreRanking(res.data.score_ranking);
        setDistanceRanking(res.data.distance_ranking);
        setDurationRanking(res.data.duration_ranking);
        setTotalRanking(res.data?.total_ranking);
      })
      .catch((err) => console.error(err));
  };

  // 생활케어 시스템 운영관리 - 워킹머신 코스 리스트
  const getWalkingCoursesCategory = () => {
    getWalkingCourses()
      .then((res) => {
        console.log("getWalkingCourses : ", res.data);
        setCourseCategory(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  // 생활케어 시스템 운영관리 - 워킹머신 구간 리스트
  const getWalkingSectionsCategory = () => {
    const courseId = searchParam.course;
    if (!courseId) return;

    getWalkingSections(courseId)
      .then((res) => {
        console.log("getWalkingSections : ", res.data);
        setSectionCategory(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          console.warn("해당 코스에 구간 데이터가 없습니다.");
          setSectionCategory([]);
        } else {
          console.error(err);
        }
      });
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getLifeCareRankingList();
    getWalkingCoursesCategory();
    getWalkingSectionsCategory();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  useEffect(() => {
    if (searchParam.course) {
      getWalkingSectionsCategory();
    } else {
      setSectionCategory([]);
    }
  }, [searchParam.course]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const handleSearch = (e) => {
    e.preventDefault();
    getLifeCareRankingList();
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="row-gap-3">
          <Col xs={12} className="d-flex w-100 justify-content-between gap-3">
            <Row className="w-100">
              <Col xs={4} className="my-auto">
                <span className="fs-4 fw-bold">| 대전 코스 검색</span>
              </Col>
              <Col xs={4} className="pe-0">
                <Form.Select
                  className="h-100 fs-5"
                  value={searchParam.course || ""}
                  onChange={(e) => {
                    setSearchParam({ ...searchParam, course: e.target.value });
                  }}
                >
                  <option value={""}>코스 선택</option>
                  {courseCategory &&
                    courseCategory?.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col xs={4} className="">
                <Form.Select
                  className="h-100 fs-5"
                  value={searchParam.section || ""}
                  onChange={(e) => {
                    setSearchParam({ ...searchParam, section: e.target.value });
                  }}
                  disabled={!searchParam.course}
                >
                  <option value={""}>
                    {!searchParam.course
                      ? "구간 선택"
                      : sectionCategory.length === 0
                      ? "구간이 없습니다"
                      : "구간 선택"}
                  </option>
                  {sectionCategory &&
                    sectionCategory?.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
            <DateSearch
              title={"기간 설정"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />
          </Col>

          <CenterSearch
            title={"경로당 검색 필터"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            districtList={districtList}
            centerList={centerList}
            handleKeywordChange={handleKeywordChange}
            handleCenterChange={handleCenterChange}
            onSearch={handleSearch}
          />
        </Row>
      </Col>

      {/* 내용 */}
      <Col
        xs={12}
        className=""
        style={{
          height: "calc(100vh - 22rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row className="d-flex gap-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col>
                <span className="fs-4 fw-bold">| 종합 순위</span>
              </Col>

              {overallRanking?.map((overall, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{overall.rank}위</Col>
                    <Col>{overall.district}</Col>
                    <Col>{overall.center_name}</Col>
                    <Col>{overall.user_id}</Col>
                    <Col>{overall.overall_score}</Col>
                  </Row>
                </Col>
              ))}

              {overallRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col>
                <span className="fs-4 fw-bold">| 점수 순위</span>
              </Col>

              {scoreRanking?.map((score, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col className="col-1">{score.rank}위</Col>
                    <Col className="col-2">{score.district}</Col>
                    <Col className="col-4">{score.center_name}</Col>
                    <Col className="col-4">{score.user_id}</Col>
                    <Col className="col-1">{score.score}</Col>
                  </Row>
                </Col>
              ))}

              {scoreRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row className="d-flex gap-4 mt-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A", overflow: "auto" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col>
                <span className="fs-4 fw-bold">| 누적 거리 순위</span>
              </Col>

              {distanceRanking?.map((distance, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{distance.rank}위</Col>
                    <Col>{distance.district}</Col>
                    <Col>{distance.center_name}</Col>
                    <Col>{distance.user_id}</Col>
                    <Col>{distance.distance}</Col>
                  </Row>
                </Col>
              ))}

              {distanceRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"justify-content-center gap-3"}>
              <Col>
                <span className="fs-4 fw-bold">| 누적 이용시간 순위</span>
              </Col>

              {durationRanking?.map((duration, idx) => (
                <Col xs={12} key={idx}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>{duration.rank}위</Col>
                    <Col>{duration.district}</Col>
                    <Col>{duration.center_name}</Col>
                    <Col>{duration.user_id}</Col>
                    <Col>{duration.duration}</Col>
                  </Row>
                </Col>
              ))}

              {durationRanking.length === 0 && (
                <Col xs={12}>
                  <Row
                    className={"text-center py-3 border-top border-bottom fs-5"}
                    style={{ backgroundColor: "#353C47" }}
                  >
                    <Col>데이터가 없습니다.</Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row
          className="p-3 border rounded mt-4"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12} className={"mb-3"}>
            <span className="fs-4 fw-bold">| 전체 랭킹</span>
          </Col>
          <Col xs={12}>
            <Row>
              <Col>
                <Row
                  className={"text-center py-3 border-top border-bottom fs-5"}
                  style={{ backgroundColor: "#353C47" }}
                >
                  {[
                    "순위",
                    "구",
                    "경로당",
                    "ID",
                    "코스명",
                    "구간",
                    "점수",
                    "거리",
                    "시간",
                  ].map((col, idx) => (
                    <Col key={idx}>{col}</Col>
                  ))}
                </Row>
              </Col>
            </Row>
            {totalRanking?.map((total, idx) => (
              <Col key={idx}>
                <Row
                  className={"text-center py-3 border-top border-bottom fs-5"}
                  style={{ backgroundColor: "" }}
                >
                  <Col>{total.rank}위</Col>
                  <Col>{total.district}</Col>
                  <Col>{total.center_name}</Col>
                  <Col>{total.user_id}</Col>
                  <Col>{total.course}</Col>
                  <Col>{total.section}</Col>
                  <Col>{total.score}</Col>
                  <Col>{total.distance}</Col>
                  <Col>{total.duration}</Col>
                </Row>
              </Col>
            ))}

            {totalRanking.length === 0 && (
              <Col xs={12}>
                <Row
                  className={"text-center py-3 border-top border-bottom fs-5"}
                >
                  <Col>데이터가 없습니다.</Col>
                </Row>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CourseRanking;
