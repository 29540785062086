import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import TabMenu from "./TabMenu";
import BackgroundImg from "../../assets/landing/bg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getTokenVerify } from "../../api/apiClient";

const Frame = ({
  title,
  children,
  className,
  selectedTab,
  showTabMenu,
  onTabSelect,
  subMenu,
}) => {
  // 로그인 되어있는지 체크
  // 안되어있으면 로그인 페이지로 이동
  // 되어있으면 해당 페이지로 이동
  // const [isLogin, setIsLogin] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const checkLogin = () => {
    // 회원가입 페이지에서는 해당 기능 사용 X
    if (location.pathname.includes("/register")) {
      console.log("회원가입 페이지");
      return;
    }

    if (localStorage.getItem("access")) {
    } else {
      navigate("/login");
    }
  };

  const checkAuthority = () => {
    // 회원가입 페이지에서는 해당 기능 사용 X
    if (location.pathname.includes("/register")) {
      console.log("회원가입 페이지");
      return;
    }

    // external일 경우 메시지 전송 페이지로 이동
    if (localStorage.getItem("type") === "external") {
      // navigate("/message/register");
      getTokenVerify()
        .then((res) => {
          console.log("Token verify response : ", res);
        })
        .catch((error) => {
          console.error("Token verify error : ", error);
          // navigate("/login");
        });
    }
  };

  useEffect(() => {
    checkLogin();
    checkAuthority();
  }, []);

  const [activeTab, setActiveTab] = useState("");

  return (
    <Container
      fluid
      className={"container-fluid landing container-wrap p-0 w-100"}
      style={{
        height: "100vh",
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "cover",
        overflowX: "hidden ",
      }}
    >
      <Row
        className="position-fixed top-0 start-50 w-100 p-0 m-0"
        style={{
          zIndex: 1000,
          transform: "translateX(-50%)",
        }}
      >
        <Header title={title} />
        {showTabMenu && (
          <TabMenu
            subMenu={subMenu}
            activeTab={selectedTab}
            onTabSelect={(tab) => {
              console.log("tab", tab);
              setActiveTab(tab); // Frame 내에서 상태 업데이트
              if (onTabSelect) onTabSelect(tab); // 부모 컴포넌트에 콜백 전달
            }}
          />
        )}
      </Row>
      <Row
        className={`px-md-4 px-lg-5 justify-content-center mx-auto ${className}`}
        style={{
          marginTop: "11rem",
          overflowY: "hidden",
          maxWidth: "1920px",
          minWidth: "1920px",
        }}
      >
        {children}
      </Row>
    </Container>
  );
};

export default Frame;
