import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import {
  deleteWalkingCompetition,
  getDetailWalkingCompetition,
  getWalkingCompetition,
  getWalkingCourses,
  getWalkingRounds,
  getWalkingSections,
  postWalkingCompetition,
  putDetailWalkingCompetition,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const CompetitionData = ({ permissionLevel }) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    round: "",
    name: "",
  });

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [detailFormData, setDetailFormData] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    name: "",
    round: "",
    section: "",
    course: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "roundText", title: "회차" },
    { key: "name", title: "대회명" },
    { key: "course_name", title: "코스명" },
    { key: "section_name", title: "구간" },
    {
      key: "period",
      title: "대회기간",
    },
    {
      key: "created_at",
      title: "등록일시",
    },
    {
      key: "edit",
      title: "수정하기",
    },
  ];

  const [rounds, setRounds] = useState([]);
  const [courseCategory, setCourseCategory] = useState([]);
  const [sectionCategory, setSectionCategory] = useState([]);

  const getWalkingCompetitionList = () => {
    const startDate = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endDate = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getWalkingCompetition(
      searchParam.round,
      searchParam.name,
      currentPage,
      startDate,
      endDate
    )
      .then((res) => {
        console.log("getWalkingCompetition : ", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getWalkingRoundsList = () => {
    getWalkingRounds()
      .then((res) => {
        console.log("getWalkingRounds : ", res.data);
        setRounds(res.data);
      })
      .catch((err) => console.error(err));
  };

  const editData = {
    ...tableData,
    results: tableData?.results?.map((data) => ({
      ...data,
      roundText: `${data.round}회차`,
      period: `${data.start_date} ~ ${data.end_date}`,
    })),
  };

  // 생활케어 시스템 운영관리 - 워킹머신 코스 리스트
  const getWalkingCoursesCategory = () => {
    getWalkingCourses()
      .then((res) => {
        console.log("getWalkingCourses : ", res.data);
        setCourseCategory(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  // 생활케어 시스템 운영관리 - 워킹머신 구간 리스트
  const getWalkingSectionsCategory = () => {
    const courseId = detailFormData.course;
    if (!courseId) return;

    getWalkingSections(courseId)
      .then((res) => {
        console.log("getWalkingSections : ", res.data);
        setSectionCategory(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (detailFormData.course) {
      getWalkingSectionsCategory();
    } else {
      setSectionCategory([]);
    }
  }, [detailFormData.course]);

  useEffect(() => {
    getWalkingCompetitionList();
    getWalkingRoundsList();
    getWalkingCoursesCategory();
    getWalkingSectionsCategory();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getWalkingCompetitionList();
  }, [currentPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    getWalkingCompetitionList();
  };

  // 상세보기 버튼
  const handleDetail = (data) => {
    console.log("handleDetail id : ", data);
    setShowEditModal(true);
    // setDetailFormData(data);

    getDetailWalkingCompetition(data?.id)
      .then((res) => {
        console.log("id response data:", res.data);
        // 데이터를 `detailFormData` 형식에 맞게 변환
        const fetchedData = {
          id: res.data.id,
          year: res.data.start_date.split("-")[0],
          month: parseInt(res.data.start_date.split("-")[1], 10).toString(),
          day: parseInt(res.data.start_date.split("-")[2], 10).toString(),
          year2: res.data.end_date.split("-")[0],
          month2: parseInt(res.data.end_date.split("-")[1], 10).toString(),
          day2: parseInt(res.data.end_date.split("-")[2], 10).toString(),
          name: res.data.name,
          round: res.data.round,
          course: res.data.course.id,
          section: res.data.section.id,
        };

        console.log("fetchedData", fetchedData);

        setDetailFormData(fetchedData);
      })
      .catch((err) => console.error(err));
  };

  const handleSave = () => {
    if (!window.confirm("저장 하시겠습니까?")) return;

    const startDate = `${detailFormData.year}-${detailFormData.month}-${detailFormData.day}`;
    const endDate = `${detailFormData.year2}-${detailFormData.month2}-${detailFormData.day2}`;

    const updatedData = {
      round: detailFormData.round,
      name: detailFormData.name,
      section: detailFormData.section,
      start_date: startDate,
      end_date: endDate,
    };

    postWalkingCompetition(updatedData)
      .then((res) => {
        console.log("post : ", res.data);
        setShowRegisterModal(false);
        getWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const startDate = `${detailFormData.year}-${detailFormData.month}-${detailFormData.day}`;
    const endDate = `${detailFormData.year2}-${detailFormData.month2}-${detailFormData.day2}`;

    const updatedData = {
      round: detailFormData.round,
      name: detailFormData.name,
      section: detailFormData.section,
      start_date: startDate,
      end_date: endDate,
    };

    console.log("수정", detailFormData.id);
    console.log("수정", updatedData);

    putDetailWalkingCompetition(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료 : ", res.data);
        setShowEditModal(false);
        getWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteWalkingCompetition(detailFormData.id)
      .then((res) => {
        // console.log(res.data);
        setShowEditModal(false);
        getWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  // 대회등록 모달창 내용
  const renderEdit1 = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row className="">
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 등록하기</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회 회차
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="number"
                        min="1"
                        placeholder="대회 회차 입력"
                        value={detailFormData.round || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          // 입력 값이 양수인 경우에만 상태 업데이트
                          if (
                            !value ||
                            (Number(value) > 0 && /^[0-9]*$/.test(value))
                          ) {
                            setDetailFormData({
                              ...detailFormData,
                              round: value,
                            });
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회명
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회명 입력"
                        value={detailFormData.name}
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={detailFormData.course || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            course: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>코스 선택</option>
                        {courseCategory &&
                          courseCategory?.map((course) => (
                            <option key={course.id} value={course.id}>
                              {course.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={detailFormData.section || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            section: e.target.value,
                          });
                        }}
                        disabled={!detailFormData.course}
                      >
                        <option value={""}>
                          {!detailFormData.course
                            ? "구간 선택"
                            : sectionCategory.length === 0
                            ? "구간이 없습니다"
                            : "구간 선택"}
                        </option>
                        {sectionCategory &&
                          sectionCategory?.map((section) => (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기간 설정
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-2">
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.year} // 연도
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              year: e.target.value,
                            })
                          }
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.month}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              month: e.target.value,
                            })
                          }
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}월
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.day}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              day: e.target.value,
                            })
                          }
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                              {day}일
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col
                        className="border rounded text-center p-2"
                        style={{ maxWidth: "2rem" }}
                      >
                        ~
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.year2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              year2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.month2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              month2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}월
                            </option>
                          ))}
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.day2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              day2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                              {day}일
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button
              className="btn-send rounded-5 px-5 fw-bold fs-5"
              onClick={handleSave}
            >
              저장
            </Button>
            <Button
              className="rounded-5 px-5 fw-bold fs-5"
              variant="secondary"
              onClick={() => {
                setDetailFormData({
                  year: today.getFullYear(),
                  month: today.getMonth() + 1,
                  day: today.getDate(),
                  year2: today.getFullYear(),
                  month2: today.getMonth() + 1,
                  day2: today.getDate() + 1,
                  name: "",
                  round: "",
                  section: "",
                  course: "",
                });
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  // 수정하기 모달창 내용
  const renderEdit2 = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 수정하기</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회 회차
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="number"
                        min="1"
                        placeholder="대회 회차 입력"
                        value={detailFormData.round}
                        onChange={(e) => {
                          const value = e.target.value;
                          // 입력 값이 양수인 경우에만 상태 업데이트
                          if (
                            !value ||
                            (Number(value) > 0 && /^[0-9]*$/.test(value))
                          ) {
                            setDetailFormData({
                              ...detailFormData,
                              round: value,
                            });
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회명
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회명 입력"
                        value={detailFormData.name}
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={detailFormData.course || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            course: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>코스 선택</option>
                        {courseCategory &&
                          courseCategory?.map((course) => (
                            <option key={course.id} value={course.id}>
                              {course.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={detailFormData.section || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            section: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>구간 선택</option>
                        {sectionCategory &&
                          sectionCategory?.map((section) => (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기간 설정
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-2">
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.year} // 연도
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              year: e.target.value,
                            })
                          }
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.month}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              month: e.target.value,
                            })
                          }
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}월
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.day}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              day: e.target.value,
                            })
                          }
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                              {day}일
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col
                        className="border rounded text-center p-2"
                        style={{ maxWidth: "2rem" }}
                      >
                        ~
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.year2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              year2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.month2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              month2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}월
                            </option>
                          ))}
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                          value={detailFormData.day2}
                          onChange={(e) =>
                            setDetailFormData({
                              ...detailFormData,
                              day2: e.target.value,
                            })
                          }
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                              {day}일
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button
              className="btn-send rounded-5 px-5 fw-bold fs-5"
              onClick={handleEdit}
            >
              저장
            </Button>
            <Button
              className="rounded-5 px-5 fw-bold fs-5"
              variant="danger"
              onClick={handleDelete}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 대회 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form className="p-0" onSubmit={handleSearch}>
            <Row className="justify-content-between">
              <Col xs={8} className="mb-3">
                <Row className="justify-content-start">
                  <Col xs={2} className="my-auto">
                    <span className="fs-4 fw-bold">| 대회 검색</span>
                  </Col>
                  <Col xs={6}>
                    <Row className="row-gap-2">
                      <Col xs={5} className="">
                        <Form.Select
                          className="h-100 fs-5"
                          value={searchParam.round}
                          onChange={(e) =>
                            setSearchParam({
                              ...searchParam,
                              round: e.target.value,
                            })
                          }
                        >
                          <option value={""}>대회 회차 선택</option>
                          {[...new Set(rounds.map((round) => round.round))].map(
                            (uniqueRound, index) => (
                              <option key={index} value={uniqueRound}>
                                {uniqueRound}회차
                              </option>
                            )
                          )}
                        </Form.Select>
                        {/* <Form.Control
                          type="text"
                          placeholder="대회 회차 입력"
                          className="h-100 fs-5"
                          value={searchParam.round}
                          onChange={(e) =>
                            setSearchParam({
                              ...searchParam,
                              round: e.target.value,
                            })
                          }
                        /> */}
                      </Col>
                      <Col
                        xs={2}
                        className="border rounded text-center p-2 fs-5"
                      >
                        대회명
                      </Col>
                      <Col xs={5}>
                        <Form.Control
                          type="text"
                          placeholder="대회명 입력"
                          className="h-100 fs-5"
                          value={searchParam.name}
                          onChange={(e) =>
                            setSearchParam({
                              ...searchParam,
                              name: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={1} className="text-end">
                <Button
                  className="w-100 fs-5 fw-bold"
                  onClick={() => {
                    setShowRegisterModal(true);
                    setDetailFormData({
                      year: today.getFullYear(),
                      month: today.getMonth() + 1,
                      day: today.getDate(),
                      year2: today.getFullYear(),
                      month2: today.getMonth() + 1,
                      day2: today.getDate() + 1,
                      name: "",
                      round: "",
                      section: "",
                      course: "",
                    });
                  }}
                >
                  대회 등록
                </Button>
              </Col>
            </Row>

            <Row>
              <DateSearch
                title={"기간 설정"}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                daysInMonth={daysInMonth}
              />
              <Col xs={1} className={"text-end ps-0"}>
                <Button
                  type={"button"}
                  className="w-100 h-100 fs-5 fw-bold"
                  onClick={handleSearch}
                >
                  검색
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* 차트 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">| 대회 정보</span>
            </Col>
            <Col xs={12} className="">
              {tableData ? (
                <CustomTable
                  columns={columns}
                  tableData={editData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit1({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
      <CustomModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit2({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default CompetitionData;
