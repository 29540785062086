import React, { useEffect, useState } from "react";
import Frame from "../components/common/Frame";
import CompetitionData from "../components/lifestyle/CompetitionData";
import CompetitionHistory from "../components/lifestyle/CompetitionHistory";
import BattleData from "../components/lifestyle/BattleData";
import CourseRanking from "../components/lifestyle/CourseRanking";
import SmartTableContent from "../components/lifestyle/SmartTableContent";
import LifeStyleDeviceStatus from "../components/lifestyle/LifeStyleDeviceStatus";
import LifeStyleUsage from "../components/lifestyle/LifeStyleUsage";
import { useLocation } from "react-router-dom";
import { getMainMenu, getSubMenu } from "../api/apiClient";
import { Col, Spinner } from "react-bootstrap";
import Error from "../components/common/Error";

const Lifestyle = () => {
  // get location State
  const location = useLocation();
  // console.log(location.state);

  const permissionLevel = location.state.menu.permission_level;
  console.log(permissionLevel);

  const [initialSubMenu, setInitialSubMenu] = useState([]); // 초기 데이터 저장
  const [selectedSubMenu, setSelectedSubMenu] = useState(4); // 선택한 카테고리 ID
  const [selectedContent, setSelectedContent] = useState(null);
  const [mainTitle, setMainTitle] = useState([]);

  const [loading, setLoading] = useState(true);

  // 메인 메뉴
  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("getMainMenu", res.data);
        setMainTitle(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 서브 메뉴 리스트 조회
  const getSubMenuList = () => {
    setLoading(true);

    getSubMenu(selectedSubMenu)
      .then((res) => {
        console.log("getSubMenu", res.data);
        setInitialSubMenu(res.data);
        if (res.data.length > 0) {
          setSelectedContent(res.data[0].order + 21); // Set default selected tab
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedSubMenu) {
      getSubMenuList(selectedSubMenu); // 선택된 카테고리의 서브 메뉴 불러오기
    }
    getMainMenuList();
  }, [selectedSubMenu]);

  // const sortedSubMenuData = [...initialSubMenu].sort(
  //   (a, b) => a.order - b.order || 0
  // );

  const sortedSubMenuData = [
    ...initialSubMenu
      .sort((a, b) => a.order - b.order || 0) // order 기준 정렬
      .reduce((map, item) => {
        if (!map.has(item.display_name)) {
          map.set(item.display_name, item); // display_name이 처음 등장하면 추가
        }
        return map;
      }, new Map())
      .values(), // 중복 제거된 값들만 배열로 변환
  ];

  const subMenuComponents = {
    22: <CompetitionData permissionLevel={permissionLevel} />,
    23: <CompetitionHistory />,
    24: <BattleData />,
    25: <CourseRanking />,
    26: <SmartTableContent />,
    27: <LifeStyleDeviceStatus permissionLevel={permissionLevel} />,
    28: <LifeStyleUsage />,
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Col
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100vw", height: "80vh", zIndex: 100 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      );
    }

    const selectedMenu = initialSubMenu.find(
      (menu) => menu.id === selectedContent
    );
    return selectedMenu ? (
      subMenuComponents[selectedMenu.id] || <Error />
    ) : (
      <Error />
    );
  };

  return (
    <Frame
      // title={`${mainTitle[3]?.display_name || ""}`}
      title={
        mainTitle.find((menu) => menu.id === selectedSubMenu)?.display_name ||
        ""
      }
      showTabMenu={true}
      subMenu={sortedSubMenuData}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Lifestyle;
