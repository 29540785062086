import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const usePrintPDF = () => {
  const pdfRef = useRef();

  const handlePrintPDF = () => {
    const input = pdfRef.current;
    html2canvas(input, { scale: 2, scrollY: -window.scrollY })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;
        const ratio = imgWidth / pdfWidth;
        const scaledHeight = imgHeight / ratio;
        let heightLeft = scaledHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, scaledHeight);
        heightLeft -= pdfHeight;

        while (heightLeft > 0) {
          position = heightLeft - scaledHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, scaledHeight);
          heightLeft -= pdfHeight;
        }

        pdf.save('statistics.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  return { pdfRef, handlePrintPDF };
};

export default usePrintPDF;