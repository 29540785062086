import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";

const PieChart = ({
  title,
  series,
  centerText1,
  centerText2,
  innerSize,
  className,
  backgroundImage,
}) => {
  const options = useMemo(
    () => ({
      chart: {
        type: "pie",
        backgroundColor: "transparent", // 배경 투명
        height: "92%",
        events: {
          render() {
            const chart = this;

            const centerX = chart.plotWidth / 2 + chart.plotLeft; // 차트의 중앙 X 좌표
            const centerY = chart.plotHeight / 2 + chart.plotTop; // 차트의 중앙 Y 좌표

            // 첫 번째 텍스트 추가
            if (!chart.customCenterText1) {
              chart.customCenterText1 = chart.renderer
                .text(centerText1, centerX, centerY - 10) // 텍스트 위치 조정
                .attr({
                  align: "center", // 중앙 정렬
                  zIndex: 5,
                  "font-weight": "bold",
                })
                .css({
                  color: "#FFFFFF",
                  fontSize: "2rem",
                  textAlign: "center",
                })
                .add();
            } else {
              chart.customCenterText1.attr({
                text: centerText1,
                x: centerX, // X 위치 업데이트
                y: centerY - 10, // Y 위치 업데이트
              });
            }

            // 두 번째 텍스트 추가
            if (!chart.customCenterText2) {
              chart.customCenterText2 = chart.renderer
                .text(centerText2, centerX, centerY + 10) // 텍스트 위치 조정
                .attr({
                  align: "center", // 중앙 정렬
                  zIndex: 5,
                  "font-weight": "bold",
                })
                .css({
                  color: "#B2FF00",
                  fontSize: "1.5rem",
                  textAlign: "center",
                })
                .add();
            } else {
              chart.customCenterText2.attr({
                text: centerText2,
                x: centerX, // X 위치 업데이트
                y: centerY + 30, // Y 위치 업데이트
              });
            }
          },
        },
      },
      title: {
        text: title,
        style: { color: "#FFFFFF" }, // 제목 텍스트 색상
      },
      plotOptions: {
        pie: {
          innerSize: innerSize || "70%", // Donut chart (50%)
          dataLabels: {
            enabled: true,
            style: { color: "#FFFFFF" }, // 데이터 레이블 텍스트 색상
          },
        },
        series: {
          borderWidth: 0, // 막대 테두리 제거
        },
      },
      credits: {
        enabled: false, // Highcharts 로고 제거
      },
      legend: {
        itemStyle: { color: "#FFFFFF" }, // 범례 텍스트 색상
      },
      series,
    }),
    [
      title,
      series,
      centerText1,
      centerText2,
      innerSize,
      className,
      backgroundImage,
    ]
  );

  return (
    <Col
      className={`p-3 rounded ${className}`}
      style={{
        backgroundColor: "#1D222A",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default PieChart;
