import React from "react";

const NotFoundPage = () => {
  return (
    <h1 className="d-flex flex-column justify-content-center align-items-center gap-3 h-100">
      404 - Page Not Found
    </h1>
  );
};

export default NotFoundPage;
