import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import {
  getDetailWalkingCompetition,
  getWalkingCompetition,
  getWalkingCompetitionHistory,
  getWalkingCourses,
  getWalkingCurrentCompetition,
  getWalkingRounds,
  getWalkingSections,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const CompetitionHistory = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    round: "",
    name: "",
  });

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "roundText", title: "회차" },
    { key: "name", title: "대회명" },
    { key: "course_name", title: "코스명" },
    { key: "section_name", title: "구간" },
    {
      key: "period",
      title: "대회기간",
    },
    {
      key: "created_at",
      title: "등록일시",
    },
    {
      key: "actions",
      title: "상세보기",
    },
  ];

  const [detailData, setDetailData] = useState([]);

  const [rounds, setRounds] = useState([]);

  const getWalkingCompetitionList = () => {
    const startDate = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endDate = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getWalkingCompetition(
      searchParam.round,
      searchParam.name,
      currentPage,
      startDate,
      endDate
    )
      .then((res) => {
        console.log("getWalkingCompetition : ", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getWalkingRoundsList = () => {
    getWalkingRounds()
      .then((res) => {
        console.log("getWalkingRounds : ", res.data);
        setRounds(res.data);
      })
      .catch((err) => console.error(err));
  };

  const editData = {
    ...tableData,
    results: tableData?.results?.map((data) => ({
      ...data,
      roundText: `${data.round}회차`,
      period: `${data.start_date} ~ ${data.end_date}`,
    })),
  };

  const [currentCompetition, setCurrentCompetition] = useState([]);

  // 현재 진행중인 대회 리스트
  const getCurrentCompetition = () => {
    getWalkingCurrentCompetition()
      .then((res) => {
        console.log("getCurrentCompetition : ", res.data);
        setCurrentCompetition(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setCurrentCompetition([]);
      });
  };

  useEffect(() => {
    getWalkingCompetitionList();
    getWalkingRoundsList();
    getCurrentCompetition();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getWalkingCompetitionList();
  }, [currentPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    getWalkingCompetitionList();
  };

  // 상세보기 버튼
  const handleDetail = (data) => {
    console.log("handleDetail id : ", data);
    setShowDetailModal(true);
    // setDetailFormData(data);

    getWalkingCompetitionHistory(data?.id)
      .then((res) => {
        console.log("getWalkingCompetitionHistory : ", res.data);
        setDetailData(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 모달창 내용
  const renderEdit = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 랭킹 히스토리</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} className={"my-auto align-middle"}>
                      <BootstrapTable striped hover responsive>
                        <thead>
                          <tr className={"text-center"}>
                            {[
                              "순위",
                              "구",
                              "경로당명",
                              "ID",
                              "점수",
                              "거리",
                              "시간",
                              "일시",
                            ].map((col, idx) => (
                              <th key={idx}>{col}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {detailData &&
                            detailData?.map((data, idx) => (
                              <tr key={idx} className={"text-center"}>
                                <td>{data.competition}</td>
                                <td>{data.center.district.name}</td>
                                <td>{data.center.name}</td>
                                <td>{data.id || "-"}</td>
                                <td>{data.score || "-"}</td>
                                <td>{data.distance || "-"}</td>
                                <td>{data.duration || "-"}</td>
                                <td>{data.created_at}</td>
                              </tr>
                            ))}

                          {detailData && detailData.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                해당 내역이 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </BootstrapTable>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={8} className="mb-3">
            <Row className="justify-content-start">
              <Col xs={2} className="my-auto">
                <span className="fs-4 fw-bold">| 대회 검색</span>
              </Col>
              <Col xs={6}>
                <Row className="row-gap-2">
                  <Col xs={5} className="">
                    <Form.Select
                      className="h-100 fs-5"
                      value={searchParam.round}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          round: e.target.value,
                        })
                      }
                    >
                      <option value={""}>대회 회차 선택</option>
                      {[...new Set(rounds.map((round) => round.round))].map(
                        (uniqueRound, index) => (
                          <option key={index} value={uniqueRound}>
                            {uniqueRound}회차
                          </option>
                        )
                      )}
                      {/* {rounds.map((round) => (
                        <option key={round.id} value={round.id}>
                          {round.round}
                        </option>
                      ))} */}
                    </Form.Select>
                  </Col>
                  <Col xs={2} className="border rounded text-center p-2 fs-5">
                    대회명
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      placeholder="대회명 입력"
                      className="h-100 fs-5"
                      value={searchParam.name}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Row>
            <DateSearch
              title={"기간 설정"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              daysInMonth={daysInMonth}
            />
            <Col xs={1} className={"text-end ps-0"}>
              <Button
                type={"button"}
                className="w-100 h-100 fs-5 fw-bold"
                onClick={handleSearch}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            minHeight: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={2} className="">
              <span className="fs-4 fw-bold">| 현재 진행중인 대회</span>
            </Col>
            <Col xs={10} className="">
              <BootstrapTable striped hover responsive>
                <thead>
                  <tr className={"text-center"}>
                    {["회차", "대회명", "코스", "구간", "대회 기간"].map(
                      (col, idx) => (
                        <th key={idx}>{col}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentCompetition &&
                    currentCompetition.map((data, idx) => (
                      <tr key={idx} className={"text-center"}>
                        <td>{data.round}회차</td>
                        <td>{data.name}</td>
                        <td>{data.course_name}</td>
                        <td>{data.section_name}</td>
                        <td>{`${data.start_date} ~ ${data.end_date}`}</td>
                      </tr>
                    ))}

                  {currentCompetition.length === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        진행중인 대회가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </BootstrapTable>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 대회 히스토리</span>
            </Col>
            <Col xs={12} className="">
              {tableData ? (
                <CustomTable
                  columns={columns}
                  tableData={editData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        {renderEdit()}
      </CustomModal>
    </>
  );
};

export default CompetitionHistory;
