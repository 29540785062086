import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  getDetailIssue,
  getDistrict,
  getIssue,
  getIssueCategory,
  getIssueDetailLog,
  getIssueLog,
  getRiskCategory,
  getSeniorCenter,
  getSolutionCategory,
  postIssueMessage,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import CustomTable from "../table/CustomTable";
import CustomModal from "../common/CustomModal";
import Info from "../../assets/table/info.svg";
import Warning from "../../assets/table/warning.svg";
import Error from "../../assets/table/error.svg";
import * as XLSX from "xlsx";

const categoryArr = [
  { id: 1, category: "TVS", title: "양방향 화상시스템" },
  { id: 2, category: "lifecare", title: "생활케어" },
  { id: 3, category: "healthcare", title: "헬스케어" },
];

const featureArr = [
  { id: 1, category_id: 1, description: "dev", name: "개발용" },
  {
    id: 2,
    category_id: 1,
    description: "live_streaming",
    name: "라이브 스트리밍",
  },
  { id: 3, category_id: 1, description: "vod", name: "녹화영상" },
  { id: 4, category_id: 1, description: "iptv", name: "IPTV" },
  { id: 5, category_id: 1, description: "weather", name: "날씨 정보" },
  { id: 6, category_id: 1, description: "bus", name: "버스 정보" },
  { id: 7, category_id: 1, description: "message", name: "외부 메시지" },
  { id: 8, category_id: 1, description: "video_call", name: "영상 통화" },
  { id: 9, category_id: 2, description: "walking_machine", name: "워킹머신" },
  { id: 10, category_id: 2, description: "smart_table", name: "스마트 테이블" },
  { id: 11, category_id: 3, description: "blood_pressure", name: "혈압계" },
  { id: 12, category_id: 3, description: "body_temperature", name: "체온계" },
  { id: 13, category_id: 3, description: "weight", name: "체중계" },
  {
    id: 14,
    category_id: 3,
    description: "electrocardiogram",
    name: "심전도계",
  },
];

const IntegratedLog = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "timestamp", title: "일시" },
    { key: "center.center_code", title: "경로당 ID" },
    { key: "center.district", title: "구" },
    { key: "center.name", title: "경로당명" },
    { key: "grade", title: "수준" },
    { key: "actions", title: "상세보기" },
  ];

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    search: "",
    keyword: "",
    category: "",
    feature: "",
    grade: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const [selectedCategory, setSelectedCategory] = useState("");

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [selectedIssue, setSelectedIssue] = useState(null);

  const getIssueLogList = () => {
    if (!searchParam.center) return;

    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getIssueLog(
      currentPage,
      searchParam.local,
      searchParam.center,
      searchParam.search,
      startData,
      endData,
      searchParam.category,
      searchParam.feature,
      searchParam.grade
    )
      .then((res) => {
        console.log("getIssueLogList : ", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getIssueLogList();
  }, []);

  const handleButtonClick = (grade) => {
    setSelectedCategory(grade);

    setSearchParam((prev) => ({
      ...prev,
      grade: grade,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getIssueList();
  };

  useEffect(() => {
    getIssueLogList();
  }, [currentPage, searchParam.grade, searchParam.category]);

  const handleDetail = (data) => {
    setShowDetailModal(true);
    getIssueDetailLog(data?.id)
      .then((res) => {
        console.log("getIssueDetailLog", res.data);
        setSelectedIssue(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (!searchParam.center) {
      alert("경로당을 선택해주세요.");
      return;
    }

    if (!searchParam.category) {
      alert("메뉴를 선택해주세요.");
      return;
    }

    // if (!searchParam.feature) {
    //   alert("카테고리를 선택해주세요.");
    //   return;
    // }

    // setCurrentPage(1);
    getIssueLogList();
  };

  // 선택된 category에 해당하는 feature만 필터링
  const filteredFeatureArr = featureArr.filter(
    (feature) =>
      feature.category_id ===
      (categoryArr.find((c) => c.category === searchParam.category)?.id || null)
  );

  // 엑셀 다운로드
  const handleExcelDownload = () => {
    if (!tableData || tableData.results.length === 0) {
      alert("다운로드할 데이터가 없습니다.");
      return;
    }

    // 엑셀에 저장할 데이터 변환
    const exportData = tableData?.results?.map((data, index) => ({
      번호: data.id,
      일시: data.timestamp,
      "경로당 ID": data.center?.center_code || "",
      구: data.center?.district || "",
      경로당명: data.center?.name || "",
      "로그 상세": data.description,
      수준:
        data.grade === "info"
          ? "정보"
          : data.grade === "warning"
          ? "경고"
          : "에러",
    }));

    // 워크시트 생성
    const ws = XLSX.utils.json_to_sheet(exportData);

    // 엑셀 파일 생성
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "로그 목록");

    const centerName = tableData.results[0]?.center?.name;

    // 파일 다운로드
    XLSX.writeFile(wb, `통합로그(${centerName}).xlsx`);
  };

  // 모달창 내용
  const renderEdit = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <BootstrapTable striped hover responsive>
                      <thead>
                        <tr>
                          {["일시", "경로당 ID", "구", "경로당명", "수준"].map(
                            (col, idx) => (
                              <th key={idx}>{col}</th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="modal-table">
                          <td>{selectedIssue?.timestamp}</td>
                          <td>{selectedIssue?.center?.center_code}</td>
                          <td>{selectedIssue?.center?.district}</td>
                          <td>{selectedIssue?.center?.name}</td>
                          <td>
                            {selectedIssue?.grade === "info" ? (
                              <img src={Info} />
                            ) : selectedIssue?.grade === "warning" ? (
                              <img src={Warning} />
                            ) : (
                              <img src={Error} />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </BootstrapTable>
                  </Row>

                  <Col>
                    <span className="fs-4 fw-bold">| 로그 상세 내용</span>
                  </Col>
                  <div
                    className="px-4 py-3 border rounded justify-content-center"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Row
                      style={{
                        height: "calc(100vh - 50rem)",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <p className="fs-5 text-black">
                        {selectedIssue?.description}
                      </p>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Form className="p-0" onSubmit={handleSearch}>
          <Col
            xs={12}
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            {/* 날짜 검색 */}
            <Row className="mb-2">
              <Col xs={7} className="d-flex gap-2">
                <Col xs={2}>
                  <span className="fs-4 fw-bold">| 날짜 검색</span>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.year}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        year: e.target.value,
                      })
                    }
                  >
                    <option value={""}>년 리스트</option>
                    <option value={2024}>2024년</option>
                    <option value={2025}>2025년</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.month}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        month: e.target.value,
                      })
                    }
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.day}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        day: e.target.value,
                      })
                    }
                  >
                    <option value={""}>일 리스트</option>
                    {daysInMonth.map((day) => (
                      <option key={day} value={day}>
                        {day}일
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col
                  className="border rounded text-center p-2 my-auto align-middle"
                  style={{ maxWidth: "2rem" }}
                >
                  ~
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.year2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        year2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>년 리스트</option>
                    <option value={2024}>2024년</option>
                    <option value={2025}>2025년</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.month2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        month2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>월 리스트</option>
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}월
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.day2}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        day2: e.target.value,
                      })
                    }
                  >
                    <option value={""}>일 리스트</option>
                    {daysInMonth.map((day) => (
                      <option key={day} value={day}>
                        {day}일
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Col>
              <Col className="d-flex justify-content-start align-items-center p-0">
                <p className="text-secondary">
                  ※최근 3개월 로그만 검색 가능합니다.
                </p>
              </Col>
            </Row>

            {/* 경로당 검색 */}
            <Row className=" mb-2">
              <Col xs={7} className="d-flex gap-2">
                <Col xs={2}>
                  <span className="fs-4 fw-bold">| 경로당 검색</span>
                </Col>
                <Col xs={2} className="pe-0">
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.local}
                    onChange={(e) =>
                      setSearchParam({ ...searchParam, local: e.target.value })
                    }
                  >
                    <option value={""}>구</option>
                    {districtList &&
                      districtList.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.center}
                    onChange={handleCenterChange}
                  >
                    <option value={""}>경로당</option>
                    {centerList &&
                      centerList.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                {/* <Col xs={2} className="border rounded text-center p-2 fs-5">
                  경로당명
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.keyword}
                    onChange={handleKeywordChange}
                    disabled={searchParam.center !== ""}
                  />
                </Col> */}
                <Col xs={1} className="border rounded text-center p-2 fs-5">
                  구분
                </Col>
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5 primary-select"
                    aria-label="Default select example"
                    value={searchParam.category}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value={""}>양방향/생활/헬스</option>
                    {categoryArr.map((category) => (
                      <option key={category.id} value={category.category}>
                        {category.title}
                      </option>
                    ))}
                    {/* <option value={""}>양방향/생활/헬스</option>
                    <option value={"1"}>양방향 화상시스템</option>
                    <option value={"2"}>생활케어</option>
                    <option value={"3"}>헬스케어</option> */}
                  </Form.Select>
                </Col>
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5 primary-select"
                    aria-label="Default select example"
                    value={searchParam.feature}
                    onChange={(e) =>
                      setSearchParam({
                        ...searchParam,
                        feature: e.target.value,
                      })
                    }
                    disabled={!searchParam.category}
                  >
                    <option value={""}>카테고리</option>
                    {filteredFeatureArr.map((feature) => (
                      <option key={feature.id} value={feature.description}>
                        {feature.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={1} className="">
                  <Button
                    className="w-100 h-100 fs-5 fw-bold"
                    onClick={handleSearch}
                  >
                    검색
                  </Button>
                </Col>
                <Col xs={6} className="text-end">
                  <Button
                    className="w-0 h-100 px-4 fs-5 fw-bold"
                    onClick={handleExcelDownload}
                  >
                    엑셀
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </Form>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 23rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={12} className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">
                {searchParam.category === "TVS"
                  ? "| 양방향 화상시스템 로그"
                  : searchParam.category === "lifecare"
                  ? "| 생활케어 로그"
                  : searchParam.category === "healthcare"
                  ? "| 헬스케어 로그"
                  : "| 로그 목록"}
              </span>
            </Col>

            <Col xs={7} className="d-flex gap-2 mb-2">
              <Col xs={1} className="border rounded text-center p-2 fs-5">
                수준
              </Col>
              {[
                { type: "전체", grade: "" },
                { type: "정보", grade: "info" },
                { type: "경고", grade: "warning" },
                { type: "에러", grade: "error" },
              ].map((category, idx) => (
                <Col xs={1} className="pe-0" key={idx}>
                  <Button
                    className={`w-100 h-100 btn-outline-primary fs-5 ${
                      searchParam.center &&
                      searchParam.category &&
                      selectedCategory === category.grade
                        ? "bg-primary text-black"
                        : ""
                    }`}
                    style={{ color: "#fff" }}
                    onClick={() => handleButtonClick(category.grade)}
                  >
                    {category.type}
                  </Button>
                </Col>
              ))}
            </Col>

            {searchParam.center && searchParam.category ? (
              <Col xs={12} className="">
                {tableData ? (
                  <CustomTable
                    columns={columns}
                    tableData={tableData}
                    handleDetail={handleDetail}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            ) : (
              <Col xs={12} className="w-100 text-center">
                <BootstrapTable striped hover responsive>
                  <thead>
                    <tr>
                      {[
                        "번호",
                        "일시",
                        "경로당 ID",
                        "구",
                        "경로당명",
                        "수준",
                      ].map((col, idx) => (
                        <th key={idx}>{col}</th>
                      ))}
                    </tr>
                  </thead>
                </BootstrapTable>
                <p className="fs-5 mt-5">검색 조건을 설정해주세요.</p>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default IntegratedLog;
