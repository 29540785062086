import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import Frame from "../components/common/Frame";
import BackgroundImg from "../assets/landing/bg.png";
import LogoSvg from "../assets/common/logo.svg";
import "./MessageSend.scss";
import ImgIconSvg from "../assets/message/icon-img.svg";
import axios from "axios";
import { getTokenVerify, postMessage } from "../api/apiClient";
import { error } from "highcharts";
import { Filter } from "bad-words";
import { badWords } from "../data/badWords";
import { use } from "react";
import { useRef } from "react";

const MessageSend = () => {
  const [messageFormData, setMessageFormData] = useState({
    title: "",
    content: "",
    centerId: "",
  });
  const [file, setFile] = useState({});

  const [userInfo, setUserInfo] = useState(null);

  const [isBlocked, setIsBlocked] = useState(false);
  const messageLog = useRef([]);

  const getUserInfo = () => {
    getTokenVerify().then((res) => {
      console.log("user", res.data);
      setUserInfo(res.data);
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // 파일 업로드
  const fileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imageType = file.type.includes("image");
    const videoType = file.type.includes("video");

    setFile({
      url: URL.createObjectURL(file),
      image: imageType,
      video: videoType,
    });

    setMessageFormData((prev) => ({
      ...prev,
      media: file,
    }));
  };

  // 입력 필드
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 욕설 필터링
  const checkProfanityWord = (word) => {
    const filter = new Filter();

    filter.addWords(...badWords);

    // `bad-words`의 `isProfane()` + 직접 한국어 필터링 적용
    return (
      filter.isProfane(word) ||
      badWords.some((_word) => (_word ? word.includes(_word) : false))
    );
  };

  // 5분 이내 동일한 메시지 3번 이상 발송 시 10분 차단
  const checkDuplicateMessage = (sender) => {
    const now = Date.now();
    const fiveMinutes = 5 * 60 * 1000; // 5분
    const tenMinutes = 10 * 60 * 1000; // 10분
    const thirtySeconds = 30 * 1000; // 차단 시간 30초
    const maxAttempts = 3;

    let messageLog = JSON.parse(localStorage.getItem("messageLog")) || [];

    // 5분이 지난 메시지 삭제
    messageLog = messageLog.filter((msg) => now - msg.timestamp <= fiveMinutes);

    // 5분 내 동일한 발신자 메시지 개수 확인
    const recentMessages = messageLog.filter((msg) => msg.sender === sender);

    console.log("현재 메시지 로그:", messageLog);
    console.log("5분 내 동일 발신자 메시지 개수:", recentMessages.length);

    if (recentMessages.length >= maxAttempts - 1) {
      setIsBlocked(true);
      console.warn("10분 동안 메시지를 보낼 수 없습니다.");

      // 차단 상태를 localStorage에 저장
      localStorage.setItem(
        "isBlocked",
        JSON.stringify({ blocked: true, timestamp: now })
      );

      // 30초 후 차단 해제
      setTimeout(() => {
        setIsBlocked(false);
        localStorage.removeItem("isBlocked"); // 차단 해제 시 localStorage에서 제거
      }, tenMinutes);

      return true;
    }

    // 새로운 메시지 추가 후 localStorage에 저장
    messageLog.push({ sender, timestamp: now });
    localStorage.setItem("messageLog", JSON.stringify(messageLog));
    console.log("새로운 메시지 추가됨:", { sender, timestamp: now });

    return false;
  };

  useEffect(() => {
    const storedBlock = JSON.parse(localStorage.getItem("isBlocked"));
    if (storedBlock && Date.now() - storedBlock.timestamp < 10 * 60 * 1000) {
      setIsBlocked(true);

      setTimeout(() => {
        setIsBlocked(false);
        localStorage.removeItem("isBlocked");
      }, 10 * 60 * 1000 - (Date.now() - storedBlock.timestamp));
    }
  }, []);

  console.log("messageLog", messageLog);

  // submit
  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    // console.log("messageFormData :", messageFormData);
    // console.log("file :", file);

    if (
      checkProfanityWord(messageFormData.title) ||
      checkProfanityWord(messageFormData.content)
    ) {
      alert("욕설이 포함된 내용은 발송할 수 없습니다.");
      return;
    }

    if (checkDuplicateMessage(userInfo?.username)) {
      alert("5분 이내 3번 이상 발송하여 10분간 차단되었습니다.");
      return;
    }

    // 입력값 검증
    const { title, content } = messageFormData;
    if (!title || !content) {
      alert("모든 필수 입력란을 채워주세요. (Media 파일 제외)");
      return;
    }

    // FormData 생성
    const formData = new FormData();
    formData.append("title", messageFormData.title);
    formData.append("content", messageFormData.content);

    if (file) {
      formData.append("media", file);
      // formData.append("media", file, file.name);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    console.log("messageFormData", messageFormData);
    /*for (let [key, value] of messageFormData.entries()) {
      console.log(`${key}: ${value}`);
    }*/

    postMessage(messageFormData)
      .then((res) => {
        console.log(res);
        alert("발송 완료");
        //   formdata 초기화
        setMessageFormData({
          title: "",
          content: "",
        });
        setFile({});
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Frame
      title={"스마트경로당 메시지"}
      showTabMenu={false}
      className={"my-auto"}
    >
      <Form
        className="d-flex flex-column justify-content-center gap-3 p-4"
        style={{
          border: "1px solid #868686",
          borderRadius: "12px",
          backgroundColor: "#1D222A",
        }}
        onSubmit={handleMessageSubmit}
      >
        {/* 경로당 / 받는사람 / 보내기 버튼 */}
        <Row className="justify-content-between row-gap-3">
          <Col xs={4} className="d-flex">
            <Col xl={5} sm={12} className="fs-4 fw-bold border-end text-center">
              {/* <Form.Control
              className="w-100"
              name="center"
              value={messageFormData.center}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="경로당 명을 입력하세요"
            /> */}
              {userInfo?.senior_center?.name}
            </Col>
            <Col
              xl={5}
              sm={12}
              className="d-flex justify-content-center text-center"
            >
              {/* <Form.Control
              className="w-100"
              name="receiver"
              value={messageFormData.receiver}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="받는 사람을 입력하세요"
            /> */}
              <p className="fs-4 fw-bold">받는 어르신 : </p>
              <p className="fs-4 fw-bold ms-2"> {userInfo?.senior_name}</p>
            </Col>
          </Col>
          <Col xl={4} sm={12} className="d-flex justify-content-end">
            {/* <Form.Control
              className="w-100"
              name="author"
              value={messageFormData.author}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="보내는 사람을 입력하세요"
            /> */}
            <p className="fs-4 fw-bold">발신자 : </p>
            <p className="fs-4 fw-bold ms-2">{userInfo?.username}</p>
          </Col>
          {/* submit */}
          {/* <Col xs={4} className="text-end pc-btn">
              <Button type="submit">보내기</Button>
            </Col> */}
        </Row>

        {/* 메시지 제목 입력 */}
        <Row>
          <Col>
            <Form.Control
              name="title"
              value={messageFormData.title}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="메시지 제목을 입력하세요"
            />
          </Col>
        </Row>

        {/* 파일 업로드 */}
        <Row className="m-0">
          <Col className="btn-upload">
            <label htmlFor="file" className="">
              <img src={ImgIconSvg} alt="img-icon" />
              <span>이미지, 영상 업로드</span>
            </label>
            <input
              type="file"
              name="file"
              id="file"
              className="custom-box"
              onChange={fileUpload}
            />
          </Col>
        </Row>

        {/* 파일 미리보기 및 메시지 텍스트 입력 */}
        <Row>
          <Col
            xs={12}
            xl={6}
            style={{
              backgroundColor: "#fff",
              border: "1px solid #868686",
              borderRadius: "0.5rem",
              padding: "0.7rem 1rem",
            }}
          >
            {file.image ? (
              <img
                src={file.url}
                alt="preview"
                className="d-block w-100"
                style={
                  {
                    // width: "100%",
                    // height: "auto",
                  }
                }
              />
            ) : file.video ? (
              <video
                src={file.url}
                controls
                className="d-block w-100"
                style={
                  {
                    // width: "100%",
                    // height: "auto",
                  }
                }
              />
            ) : (
              <span className={"fs-1 text-black"}>Preview</span>
            )}
          </Col>

          {/*{file.video && <video src={file.url} controls width="350px"/>}*/}

          <Col xs={12} xl={6}>
            {/*<input
                    as="textarea"
                    name="content"
                    value={messageFormData.content}
                    onChange={handleChange}
                    className="border-0 fs-5 py-2 img-box w-100"
                    size="lg"
                    rows={10}
                    placeholder="메시지 내용을 입력하세요"
                />*/}
            <textarea
              name="content"
              value={messageFormData.content}
              onChange={handleChange}
              className="border-0 fs-5 py-2 img-box w-100"
              rows={10}
              placeholder="메시지 내용을 입력하세요"
            />
          </Col>
        </Row>

        <Col className="text-center">
          <Button className="w-100" type="submit">
            발송
          </Button>
        </Col>
      </Form>
    </Frame>
  );
};

export default MessageSend;
