import { SWAGGER_BASE_URL, SWAGGER_FILE_URL } from "../config";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

/*const getToken = () => {
  return window.localStorage.getItem("access");
};

const token = getToken();*/

const axiosClient = axios.create({
  baseURL: SWAGGER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosClientFormData = axios.create({
  baseURL: `${SWAGGER_BASE_URL}`,
  timeout: 10000,
});

axiosClientFormData.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 파일 업로드용 axios
const axiosContents = axios.create({
  baseURL: SWAGGER_FILE_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

axiosContents.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosContentsFormData = axios.create({
  baseURL: `${SWAGGER_FILE_URL}`,
  timeout: 10000,
});

axiosContentsFormData.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const axiosContentsPostFormData = (url, formData) => {
  let ret;
  ret = axiosContentsFormData.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

// 500 502 에러 페이지 이동
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        // error.response.status === 404 ||
        error.response.status === 500 ||
        error.response.status === 502
      ) {
        window.location.href = "/error";
      }
    }
    return Promise.reject(error);
  }
);

axiosClientFormData.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        // error.response.status === 404 ||
        error.response.status === 500 ||
        error.response.status === 502
      ) {
        window.location.href = "/error";
      }
    }
    return Promise.reject(error);
  }
);

// 로그인 (JWT 토큰 발급)
export const postLogin = (data) => {
  try {
    return axiosClient.post("/jwt/token/", data);
  } catch (e) {
    console.error(e);
  }
};

// JWT 토큰 검증
export const getTokenVerify = () => {
  try {
    return axiosClient.get("/jwt/verify/");
  } catch (e) {
    console.error(e);
  }
};

const axiosClientPostFormData = (url, formData) => {
  const token = window.localStorage.getItem("access");
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

const axiosExternalPostFormData = (url, formData) => {
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

// 공통
// 행정구 리스트 조회
export const getDistricts = () => {
  try {
    return axiosClient.get("/district/");
  } catch (e) {
    console.error(e);
  }
};

// 경로당 단순 리스트 조회 (경로당 찾기)
export const getCenters = (district_id, search) => {
  try {
    return axiosClient.get(
      `/senior-center/?district_id=${district_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

export const getCentersLength = () => {
  try {
    return axiosClient.get(`/senior-center/`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 등록
export const postNotice = (formData) => {
  try {
    return axiosClient.post("/notice/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 회원가입
export const postAccountsUserRequest = (data) => {
  try {
    return axiosExternalPostFormData("/accounts/register/external/", data);
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 아이디 중복확인
export const getCheckDuplicate = (username) => {
  try {
    return axiosClient.get(
      `/accounts/external/users/check-duplicate?username=${username}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 메시지 보내기
export const postMessage = (formData) => {
  try {
    return axiosClientPostFormData("/message/send/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 리스트 조회
export const getNotice = (page, category, search) => {
  try {
    return axiosClient.get(
      `/notice/?page=${page}&category=${category}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 카테고리
export const getNoticeCategory = () => {
  try {
    return axiosClient.get(`/notice/category/`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 리스트 조회
export const getTemplate = () => {
  try {
    return axiosClient.get(`/notice/template/`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 리스트 조회
export const getNoticeTemplate = (page, search, category) => {
  try {
    return axiosClient.get(
      `/notice/template/?page=${page}&search=${search}&category=${category}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 등록
export const postNoticeTemplate = (formData) => {
  try {
    return axiosClient.post("/notice/template/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 상세 조회
export const getNoticeDetailTemplate = (id) => {
  try {
    return axiosClient.get(`/notice/template/${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 수정
export const putNoticeTemplate = (id, formData) => {
  try {
    return axiosClient.put(`/notice/template/${id}/`, formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 삭제
export const deleteNoticeDetailTemplate = (id) => {
  try {
    return axiosClient.delete(`/notice/template/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상시스템 운영관리

// 편성 카테고리 조회 (편성표 카테고리 목록 조회)
export const getTvsScheduleCategory = () => {
  try {
    return axiosClient.get("/tvs/schedule-categories/");
  } catch (e) {
    console.error(e);
  }
};

// 영상 카테고리 조회 (VOD 카테고리 조회)
export const getTvsVodCategory = () => {
  try {
    return axiosClient.get(`/tvs/vod-categories/`);
  } catch (e) {
    console.error(e);
  }
};

// 클라이언트 편성 정보 조회
export const getProgramSchedule = () => {
  try {
    return axiosClient.get(`/tvs/schedule/client/`);
  } catch (e) {
    console.error(e);
  }
};

// 실시간 모니터링
// 종합 대시보드
export const getStatisticsDashboard = () => {
  try {
    return axiosClient.get(`/statistics/dashboard/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 현황
export const getStatisticsCenters = (page) => {
  try {
    return axiosClient.get(`/statistics/centers/?page=${page}`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 현황 - 상세보기
export const getStatisticsCenterDetail = (center_id) => {
  try {
    return axiosClient.get(`/statistics/center/${center_id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 시스템별 이용현황
export const getStatisticsTvsCenter = (district, center_id, search) => {
  try {
    return axiosClient.get(
      `/statistics/tvs/center/?district=${district}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 발생 현황
export const getStatisticsIssue = (district_id, center_id, search) => {
  try {
    return axiosClient.get(
      `/statistics/issue/center/?district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (월)
export const getTvsSchedulesCalendar = (year, month, category, resolution) => {
  try {
    return axiosClient.get(
      `/tvs/schedules/calendar/?year=${year}&month=${month}&schedule_category_id=${category}&resolution=${resolution}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (일)
export const getTvsSchedulesDay = (date) => {
  try {
    return axiosClient.get(`/tvs/schedules/?date=${date}`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (상세)
export const getTvsScheduleDetail = (id) => {
  try {
    return axiosClient.get(`/tvs/schedules/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 방송 카테고리 목록 조회
export const getTvsBroadcastCategory = () => {
  try {
    return axiosClient.get(`/tvs/schedule-categories/`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 등록
export const postTvsSchedule = (data) => {
  try {
    // return axiosClient.post("/tvs/schedules/", data);
    return axiosClientFormData.post("/tvs/schedules/", data);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 수정
export const putTvsSchedule = (id, data) => {
  // formdata 로그
  for (let [key, value] of data.entries()) {
    console.log(`${key}: ${value}`);
  }

  try {
    return axiosClientFormData.put(`/tvs/schedules/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};
// 편성표 관리 - 스케줄 삭제
export const deleteTvsSchedule = (id) => {
  try {
    return axiosClient.delete(`/tvs/schedules/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 화상 시스템 운영 관리 리스트 조회
export const getVideoCallList = (page, center) => {
  try {
    return axiosClient.get(
      `/jitsi/seniorvideocall/?page=${page}&center=${center}`
    );
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 리스트 조회
export const getTvsVod = (category, search, page) => {
  try {
    return axiosClient.get(
      `/tvs/vod/?category=${category}&search=${search}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 등록
export const postTvsVod = (data) => {
  try {
    return axiosClientFormData.post("/tvs/vod/", data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 수정
export const putTvsVod = (id, data) => {
  try {
    return axiosClientFormData.put(`/tvs/vod/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 삭제
export const deleteTvsVod = (id) => {
  try {
    return axiosClient.delete(`/tvs/vod/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 상세 조회
export const getTvsVodDetail = (id) => {
  try {
    return axiosClient.get(`/tvs/vod/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS 영상 파일 업로드
// CMS - 영상 조회
export const getContentsFile = (category, search, page) => {
  try {
    return axiosContents.get(
      `/contents/?category=${category}&search=${search}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// CMS - 영상 상세 조회
export const getContentsFileDetail = (id) => {
  try {
    return axiosContents.get(`/contents/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - 업로드 프로그레스바
export const getProgressBar = (id) => {
  try {
    return axiosContents.get(`/contents/${id}/progress/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - 영상 등록
export const postContentsFile = (data) => {
  try {
    return axiosContentsFormData.post("/contents/", data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - 파일 업로드
export const postFileUpload = (id, data) => {
  try {
    return axiosContentsPostFormData(`/contents/${id}/upload/`, data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - 파일 수정
export const putFileUpload = (id, data) => {
  try {
    return axiosContentsFormData.patch(`/contents/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - 파일 삭제
export const deleteContentFile = (id) => {
  try {
    return axiosContents.delete(`/contents/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 편의 기능 관리
// 날씨 정보 안내 관리
// 날씨 정보 안내 - 상태 확인
export const getWeatherStatus = () => {
  try {
    return axiosClient.get("/tvs/features/?feature=weather");
  } catch (e) {
    console.error(e);
  }
};

// 날씨 정보 안내 - 상태 변경
export const putWeatherStatus = (data) => {
  try {
    return axiosClient.put("/tvs/features/", data);
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 안내 관리 - 상태 확인
export const getBusStatus = () => {
  try {
    return axiosClient.get("/tvs/features/?feature=bus");
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 안내 - 상태 변경
export const putBusStatus = (data) => {
  try {
    return axiosClient.put("/tvs/features/", data);
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 안내 관리 - 경로당 목록 조회
export const getCenterList = (page, district_id, center_id, search) => {
  try {
    return axiosClient.get(
      `/accounts/senior-center/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 안내 관리 - 경로당 상세 조회
export const getCenterDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/senior-center/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 버스 정류장 검색
export const getBusStop = (search) => {
  try {
    return axiosClient.get(`/tvs/bus-stations/?search=${search}`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인 요청 리스트
export const getAccountsUserRequest = (
  page,
  district_id,
  center_id,
  search
) => {
  try {
    return axiosClient.get(
      `/accounts/external/users/requests/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 수락 리스트
export const getAccountsUserAccept = (page, district_id, center_id, search) => {
  try {
    return axiosClient.get(
      `/accounts/external/users/approved/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 유저 상세 정보 조회
export const getAccountsUserDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/external/users/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인
export const putAccountsUserAccept = (id, data) => {
  try {
    return axiosClient.put(`/accounts/external/users/${id}/status/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 거절
export const deleteAccountsUserReject = (id) => {
  try {
    return axiosClient.delete(`/accounts/external/users/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인 취소
export const putAccountsUserCancel = (id, data) => {
  try {
    return axiosClient.put(`/accounts/external/users/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 이용량
// 양방향 화상 시스템 - 이용량 - 양방향 화상 시스템 사용 통계
export const getStatisticsTvs = (
  district,
  center_name,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/statistics/tvs/?district=${district}&center_name=${center_name}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - 실시간 방송 이용 랭킹
export const getStatisticsTvsRankingLive = (
  page,
  start_date,
  end_date,
  order_by
) => {
  try {
    return axiosClient.get(
      `/statistics/tvs/ranking/live/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - VOD 이용시간 랭킹
export const getStatisticsTvsRankingVod = (
  page,
  start_date,
  end_date,
  order_by
) => {
  try {
    return axiosClient.get(
      `/statistics/tvs/ranking/vod/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - IPTV 이용시간 랭킹
export const getStatisticsTvsRankingIptv = (
  page,
  start_date,
  end_date,
  order_by
) => {
  try {
    return axiosClient.get(
      `/statistics/tvs/ranking/iptv/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 사용자 관리 (관리자 관리)
// 관리자 로그 관리 - 직책 리스트 조회
export const getAuthorities = () => {
  try {
    return axiosClient.get(`/authorities/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 로그 관리 - 카테고리 리스트 조회
export const getAccountsLogCategory = () => {
  try {
    return axiosClient.get(`/accounts/log/category/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 로그 관리 - 리스트 조회
export const getAccountsLog = (
  page,
  search,
  start_date,
  end_date,
  category_id,
  authority_id
) => {
  try {
    return axiosClient.get(
      `/accounts/log/?page=${page}&search=${search}&start_date=${start_date}&end_date=${end_date}&category_id=${category_id}&authority_id=${authority_id}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 리스트 조회
export const getAccountsUserStaff = (page, search, authority) => {
  try {
    return axiosClient.get(
      `/accounts/user/staff/?page=${page}&search=${search}&authority=${authority}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 상세 조회
export const getAccountsUserStaffDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/user/staff/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 등록
export const postAccountsUserStaff = (data) => {
  try {
    return axiosClient.post("/accounts/register/staff/", data);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 수정
export const putAccountsUserStaff = (id, data) => {
  try {
    return axiosClient.put(`/accounts/user/staff/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 삭제
export const deleteAccountsUserStaff = (id) => {
  try {
    return axiosClient.delete(`/accounts/user/staff/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 직책 상세 조회
export const getAccountsAuthority = (authorityId) => {
  try {
    return axiosClient.get(`/accounts/authority/${authorityId}/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 리스트 조회
export const getAccountsCenter = (
  page,
  district_id,
  center_id,
  search,
  center_code
) => {
  try {
    return axiosClient.get(
      `/accounts/senior-center/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}&center_code=${center_code}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 생성
export const postAccountsCenter = (data) => {
  try {
    return axiosClient.post(`/accounts/senior-center/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 수정
export const putAccountsCenter = (id, data) => {
  try {
    return axiosClient.put(`/accounts/senior-center/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 삭제
export const deleteAccountsCenter = (id, delete_type) => {
  try {
    return axiosClient.delete(
      `/accounts/senior-center/${id}/?delete_type=${delete_type}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 헬스&생활케어 계정 정보 리스트 조회
export const getAccountsCare = (
  page,
  district_id,
  center_id,
  search_center,
  search_username
) => {
  try {
    return axiosClient.get(
      `/accounts/health-life-care-account/?page=${page}&district_id=${district_id}&center_id=${center_id}&search_center=${search_center}&search_username=${search_username}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 헬스&생활케어 계정 정보 상세 조회
export const getAccountsCareDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/health-life-care-account/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 헬스&생활케어 계정 정보 생성
export const postAccountsCare = (data) => {
  try {
    return axiosClient.post(`/accounts/health-life-care-account/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 헬스&생활케어 계정 정보 수정
export const putAccountsCare = (id, data) => {
  try {
    return axiosClient.put(`/accounts/health-life-care-account/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 헬스&생활케어 계정 정보 삭제
export const deleteAccountsCare = (id) => {
  try {
    return axiosClient.delete(`/accounts/health-life-care-account/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 메뉴 리스트 조회
export const getMainMenu = () => {
  try {
    return axiosClient.get(`/accounts/menu/main/`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 메뉴 수정
export const putMainMenu = (data) => {
  try {
    return axiosClient.put(`/accounts/menu/main/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 서브 메뉴 리스트 조회
export const getSubMenu = (id) => {
  try {
    return axiosClient.get(`/accounts/menu/main/${id}/sub/`);
  } catch (e) {
    console.error(e);
  }
};

// 서브 메뉴 수정
export const putSubMenu = (id, data) => {
  try {
    return axiosClient.put(`/accounts/menu/main/${id}/sub/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 행정구 리스트 조회
export const getDistrict = () => {
  try {
    return axiosClient.get(`/district/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 단순 리스트 조회
export const getSeniorCenter = (district_id) => {
  try {
    return axiosClient.get(`/senior-center/?district_id=${district_id}`);
  } catch (e) {
    console.error(e);
  }
};

// 양방향 - 셋톱박스 조회
// 실시간 모니터링 - 셋톱박스 동작 현황
export const getSetTopBox = (page, district, center, search) => {
  try {
    return axiosClient.get(
      `/tvs/mini-pcs/?page=${page}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 - 셋톱박스 상세 조회
// 실시간 모니터링 - 셋톱박스 동작 현황 - 상세보기
export const getSetTopBoxId = (id) => {
  try {
    return axiosClient.get(`/tvs/mini-pcs/${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 셋톱박스 수정
export const putSetTopBox = (id, data) => {
  try {
    return axiosClient.put(`/tvs/mini-pcs/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 셋톱박스 삭제
export const deleteSetTopBox = (id) => {
  try {
    return axiosClient.delete(`/tvs/mini-pcs/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 리스트 조회
export const getAuthority = (page) => {
  try {
    return axiosClient.get(`/accounts/authority/?page=${page}`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 상세 조회
export const getDetailAuthority = (id) => {
  try {
    return axiosClient.get(`/accounts/authority/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 추가
export const postAuthority = (data) => {
  try {
    return axiosClient.post(`/accounts/authority/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 직책 수정
export const putAuthority = (id, data) => {
  try {
    return axiosClient.put(`/accounts/authority/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 직책 삭제
export const deleteAuthority = (id) => {
  try {
    return axiosClient.delete(`/accounts/authority/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 이슈 사항 리스트 조회
export const getIssue = (
  page,
  search,
  district_id,
  senior_center_id,
  senior_search,
  risk_level,
  category,
  is_resolved,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/issues/?page=${page}&search=${search}&district_id=${district_id}&senior_center_id=${senior_center_id}&senior_search=${senior_search}&risk_level=${risk_level}&category=${category}&is_resolved=${is_resolved}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 이슈 사항 상세 조회
export const getDetailIssue = (id) => {
  try {
    return axiosClient.get(`/issues/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 이슈 카테고리 리스트 조회
export const getIssueCategory = () => {
  try {
    return axiosClient.get(`/issues/category/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 위험도 리스트 조회
export const getRiskCategory = () => {
  try {
    return axiosClient.get(`/issues/risk-level/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 처리방법 리스트 조회
export const getSolutionCategory = () => {
  try {
    return axiosClient.get(`/issues/solution/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 시나리오 리스트 조회
export const getScenario = () => {
  try {
    return axiosClient.get(`/issues/scenarios/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 시나리오 상세 조회
export const getDetailScenario = (id) => {
  try {
    return axiosClient.get(`/issues/scenarios/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 시나리오 수정
export const putScenario = (id, data) => {
  try {
    return axiosClient.put(`/issues/scenarios/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 이슈 사항 메세지 작성
export const postIssueMessage = (id, data) => {
  try {
    return axiosClient.post(`/issues/${id}/message/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 이슈 통계 조회
export const getIssueStatistics = (
  district_id,
  senior_center_id,
  search,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/issues/statistics/?district_id=${district_id}&senior_center_id=${senior_center_id}&search=${search}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 관리 - 통합 로그 조회
export const getIssueLog = (
  page,
  district_id,
  center_id,
  search,
  start_date,
  end_date,
  feature_category,
  feature,
  grade
) => {
  try {
    return axiosClient.get(
      `/issues/logs/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}&start_date=${start_date}&end_date=${end_date}&feature_category=${feature_category}&feature=${feature}&grade=${grade}`
    );
  } catch (e) {
    console.error(e);
  }
};

export const getIssueDetailLog = (id) => {
  try {
    return axiosClient.get(`/issues/logs/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 설정관리 - 조직관리 리스트 조회
export const getOrganization = () => {
  try {
    return axiosClient.get(`/accounts/organization/`);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 시스템 운영 관리
// 워킹머신 대회 데이터 관리 리스트 조회
// 워킹머신 대회 히스토리 리스트 조회
export const getWalkingCompetition = (
  round,
  name,
  page,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/lifecare/walking/competition/?round=${round}&name=${name}&page=${page}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 회차 리스트 (검색용)
export const getWalkingRounds = () => {
  try {
    // return axiosClient.get(`/lifecare/walking/rounds/`);
    return axiosClient.get(`/lifecare/walking/competition/rounds/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 생성
export const postWalkingCompetition = (data) => {
  try {
    return axiosClient.post(`/lifecare/walking/competition/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 상세 조회
export const getDetailWalkingCompetition = (id) => {
  try {
    return axiosClient.get(`/lifecare/walking/competition/${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 상세 수정
export const putDetailWalkingCompetition = (id, data) => {
  try {
    return axiosClient.put(`/lifecare/walking/competition/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 삭제
export const deleteWalkingCompetition = (id) => {
  try {
    return axiosClient.delete(`/lifecare/walking/competition/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 현재 진행 중인 대회 조회
export const getWalkingCurrentCompetition = () => {
  try {
    return axiosClient.get(`/lifecare/walking/competition/active/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대회 히스토리 리스트 조회
export const getWalkingCompetitionHistory = (id) => {
  try {
    return axiosClient.get(`/lifecare/walking/competition/${id}/history/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 고스트 데이터 리스트 조회
export const getWalkingGhost = (section) => {
  try {
    return axiosClient.get(`/lifecare/walking/ghost/?section=${section}`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 고스트 데이터 상세 조회
export const getWalkingGhostDetail = (id) => {
  try {
    return axiosClient.get(`/lifecare/walking/ghost/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 고스트 데이터 수정
export const putWalkingGhost = (id, data) => {
  try {
    return axiosClient.put(`/lifecare/walking/ghost/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 고스트 데이터 삭제
export const deleteWalkingGhost = (id) => {
  try {
    return axiosClient.delete(`/lifecare/walking/ghost/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 사용자 데이터 리스트 조회
export const getWalkingUser = (
  section,
  district,
  center,
  search_center,
  search_username,
  is_competition,
  page
) => {
  try {
    return axiosClient.get(
      `/lifecare/walking/users/data/?section=${section}&district=${district}&center=${center}&search_center=${search_center}&search_username=${search_username}&is_competition=${is_competition}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 대전 데이터관리 사용자 데이터 삭제
export const deleteWalkingUser = (id) => {
  try {
    return axiosClient.delete(`/lifecare/walking/users/data/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 워킹머신 코스별 랭킹 조회

// 스마트테이블 콘텐츠 리스트 (검색용)
export const getSmartTableContent = () => {
  try {
    return axiosClient.get(`/lifecare/table/contents/`);
  } catch (e) {
    console.error(e);
  }
};

// 스마트테이블 콘텐츠별 랭킹 조회
export const getSmartTableRanking = (
  content,
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `lifecare/table/ranking/?content=${content}&start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 기기 동작 현황 리스트 조회
export const getLifeCareDevice = (
  page,
  district,
  center,
  search,
  device_type
) => {
  try {
    return axiosClient.get(
      `/lifecare/devices/?page=${page}&district=${district}&center=${center}&search=${search}&device_type=${device_type}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 기기 동작 현황 상세 조회
export const getLifeCareDeviceDetail = (id) => {
  try {
    return axiosClient.get(`/lifecare/device/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 기기 동작 현황 상세 수정
export const putLifeCareDevice = (id, data) => {
  try {
    return axiosClient.put(`/lifecare/device/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 기기 동작 현황 삭제
export const deleteLifeCareDevice = (id) => {
  try {
    return axiosClient.delete(`/lifecare/device/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 시스템 운영관리 - 워킹머신 랭킹
export const getLifeCareRanking = (
  course,
  section,
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `/lifecare/walking/ranking/?course=${course}&section=${section}&start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 시스템 운영관리 - 워킹머신 코스 리스트
export const getWalkingCourses = () => {
  try {
    return axiosClient.get(`/lifecare/walking/courses/`);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 시스템 운영관리 - 워킹머신 구간 리스트
export const getWalkingSections = (id) => {
  try {
    // return axiosClient.get(`/lifecare/walking/sections/`);
    return axiosClient.get(`/lifecare/walking/courses/${id}/sections/`);
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 시스템 이용량 통계
export const getLifeCareStatistics = (
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `/lifecare/lifecare-system-statistics/?start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 이용량 리스트
export const getLifeCareTotal = (
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `/lifecare/centers/total/?start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 생활케어 기기 on/off 현황
export const getLifeCareDeviceOnOff = () => {
  try {
    return axiosClient.get(`/lifecare/devices/status/`);
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 시스템 운영관리
// 혈압 통계
export const getBloodPressure = (
  district,
  center,
  search,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/healthcare/blood-pressure-statistics/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 혈압 측정 현황 상세 조회
export const getDetailBloodPressure = (id) => {
  try {
    return axiosClient.get(`/healthcare/center/${id}/blood-pressure/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 혈압 측정 현황 리스트
export const getCentersBloodPressure = (
  district,
  center,
  search,
  start_date,
  end_date,
  page
) => {
  try {
    return axiosClient.get(
      `/healthcare/centers/blood-pressure/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 체온 통계
export const getTemperature = (
  district,
  center,
  search,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/healthcare/body-temperature-statistics/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 체온 측정 현황 상세 조회
export const getDetailTemperature = (id) => {
  try {
    return axiosClient.get(`/healthcare/center/${id}/body-temperature/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 체온 측정 현황 리스트
export const getCentersTemperature = (
  district,
  center,
  search,
  start_date,
  end_date,
  page
) => {
  try {
    return axiosClient.get(
      `/healthcare/centers/body-temperature/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 체중 통계
export const getWeight = (district, center, search, start_date, end_date) => {
  try {
    return axiosClient.get(
      `/healthcare/weight-statistics/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 체중 측정 현황 상세 조회
export const getDetailWeight = (id) => {
  try {
    return axiosClient.get(`/healthcare/center/${id}/weight/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 체중 측정 현황 리스트
export const getCentersWeight = (
  district,
  center,
  search,
  start_date,
  end_date,
  page
) => {
  try {
    return axiosClient.get(
      `/healthcare/centers/weight/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 심전도 통계
export const getElectrocardiogram = (
  district,
  center,
  search,
  start_date,
  end_date
) => {
  try {
    return axiosClient.get(
      `/healthcare/electrocardiogram-statistics/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 심전도 측정 현황 상세 조회
export const getDetailElectrocardiogram = (id) => {
  try {
    return axiosClient.get(`/healthcare/center/${id}/electrocardiogram/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 심전도 측정 현황 리스트
export const getCentersElectrocardiogram = (
  district,
  center,
  search,
  start_date,
  end_date,
  page
) => {
  try {
    return axiosClient.get(
      `/healthcare/centers/electrocardiogram/?district=${district}&center=${center}&search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 기기 동작 현황
export const getHealthCareDevice = (
  page,
  district,
  center,
  search,
  device_type
) => {
  try {
    return axiosClient.get(
      `/healthcare/devices/?page=${page}&district=${district}&center=${center}&search=${search}&device_type=${device_type}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 기기 상세 조회
export const getHealthCareDeviceDetail = (id) => {
  try {
    return axiosClient.get(`/healthcare/device/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 기기 정보 수정
export const putHealthCareDevice = (id, data) => {
  try {
    return axiosClient.put(`/healthcare/device/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 기기 삭제
export const deleteHealthCareDevice = (id) => {
  try {
    return axiosClient.delete(`/healthcare/device/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 시스템 이용량
export const getHealthCareStatistics = (
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `/healthcare/healthcare-system-statistics/?start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 헬스케어 기기 on/off 현황
export const getHealthCareDeviceOnOff = () => {
  try {
    return axiosClient.get(`/healthcare/devices/status/`);
  } catch (e) {
    console.error(e);
  }
};

// 이용량(측정 횟수 현황)
export const getHealthCareTotal = (
  start_date,
  end_date,
  district,
  center,
  search
) => {
  try {
    return axiosClient.get(
      `/healthcare/centers/total/?start_date=${start_date}&end_date=${end_date}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 현재 진행중인 방송 조회
export const getLiveStreaming = () => {
  try {
    return axiosClient.get("/tvs/on-air/");
  } catch (e) {
    console.error(e);
  }
};

// jitsi 토큰
export const getJWT = (user) => {
  if (user) {
    try {
      return axiosClient.post(`jitsi/token/`, {
        user: user,
      });
    } catch (e) {
      console.error(e);
    }
  }
};
