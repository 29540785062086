export const badWords = [
  // ✅ 초성 비속어 (변형된 형태 포함)
  "ㅅㅂ",
  "ㅆㅂ",
  "ㅄ",
  "ㅂㅅ",
  "ㅈㄹ",
  "ㄷㅊ",
  "ㄱㅐㅅㅐㄲl",
  "ㄱㅐㅈㅏ",
  "ㅁㅊ",
  "ㅂㄷㅅ",
  "ㅎㅌㅊ",
  "ㅈㄲ",
  "ㅆㅣㅂㅏㄹ",
  "ㅆㅂㄹㅁ",
  "ㅆㅂㄻ",
  "ㅍㅏㄹ",

  // ✅ 한국어 비속어
  "18년",
  "18놈",
  "18새끼",
  "강간",
  "개가튼년",
  "개가튼뇬",
  "개같은년",
  "개걸레",
  "개고치",
  "개너미",
  "개넘",
  "개년",
  "개놈",
  "개늠",
  "개똥",
  "개떵",
  "개떡",
  "개라슥",
  "개새",
  "개세",
  "개쓰래기",
  "개쓰레기",
  "개씁년",
  "개씁블",
  "개씨발",
  "개씨블",
  "개자식",
  "개잡년",
  "개젓가튼넘",
  "개좆",
  "개지랄",
  "개후라년",
  "개후라들놈",
  "개후라새끼",
  "걔잡년",
  "거시기",
  "걸래년",
  "걸레같은년",
  "걸레년",
  "걸레핀년",
  "게부럴",
  "게세끼",
  "게이",
  "게새끼",
  "게늠",
  "게자식",
  "게지랄놈",
  "고환",
  "귀두",
  "난자마셔",
  "난자먹어",
  "내꺼빨아",
  "내꺼핧아",
  "내버지",
  "너거애비",
  "노옴",
  "누나강간",
  "니기미",
  "니뿡",
  "니뽕",
  "니씨브랄",
  "니아범",
  "니아비",
  "니애미",
  "니애뷔",
  "니애비",
  "니할애비",
  "닝기미",
  "닌기미",
  "니미",
  "닳은년",
  "덜은새끼",
  "돈새끼",
  "돌으년",
  "돌은넘",
  "돌은새끼",
  "동생강간",
  "동성애자",
  "딸딸이",
  "똥구녕",
  "똥꾸뇽",
  "똥구뇽",
  "띠발뇬",
  "띠팔",
  "띠펄",
  "띠풀",
  "띠벌",
  "띠벨",
  "띠빌",
  "막간년",
  "맛간년",
  "맛없는년",
  "맛이간년",
  "멜리스",
  "미친구녕",
  "미친구멍",
  "미친넘",
  "미친년",
  "미친놈",
  "미친눔",
  "미친새끼",
  "미친쇄리",
  "미친쇠리",
  "미친쉐이",
  "미친씨부랄",
  "미튄",
  "미티넘",
  "미틴",
  "미틴넘",
  "미틴년",
  "미틴놈",
  "미틴것",
  "벵신",
  "병닥",
  "병딱",
  "병신",
  "븅쉰",
  "븅신",
  "빙띤",
  "빙신",
  "빠가십새",
  "빠가씹새",
  "빠구리",
  "빠굴이",
  "뻑큐",
  "뼝신",
  "사까시",
  "새꺄",
  "새뀌",
  "새끼",
  "색갸",
  "색끼",
  "색키",
  "세꺄",
  "세끼",
  "시댕이",
  "시발",
  "시발년",
  "시발놈",
  "시발새끼",
  "시방새",
  "시밸",
  "시벌",
  "시불",
  "시붕",
  "십새",
  "십세이",
  "십셰리",
  "십쉐",
  "십창녀",
  "십창",
  "십탱",
  "십탱구리",
  "십탱굴이",
  "십팔새끼",
  "쌍넘",
  "쌍년",
  "쌍놈",
  "쌔끼",
  "쌔리",
  "썅년",
  "썅놈",
  "씹년",
  "씹물",
  "씹미랄",
  "씹버지",
  "씹부랄",
  "씹브랄",
  "씹빵구",
  "씹뽀지",
  "씹새",
  "씹새끼",
  "씹세",
  "씹쌔끼",
  "씹자석",
  "씹자슥",
  "씹지랄",
  "씹창",
  "씹창녀",
  "씹탱",
  "씹탱굴이",
  "씹탱이",
  "씹팔",
  "아가리",
  "애미",
  "애미랄",
  "애미잡년",
  "애미좃물",
  "애비",
  "애자",
];
