import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
  FormCheck,
  FormLabel,
} from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import {
  deleteWalkingGhost,
  deleteWalkingUser,
  getDistrict,
  getSeniorCenter,
  getWalkingCourses,
  getWalkingGhost,
  getWalkingGhostDetail,
  getWalkingSections,
  getWalkingUser,
  putWalkingGhost,
} from "../../api/apiClient";
import CenterSearch from "../common/search/CenterSearch";
import CustomTable from "../table/CustomTable";

const BattleData = () => {
  const [showEditModal, setShowEditModal] = useState(false);

  /*const [battleSearchParam, setBattleSearchParam] = useState({
    courses: "",
    section: "",
  });*/

  const [detailFormData, setDetailFormData] = useState({
    id: null,
    duration: "",
  });

  const [searchParam, setSearchParam] = useState({
    courses: "",
    section: "",
    local: "",
    center: "",
    keyword: "",
    id: "",
    is_competition: false,
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [courseCategory, setCourseCategory] = useState(null);
  const [sectionCategory, setSectionCategory] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  // 코스 리스트
  const getWalkingCoursesCategory = () => {
    getWalkingCourses()
      .then((res) => {
        console.log("getcourseCategory", res.data);
        setCourseCategory(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  // 구간 리스트
  const getWalkingSectionsCategory = () => {
    const courseId = searchParam.courses;
    if (!courseId) {
      setSectionCategory([]); // 코스가 선택 안됐을 때도 초기화
      return;
    }

    getWalkingSections(courseId)
      .then((res) => {
        console.log("getsectionCategory", res.data);
        setSectionCategory(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          console.warn("해당 코스에 구간 데이터가 없습니다.");
          setSectionCategory([]);
        } else {
          console.error(err);
        }
      });
  };

  useEffect(() => {
    if (searchParam.courses) {
      getWalkingSectionsCategory();
    } else {
      setSectionCategory([]);
    }
  }, [searchParam.courses]);

  // 구 리스트
  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 고스트 테이블 데이터
  const [ghostTableData, setGhostTableData] = useState([]);
  /*  const [ghostCurrentPage, setGhostCurrentPage] = useState(1);
    const ghostColumns = [
      {key: "roundText", title: "구"},
      {key: "name", title: "경로당"},
      {key: "course_name", title: "ID"},
      {key: "section_name", title: "코스명"},
      {key: "period", title: "구간"},
      {key: "created_at", title: "점수"},
      {key: "created_at", title: "거리"},
      {key: "created_at", title: "걸음 수"},
      {key: "created_at", title: "시간"},
      {key: "edit", title: "수정하기"},
    ];*/

  // 사용자 테이블 데이터
  const [userTableData, setUserTableData] = useState([]);
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const userColumns = [
    { key: "district", title: "구" },
    { key: "center", title: "경로당" },
    { key: "id", title: "ID" },
    { key: "course", title: "코스명" },
    { key: "is_competition", title: "구분" },
    { key: "section", title: "구간" },
    { key: "score", title: "점수" },
    { key: "distance", title: "거리" },
    { key: "steps", title: "걸음 수" },
    { key: "duration", title: "시간" },
    { key: "delete", title: "삭제" },
  ];

  // get 고스트 데이터
  const getGhostWalkingCompetitionList = () => {
    getWalkingGhost(searchParam.section)
      .then((res) => {
        if (searchParam.section === "") {
          setGhostTableData([]);
          return;
        }
        console.log("getWalkingGhost", res.data);
        setGhostTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  // get 사용자 데이터
  const getUserWalkingCompetitionList = () => {
    // (section, district, center, search_center, search_username, page)
    getWalkingUser(
      searchParam.section,
      searchParam.local,
      searchParam.center,
      searchParam.keyword,
      searchParam.id,
      searchParam.is_competition,
      userCurrentPage
    )
      .then((res) => {
        console.log("getUserWalkingCompetitionList", res.data);
        setUserTableData(res.data);
      })
      .catch((err) => {
        console.error(err);
        setUserTableData({ results: [] });
      });
  };

  useEffect(() => {
    getWalkingCoursesCategory();
    getWalkingSectionsCategory();
    getDistrictList();
    getAccountsCenterList();
    // getGhostWalkingCompetitionList();
    // getUserWalkingCompetitionList();
  }, []);

  const handlePageChange = (page) => {
    setUserCurrentPage(page);
  };

  useEffect(() => {
    getUserWalkingCompetitionList();
  }, [userCurrentPage]);

  useEffect(() => {
    if (userCurrentPage < 1) {
      setUserCurrentPage(1);
    } else {
      getUserWalkingCompetitionList();
    }
  }, [userCurrentPage, searchParam.is_competition]);

  const handleSearch = (e) => {
    e.preventDefault();

    if (searchParam.courses && !searchParam.section) {
      alert("구간을 선택해주세요.");
      return;
    }

    getGhostWalkingCompetitionList();
    getUserWalkingCompetitionList();
  };

  // 상세보기 버튼
  const handleDetail = (data) => {
    console.log("handleDetail id : ", data);
    setShowEditModal(true);

    getWalkingGhostDetail(data?.id)
      .then((res) => {
        console.log("getWalkingGhostDetail", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const data = {
      duration: parseInt(detailFormData.duration),
    };

    putWalkingGhost(detailFormData.id, data)
      .then((res) => {
        console.log("putWalkingGhost", res.data);
        alert("수정되었습니다.");
        setShowEditModal(false);
        getGhostWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;
    console.log("handleDelete id : ", detailFormData.id);

    deleteWalkingGhost(detailFormData.id)
      .then((res) => {
        console.log("deleteWalkingGhost", res.data);
        alert("삭제되었습니다.");
        setShowEditModal(false);
        getGhostWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  const handleDetailDelete = (data) => {
    if (!window.confirm("삭제 하시겠습니까?")) return;
    console.log("handleDetailDelete id : ", data.id);

    deleteWalkingUser(data?.id)
      .then((res) => {
        console.log("deleteWalkingUser", res.data);
        alert("삭제되었습니다.");
        // setShowEditModal(false);
        getUserWalkingCompetitionList();
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getWalkingSectionsCategory();
  }, [searchParam.courses]);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  // 수정하기 모달창 내용
  const renderEdit = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">
                        | 고스트 데이터 수정하기
                      </span>
                    </Col>
                  </Row>

                  {/* 경로당 구분 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 구분
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        // placeholder="구"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.district}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="경로당"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.center}
                      />
                    </Col>
                  </Row>

                  {/* ID */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        ID
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="ID"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.id}
                      />
                    </Col>
                  </Row>

                  {/* 코스/구간 선택 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 / 구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="코스 리스트"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.course?.name}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="구간 리스트"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.section?.name}
                      />
                    </Col>
                  </Row>

                  {/* 점수 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        점수
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="점수 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.score}
                      />
                    </Col>
                    <Col xs={4} className="my-auto">
                      <span>*시간에 따라 자동 계산됩니다.</span>
                    </Col>
                  </Row>

                  {/* 거리 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        거리
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="거리 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.distance}
                      />
                    </Col>
                  </Row>

                  {/* 걸음 수 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        걸음 수
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="걸음 수 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                        defaultValue={detailFormData?.steps}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        시간
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="시간 입력"
                        value={detailFormData.duration}
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            duration: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button
              className="btn-send rounded-5 px-5 fw-bold fs-5"
              onClick={handleEdit}
            >
              저장
            </Button>
            <Button
              className="rounded-5 px-5 fw-bold fs-5"
              variant="danger"
              onClick={handleDelete}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={10}>
            <Row className="mb-3">
              <Col xs={2} className="my-auto">
                <span className="fs-4 fw-bold">| 대전 데이터 검색</span>
              </Col>
              <Col xs={2} className="pe-0">
                <Form.Select
                  className="h-100 fs-5 "
                  aria-label="Default select example"
                  value={searchParam.courses}
                  onChange={(e) =>
                    // setSearchParam({
                    //   ...searchParam,
                    //   courses: e.target.value,
                    // })
                    setSearchParam((prev) => ({
                      ...prev,
                      courses: e.target.value,
                      section: e.target.value === "" ? "" : prev.section, // 코스 선택 해제 시 구간도 초기화
                    }))
                  }
                >
                  <option value={""}>코스 선택</option>
                  {courseCategory &&
                    courseCategory.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col xs={2} className="">
                <Form.Select
                  className="h-100 fs-5"
                  aria-label="Default select example"
                  value={searchParam.section}
                  onChange={(e) =>
                    setSearchParam({
                      ...searchParam,
                      section: e.target.value,
                    })
                  }
                  disabled={!searchParam.courses}
                >
                  <option value={""}>
                    {!searchParam.courses
                      ? "구간 선택"
                      : sectionCategory.length === 0
                      ? "구간이 없습니다"
                      : "구간 선택"}
                  </option>
                  {sectionCategory &&
                    sectionCategory.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
          </Col>

          <Row className="justify-content-start">
            <Col xs={10}>
              <Row className="row-gap-2">
                <Col xs={2} className="pe-0 my-auto">
                  <span className="fs-4 fw-bold">| 경로당 검색</span>
                </Col>
                <Col xs={2} className="pe-0">
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.local}
                    onChange={(e) =>
                      setSearchParam({ ...searchParam, local: e.target.value })
                    }
                  >
                    <option value={""}>구</option>
                    {districtList &&
                      districtList.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select
                    className="h-100 fs-5"
                    aria-label="Default select example"
                    value={searchParam.center}
                    onChange={handleCenterChange}
                  >
                    <option value={""}>경로당</option>
                    {centerList &&
                      centerList.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={1} className="border rounded text-center p-2 fs-5">
                  경로당 명
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.keyword}
                    onChange={handleKeywordChange}
                    disabled={searchParam.center !== ""}
                  />
                </Col>
                <Col xs={1} className="border rounded text-center p-2 fs-5">
                  ID
                </Col>
                <Col xs={2} className="pe-0">
                  <Form.Control
                    type="text"
                    placeholder="ID를 입력하세요."
                    className="h-100 fs-5"
                    value={searchParam.id}
                    onChange={(e) =>
                      setSearchParam({ ...searchParam, id: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button
                className="w-100 h-100 fs-5 fw-bold"
                onClick={handleSearch}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={2} className="">
              <span className="fs-4 fw-bold">| 기록된 대전 데이터</span>
            </Col>
          </Row>

          <Row className="mt-2 row-gap-1">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 고스트 데이터</span>
            </Col>
            <Col xs={12} className="">
              <BootstrapTable striped hover responsive>
                <thead>
                  <tr className={"text-center"}>
                    {[
                      "구",
                      "경로당",
                      "ID",
                      "코스명",
                      "구간",
                      "점수",
                      "거리",
                      "걸음 수",
                      "시간",
                      "수정하기",
                    ].map((col, idx) => (
                      <th key={idx}>{col}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {ghostTableData.map((data, idx) => (
                    <tr key={idx} className={"text-center"}>
                      <td>{data.district}</td>
                      <td>{data.center}</td>
                      <td>{data.id}</td>
                      <td>{data.senior}</td>
                      <td>{data.senior}</td>
                      <td>{data.score}</td>
                      <td>{data.distance}</td>
                      <td>{data.steps}</td>
                      <td>{data.duration}</td>
                      <td>
                        <Button
                          className={`rounded-5 px-4 fw-bold fs-5 btn-detail`}
                          onClick={() => handleDetail(data)}
                        >
                          수정하기
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {ghostTableData.length === 0 && (
                    <tr>
                      <td colSpan={10} className="text-center">
                        진행중인 대회가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </BootstrapTable>
            </Col>
          </Row>

          <Row className="mt-3 row-gap-1">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 사용자 데이터</span>
            </Col>
            <Col xs={12} className="p-0 mb-2">
              <Form.Check className="ps-1 d-flex align-items-center">
                <Form.Check.Input
                  type="checkbox"
                  id={`competition-checkbox`}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                  checked={searchParam.is_competition}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    setSearchParam((prev) => ({
                      ...prev,
                      is_competition: checked,
                    }));
                  }}
                />
                <Form.Check.Label
                  htmlFor={`competition-checkbox`}
                  className="ms-2 fs-5"
                >
                  대회 데이터만 보기
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col xs={12} className="">
              {userTableData ? (
                <CustomTable
                  columns={userColumns}
                  tableData={userTableData}
                  handleDetail={handleDetailDelete}
                  currentPage={userCurrentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        // onConfirm={() => alert("수정 완료")}
      >
        {renderEdit()}
      </CustomModal>
    </>
  );
};

export default BattleData;
