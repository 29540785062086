// charts/BasicColumnChart.jsx
import React, { useEffect, useMemo, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";

const BasicColumnChart = ({
  title,
  xAxisCategories,
  series,
  yAxisTitle,
  height,
}) => {
  const options = useMemo(
    () => ({
      chart: {
        type: "column",
        backgroundColor: "transparent",
        height: height || "auto",
        animation: false,
      },
      title: {
        text: title,
        style: { color: "#FFFFFF" },
      },
      xAxis: {
        categories: xAxisCategories,
        labels: { style: { color: "#FFFFFF" } },
      },
      yAxis: {
        title: {
          text: yAxisTitle,
          style: { color: "#FFFFFF" },
        },
        labels: { style: { color: "#FFFFFF" } },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
        itemStyle: { color: "#FFFFFF" },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },
      series,
    }),
    [title, xAxisCategories, series, yAxisTitle, height]
  );

  return (
    <Col className="p-3 border rounded" style={{ backgroundColor: "#1D222A" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default BasicColumnChart;
