import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
// import HeaderImg from "../assets/landing/header.png";
// import MonitoringImg from "../assets/landing/monitoring.png";
// import ConferencingManagementImg from "../assets/landing/conferencingManagement.png";
// import HealthcareManagementImg from "../assets/landing/healthcareManagement.png";
// import LivingCareManagementImg from "../assets/landing/livingCareManagement.png";
// import EventImg from "../assets/landing/event.png";
// import NoticeImg from "../assets/landing/notice.png";
// import UserImg from "../assets/landing/user.png";
// import SettingImg from "../assets/landing/setting.png";
import "./landing.scss";
import { useNavigate } from "react-router-dom";
import Frame from "../components/common/Frame";
import { getMainMenu } from "../api/apiClient";

const Landing = () => {
  const STATIC_FILE_URL = process.env.REACT_APP_STATIC_FILE_URL;

  const [initialMenuList, setInitialMenuList] = useState([]);
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const routePathArr = [
    { id: 1, route: "monitoring" },
    { id: 2, route: "video-conference" },
    { id: 3, route: "healthcare" },
    { id: 4, route: "lifestyle" },
    { id: 5, route: "issue" },
    { id: 6, route: "notice" },
    { id: 7, route: "user" },
    { id: 8, route: "settings" },
  ];

  const getMainMenuList = () => {
    setLoading(true);

    getMainMenu()
      .then((res) => {
        // console.log("mainMenu", res.data);
        setInitialMenuList(res.data);
      })
      .catch((err) => {
        console.error(err);
        if (err.response.data.code === "token_not_valid") {
          // 토큰 만료시 로그인 페이지로 이동
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMainMenuList();
  }, []);

  // menuData를 order 기준으로 정렬
  const sortedMenuData = [...initialMenuList].sort(
    (a, b) => a.order - b.order || 0
  );

  const sortedPreviews = sortedMenuData.map((menu) =>
    menu.thumbnail instanceof File
      ? URL.createObjectURL(menu.thumbnail)
      : `${STATIC_FILE_URL}${menu.thumbnail}`
  );

  /* 권한 체크 및 적용 */
  const [menuAuth, setMenuAuth] = useState([]);
  const getAuth = () => {
    const auth = localStorage.getItem("authority");
    // console.log("auth", JSON.parse(auth));
    setMenuAuth(JSON.parse(auth).detail[0].menu_permissions);
  };

  useEffect(() => {
    setTimeout(() => {
      getAuth();
    }, 100);
  }, []);

  return (
    <Frame
      title={"스마트경로당 통합관리시스템"}
      showTabMenu={false}
      className={"my-auto"}
    >
      <Row className="content justify-content-center align-items-center">
        {/* 로딩스피너 */}
        {loading && (
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100vw", height: "80vh", zIndex: 100 }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        )}
        {/* 카드 목록 */}
        <Col xl={11} md={11} className="card-list-container p-0">
          <Row className={"row-gap-5"}>
            {sortedMenuData?.map((menu, idx) => {
              // routePathArr에서 menu.id와 일치하는 route
              const matchedRoute = routePathArr.find(
                (route) => route.id === menu.id
              )?.route;

              // Assign menuAuth's permission_level to sortedMenuData
              const menuWithPermissions = sortedMenuData.map((menu) => {
                const matchedAuth = menuAuth.find(
                  (auth) =>
                    auth.main_menu.display_name === menu.display_name ||
                    auth.main_menu.id === menu.id
                );
                return matchedAuth
                  ? {
                      ...menu,
                      permission_level: matchedAuth.permission_level,
                    }
                  : {
                      ...menu,
                      permission_level: null, // Set to null or default if no match found
                    };
              });

              // console.log("menuWithPermissions", menuWithPermissions);

              return (
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  xxl={3}
                  className={"contents-main-item px-4"}
                  key={menu.id}
                >
                  <Card
                    className={"card p-0 text-center m-auto"}
                    onClick={() => {
                      if (menuWithPermissions) {
                        navigate(`/${matchedRoute}`, {
                          state: {
                            menu: menuWithPermissions[idx],
                          },
                        });
                      } else {
                        console.warn(`Route not found : ${menu.id}`);
                      }
                    }}
                    style={{
                      minHeight: "380px",
                    }}
                  >
                    <Card.Body className={"card-body p-0"}>
                      <div className={"card-wrap"}>
                        {/* 로딩 중일 때 스피너 표시 */}
                        {!loaded && (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        )}
                        {/* 실제 이미지 */}
                        <img
                          className="card-icon item-icon"
                          src={sortedPreviews[idx]}
                          alt={menu.order}
                          loading="lazy"
                          onLoad={() => setLoaded(true)} // 로드 완료 시 상태 변경
                          style={{
                            visibility: loaded ? "visible" : "hidden",
                          }}
                        />
                        <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                          {menu.display_name}
                        </h2>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Frame>
  );
};

export default Landing;
