import React from "react";

const Error = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "calc(100vh - 18rem)" }}
    >
      <p className="fs-3">시스템 점검 중입니다.</p>
    </div>
  );
};

export default Error;
