import React from "react";
import Logo from "../assets/common/logo.svg";

const ErrorPage = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-5 h-100">
      <img src={Logo} alt="logo" className="w-25" />
      <div className="text-center mb-5">
        <h3 className="fw-bold fs-1">현재 시스템 점검 중입니다.</h3>
        <h5>자세한 사항은 관리자에게 문의해 주세요.</h5>
      </div>
    </div>
  );
};

export default ErrorPage;
