import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";
import LineChart from "../chart/LineChart";
import PieChart from "../chart/PieChart";
// import Map from "../../assets/test/sample-map.png";
import Map from "../../assets/common/map.png";
import {
  getDistrict,
  getHealthCareStatistics,
  getHealthCareTotal,
  getSeniorCenter,
} from "../../api/apiClient";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import DateSearch from "../common/search/DateSearch";
import CenterSearch from "../common/search/CenterSearch";
import StockChart from "../chart/StockChart";
import { formatDataWithDateRange } from "../../hooks/useTransferData";
import usePrintPDF from "../../hooks/usePrintPDF";
import MapVisualization from "../chart/MapVisualization";

const Usage = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        // console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const [bloodPressure, setBloodPressure] = useState({});
  const [temperature, setTemperature] = useState({});
  const [weight, setWeight] = useState({});
  const [electrocardiogram, setElectrocardiogram] = useState({});
  const [totalUsage, setTotalUsage] = useState({});
  const [maleStats, setMaleStats] = useState({});
  const [femaleStats, setfemaleStats] = useState({});
  const [healthCareTotal, setHealthCareTotal] = useState({});

  // 헬스케어 시스템 이용량
  const getHealthCareStatisticsList = () => {
    const startDate = `${searchParam.year}-${String(searchParam.month).padStart(
      2,
      "0"
    )}-${String(searchParam.day).padStart(2, "0")}`;
    const endDate = `${searchParam.year2}-${String(searchParam.month2).padStart(
      2,
      "0"
    )}-${String(searchParam.day2).padStart(2, "0")}`;

    getHealthCareStatistics(
      startDate,
      endDate,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getHealthCareStatistics : ", res.data);

        // 날짜별 이용량 추이
        setBloodPressure(
          formatDataWithDateRange(
            res.data.daily_usage.blood_pressure,
            startDate,
            endDate
          )
        );
        setTemperature(
          formatDataWithDateRange(
            res.data.daily_usage.temperature,
            startDate,
            endDate
          )
        );
        setWeight(
          formatDataWithDateRange(
            res.data.daily_usage.weight,
            startDate,
            endDate
          )
        );
        setElectrocardiogram(
          formatDataWithDateRange(
            res.data.daily_usage.electrocardiogram,
            startDate,
            endDate
          )
        );

        // 기기별 이용량
        setTotalUsage({
          xAxisCategories: ["혈압", "체온", "체중", "심전도"],
          xAxisTitle: "등급구분",
          series: [
            {
              type: "column",
              name: "등급구분",
              data: [
                { y: res.data.total_usage.blood_pressure, color: "#2ECF8F" },
                { y: res.data.total_usage.temperature, color: "#FB72F4" },
                { y: res.data.total_usage.weight, color: "#FF8C18" },
                { y: res.data.total_usage.electrocardiogram, color: "#9E54F3" },
              ],
            },
          ],
        });

        // 측정 횟수 남여별 현황
        setMaleStats({
          title: "남성",
          series: [
            {
              type: "pie",
              name: "측정횟수",
              data: [
                {
                  name: "혈압",
                  y: res.data.gender_stats.blood_pressure.male,
                  color: "#2ECF8F",
                },
                {
                  name: "체온",
                  y: res.data.gender_stats.temperature.male,
                  color: "#FB72F4",
                },
                {
                  name: "체중",
                  y: res.data.gender_stats.weight.male,
                  color: "#FF8C18",
                },
                {
                  name: "심전도",
                  y: res.data.gender_stats.electrocardiogram.male,
                  color: "#9E54F3",
                },
              ],
            },
          ],
          innerSize: "0%",
        });

        setfemaleStats({
          title: "여성",
          series: [
            {
              type: "pie",
              name: "측정횟수",
              data: [
                {
                  name: "혈압",
                  y: res.data.gender_stats.blood_pressure.female,
                  color: "#2ECF8F",
                },
                {
                  name: "체온",
                  y: res.data.gender_stats.temperature.female,
                  color: "#FB72F4",
                },
                {
                  name: "체중",
                  y: res.data.gender_stats.weight.female,
                  color: "#FF8C18",
                },
                {
                  name: "심전도",
                  y: res.data.gender_stats.electrocardiogram.female,
                  color: "#9E54F3",
                },
              ],
            },
          ],
          innerSize: "0%",
        });
      })
      .catch((err) => console.error(err));
  };

  // 이용량(측정 횟수 현황)
  const getHealthCareTotalList = () => {
    const startDate = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endDate = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getHealthCareTotal(
      startDate,
      endDate,
      searchParam.local,
      searchParam.center,
      searchParam.keyword
    )
      .then((res) => {
        console.log("getHealthCareTotal : ", res.data);
        setHealthCareTotal(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 차트 설정
  const getChartData = (
    bloodPressure,
    temperature,
    weight,
    electrocardiogram
  ) => {
    return {
      tooltip: {
        shared: true, // 여러 시리즈 데이터 공유
        formatter: function () {
          // this.points는 여러 시리즈 데이터에 접근 가능
          return this.points
            .map(
              (point) =>
                `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <strong>${point.y}</strong>`
            )
            .join("<br/>"); // 시리즈별 데이터를 줄바꿈으로 연결
        },
      },
      series: [
        {
          name: "혈압",
          data: bloodPressure,
          color: "#2ECF8F",
          lineWidth: 4,
        },
        {
          name: "체온",
          data: temperature,
          color: "#FB72F4",
          lineWidth: 4,
        },
        {
          name: "체중",
          data: weight,
          color: "#FF8C18",
          lineWidth: 4,
        },
        {
          name: "심전도",
          data: electrocardiogram,
          color: "#9E54F3",
          lineWidth: 4,
        },
      ],
    };
  };

  // 날짜별 이용량 추이 데이터
  const [chartData, setChartData] = useState({ series: [] });

  useEffect(() => {
    const series = getChartData(
      bloodPressure,
      temperature,
      weight,
      electrocardiogram
    );
    setChartData(series);
  }, [bloodPressure, temperature, weight, electrocardiogram]);

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getHealthCareStatisticsList();
    getHealthCareTotalList();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const handleSearch = (e) => {
    e.preventDefault();
    getHealthCareStatisticsList();
    getHealthCareTotalList();
  };

  /* PDF 출력 기능 */
  const { pdfRef, handlePrintPDF } = usePrintPDF();

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="d-flex p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="row-gap-3">
          <DateSearch
            title={"검색 일자"}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            daysInMonth={daysInMonth}
          />
          <Row className="justify-content-between">
            <CenterSearch
              title={"경로당 필터 검색"}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              districtList={districtList}
              centerList={centerList}
              handleKeywordChange={handleKeywordChange}
              handleCenterChange={handleCenterChange}
              onSearch={handleSearch}
            />
            <Col xs={2} className="text-end pe-0">
              <Button
                className="mt-auto fw-bold fs-5 px-4 h-100"
                onClick={() => {
                  // pdfRef의 overflowY가 auto이므로 스크롤 제거하고 전체 페이지 출력

                  pdfRef.current.style.height = "auto";
                  handlePrintPDF();

                  // handlePrintPDF 동작이 끝나면 height: "calc(100vh - 23rem)" 처리
                  setTimeout(() => {
                    pdfRef.current.style.height = "calc(100vh - 22rem)";
                  }, 10000);
                }}
              >
                PDF
              </Button>
            </Col>
          </Row>
        </Row>
      </Col>

      {/* 테이블 */}

      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 22rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        ref={pdfRef}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">| 날짜별 이용량 추이</span>
          </Col>
          <Col xs={12} className="">
            <StockChart
              title="Test Stock Chart"
              series={chartData?.series || []}
              tooltip={chartData?.tooltip}
              style={{ backgroundColor: "#1D222A" }}
            />
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 기기별 이용량</span>
            </Col>
            <Col className="pe-0">
              <BasicColumnChart {...totalUsage} />
            </Col>
          </Col>

          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 측정 횟수 남여별 현황
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <PieChart {...maleStats} />
              <PieChart {...femaleStats} />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 구별 측정 현황 - 혈압 측정
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualization
                data={healthCareTotal?.centers}
                type={"blood_pressure"}
              />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 구별 측정 현황 - 체온 측정
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualization
                data={healthCareTotal?.centers}
                type={"temperature"}
              />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 구별 측정 현황 - 체중 측정
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualization
                data={healthCareTotal?.centers}
                type={"weight"}
              />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 구별 측정 현황 - 심전도 측정
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              {/*<img src={Map} alt="sample-map" className="p-5" />*/}
              <MapVisualization
                data={healthCareTotal?.centers}
                type={"electrocardiogram"}
              />
            </Col>
          </Col>
        </Row>

        <Col
          className="border rounded mt-3 p-3"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col className="w-100">
            <span className="fs-4 fw-bold w-100">| 측정 횟수 현황</span>
          </Col>
          <Col className="fs-4 mt-3 mb-1">
            {healthCareTotal.period?.start_date} ~{" "}
            {healthCareTotal.period?.end_date}
          </Col>
          <Col>
            <BootstrapTable striped hover responsive>
              <thead>
                <tr className={"text-center"}>
                  {[
                    "순번",
                    "구",
                    "경로당명",
                    "혈압",
                    "체온",
                    "체중",
                    "심전도",
                    "전체 측정 횟수",
                  ].map((col, idx) => (
                    <th key={idx}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {healthCareTotal.centers &&
                  healthCareTotal.centers?.map((data, idx) => (
                    <tr key={idx} className={"text-center"}>
                      <td>{data.id}</td>
                      <td>{data.district.name}</td>
                      <td>{data.name}</td>
                      <td>{data.measurements.blood_pressure}</td>
                      <td>{data.measurements.temperature}</td>
                      <td>{data.measurements.weight}</td>
                      <td>{data.measurements.electrocardiogram}</td>
                      <td>{data.total_measurements}</td>
                    </tr>
                  ))}

                {healthCareTotal.centers &&
                  healthCareTotal.centers.length === 0 && (
                    <tr>
                      <td colSpan={8} className="text-center">
                        해당 내역이 없습니다.
                      </td>
                    </tr>
                  )}
              </tbody>
            </BootstrapTable>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default Usage;
